/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import useTenant from  'features/tenant/use';
import useAuth from 'features/auth/use';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { setToLS, getFromLS, removeFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';
import { useHistory } from 'react-router-dom';
import useDashboards from 'features/dashboards/use';
import $ from 'jquery';
import { FiltersService } from 'pages/maindash/components/service/incidentAppsDropdown';


const TenantDialog = () => {
    const [count, setCount] = useState(0);
    // const [selectedTenant, setTenant] = useState(null);
    const { data, handleDash } = useDashboards();
    const [ globalCurrentTenant, setGlobalCurrentTenant ] = useGlobal('currentTenant');
    const [ globalAllTenants, setGlobalAllTenants ] = useGlobal('allTenants');
    const [ globalTenantCount, setGlobalTenantCount ] = useGlobal('tenantCount');
    const [globalDashboardCount, setGlobalDashboardCount] = useGlobal('dashboardCount');
    const [globalWynDashboards, setGlobalWynDashboards] = useGlobal('wynDashboards');
    const [globalVisibleLeft, setGlobalVisibleLeft] = useGlobal('visibleLeft');
    const history = useHistory();

    const params = useTenant();
    const { success, handleLogout } = useAuth();

    const listItems = [];
    // setCount(count + 1)
    
    const onHide = (name) => {
        setCount(count -1);
    }
    const HandleAternity = (event) => {
        setToLS('aternity', 'true')
        setGlobalVisibleLeft(false)
        handleDash({ event })
    }

    const HandleDash = (dash) => {
        // console.log(dash)
        removeFromLS('aternity')
        setToLS('viewingDashboard', dash.item)
        history.push('/')
        setGlobalVisibleLeft(false)
        handleDash({ id: dash.item.id, type: dash.item.type })
    }
    let newArr = [];
    const onCityChange = (e) => {
        setToLS('currentTenant',e.value)
        setGlobalCurrentTenant(e.value)
        //FiltersService.incidentsAppsDropdownOptions().then(data => console.log(data));
        // setTenant(e.value);
        // console.log(e.value.label)
        // params.handleTenant(e.value.label)
        setTimeout(function () {
            console.log(globalWynDashboards)
            console.log(data)
            console.log(success)
            history.push('/maindash')
        }, 3000);
        
        if ( data ) {
    
            let links = [];
            links = data.map((v, k) => <Button plain hoverIndicator key={k} onClick={() => handleDash({ id: v.id, type: v.type })}> <Card><p truncate>{v.title}</p></Card> </Button>);
            setToLS('all-dashboards', data);
            // alert('done wyn dash')

            setToLS('all-dashboards-safe', data);
            const getAllDashboards = getFromLS('all-dashboards');
            // Empty array on fresh call

            var loopData = [];

            if (getAllDashboards) {
                var i;
                for (i = 0; i < getAllDashboards.length; i++) {
                    loopData = { id: getAllDashboards[i].id, title: getAllDashboards[i].title, type: getAllDashboards[i].type, command: (event) => { HandleDash(event); } }
                    newArr.push(loopData)
                }
                var aternityDash = {
                    "id": "aternity",
                    "title": "Aternity",
                    "type": "dbd",
                    command: (event) => { HandleAternity(event); }
                }
                loopData = aternityDash;
                newArr.push(loopData)
                console.log(newArr)
                setGlobalWynDashboards(newArr)
                setGlobalDashboardCount(newArr.length);
            }

            // Sort the list alphabetially
            newArr.sort((a, b) => (a.title > b.title) ? 1 : -1)
        }
        
    }
    function getCurrentTenant(){
        let theCurrTenant = []
        if ( globalCurrentTenant ){
            // console.log(getFromLS('currentTenant'))
            theCurrTenant = globalCurrentTenant
            //setTenant(getFromLS('currentTenant'));
            return theCurrTenant
        }
    }
    function shouldShowDia(){
        console.log(getFromLS('currentTenant'))
        var currT = getFromLS('currentTenant')
        if ( currT ) {
            // setTenant(currT.value);
            params.handleTenant(currT.label)
            return false;
            
        } else {
            return true;
        }
    }
    useEffect(() => {
        
            
            if (params.success && success) {
                console.log(globalAllTenants)
                params.list
                .forEach((v,k) => {
                    // console.log(v)
                    listItems.push({label: v.displayName});
                });
                console.log(listItems)
                setGlobalAllTenants(listItems)
                setGlobalTenantCount(listItems.length)
                setToLS("tenantCount",listItems.length);
                
                setTimeout(function () { $(".p-sidebar-header").prepend($(".sidebar-title")); $(".p-sidebar-header").addClass("p-sidebar-header-right"); }, 1000);
                console.log(globalAllTenants)
            }
        
    }, [params.success]);
    return (
        <div data-testid="DialogComponent">
            
        {params.dialog && (
            <div className='container-fluid tenant-selection-page'>
                <div className='mobile-swirl-logo static'>
                    <img alt="Storm" src="./storm-grey-storm-logo-noswirl.png" width="200px" />
                </div>
                <div className='mobile-swirl-logo animate'>
                    <img alt="Storm" src="./swirl.png" className="logo-swirl rotating" />
                </div>
                <Dialog maskClassName="login-dialog multiple-tenants" header="You have multiple tenants" visible={shouldShowDia()} modal  onHide={() => onHide('displayPosition')}
                        draggable={false} resizable={false} dismissableMask={false} closable={false} closeOnEscape={false}>
                    
                    
                    <div className='row pt-4'>                    
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 pl-4 pr-4'>
                            <p className='pl-3 pr-3'>Select the Tenant you would like to start with from the options on the right</p>
                        </div>
                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 pl-6 pr-6'>
                            <Dropdown value="" style={{ width: '100%' }} options={globalAllTenants} onChange={onCityChange} optionLabel="label" placeholder="Select tenant" />
                        </div>
                    </div>
                    <div className='row pb-4'>                    
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 pl-4 pr-4'>
                            {params.success && params.list.length > 1 && <span>
                                <p className='pl-3'><b>TIP:</b> You can switch between tenants later in the dashboard!</p>
                                </span>
                            }
                        </div>
                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 pl-4 pr-4'>

                        </div>
                    </div>
                    
                    
                </Dialog>
                <Card
                id="SelectTenant"
                position="center"
                >
                    
                </Card>
            </div>
        )}
        </div>

    )
}

export default TenantDialog;
