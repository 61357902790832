/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Button } from 'primereact/button';
import deviceStatus from './service/device_status.json';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Card } from 'primereact/card';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';



import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import WidgetLibrary from './widget_library';
import DataLibrary from './data_library';
import SidebarBasket from 'pages/maindash/components/basket/sidebar';
import { setToLS, getFromLS } from '../../../features/app/utils/storage';
import { Sidebar } from 'primereact/sidebar';
import Basket from './basket/basket';
import { Badge } from 'primereact/badge';
import { TenantLookupService } from './service/tenantLookup';
import { useGlobal } from 'reactn';
import DynBarChart from 'pages/maindash/widgets/dynCharts/barChart';


let theDynDashDataList = [];
let theDynDashData = [];
let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "withCredentials": false,
  }
}


const GetDynamicDash = (mood) => {

  const [globalCurrentTenant, setGlobalCurrentTenant] = useGlobal('currentTenant');
  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
  const [basicData] = useState({
    labels: ['January', 'February'],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: '#42A5F5',
        data: [65, 59, 80, 81, 56, 55, 40]
      },
      {
        label: 'My Second dataset',
        backgroundColor: '#FFA726',
        data: [28, 48, 40, 19, 86, 27, 90]
      }
    ]
  });

  const [basicOptions] = useState({
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }





  })


  const theDynDash = getFromLS("dynLoad");

  theDynDashData.chartData = basicData;
  theDynDashData.chartOptions = basicOptions;
  console.log(theDynDashData.all)

  useEffect(() => {
    axios.get(globalDbENv + ':7443/' + globalDbName + '/dashboards', config)
    .then((res) => {
      const widgets = res.data
      console.log(widgets)
      var tmpWidgetList = []

      widgets
        .forEach((item, k) => {
          if (item.dashboardName === theDynDash) {
            console.log(item)
            
            tmpWidgetList.push(item)
            // tmpWidgetList[k].chartData = {};

            tmpWidgetList
            .forEach((item, k) => {
              console.log(item.collectionName)
              console.log(k)
              axios.get(globalDbENv + ':7443/' + globalDbName + '/'+item.collectionName, config)
              .then((res) => {
                tmpWidgetList[k].chartData = (res.data)
                console.log(res.data)
              }).catch((err) => {
                console.log(err);
              })
            });
            //theDynDashDataList.push(item)
          }
          // theWidgets.push({'key': item.key, 'dataName': item.dataName, 'Summary': item.Summary, 'fields': item.fields, 'visualisation': item.visualisation,'chart_options': item.chart_options});
          // console.log(theWidgets)
        });
      // for (let item of widgets) {
      //   console.log(item)
      //     theWidgets.push({'key': item.key, 'dataName': item.dataName, 'Summary': item.Summary, 'fields': item.fields, 'visualisation': item.visualisation,'chart_options': item.chart_options});
      //     console.log(theWidgets)
      // }


      // console.log(theDynDashDataList)
      theDynDashDataList = tmpWidgetList;
      // setGlobalDynamicContent(theDynDashData);


      //setAppWidgets(theWidgets);


      // console.log(deviceStabilityIndex)
    }).catch((err) => {
      console.log(err);
    })


    
}, [theDynDash]);
  

  return (
    <div>
      <div className='container-fluid p-0'>
        <div className='row panel-content loose title-container m-0'>
          <div className='col-12 col-sm-12 col-md-9 col-lg-9'>
            <div className="panel-title p-pt-2 p-pl-2">
              <p stylename="margin-top: 0;" className="yello-text">Overview.
                {/* {welcomeDate()} */}
              </p>
              <p className='small-text'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-3 col-lg-3 hide-mobile p-0 pt-3'>

          </div>

        </div>
      </div>
      <div className='container-fluid bubble-enable main-dash-welcome-page bg-white'>
       
      <div className='row pt-4'>
        {theDynDashDataList.map((data, idx) => (
          
            
              <div className="col-3">
                {data.chartType === 'bar' &&
                  <DynBarChart thedatafromparent={data} />
                }
              </div>
          
          

        ))}
        </div>

        {/* {theDynDashData.map((data, idx) => (
          <div className='container-fluid'>
            <div className='row'>
              <div className="col-6">
                {data.chartType === 'bar' &&
                  <DynBarChart thedatafromparent={basicData} />
                }
              </div>
            </div>
          </div>

        ))} */}
      </div>


    </div>

  )
}


export default GetDynamicDash;