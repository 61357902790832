/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import 'moment-timezone';
import { DataScroller } from 'primereact/datascroller';
import { ServiceUpdatesService } from './ServiceUpdatesService';  

const ServiceUpdates = () => {

    const [products, setProducts] = useState([]);
    const productService = new ServiceUpdatesService();

    useEffect(() => {
        productService.getProducts().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (data) => {
        return (
            <div className="service-update-item">
                <div className="p-grid">
                    <div className="p-col p-p-0">
                        <p className='title'>{data.name}</p>
                        <p className='summary'>{data.description}</p>
                        <p className='category'>{data.category}</p>
                        <p><a href="#">Read More</a></p>
                    </div>
                </div>
            </div>
        );
    }

    
  return (
    <div>
        <div className="card">
            <DataScroller className='service-updates-scroller' value={products} itemTemplate={itemTemplate} rows={5} inline scrollHeight="500px" />
        </div>
    </div>
   
  );
};

export default ServiceUpdates;
