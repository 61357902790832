/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import deviceStatus from './service/device_status.json';
import DataSearch from './data_search';
import { Checkbox } from 'primereact/checkbox';
import { Sidebar } from 'primereact/sidebar';
import {store, useGlobalState} from 'state-pool';
import {useLocalState} from 'state-pool';
import { setToLS, getFromLS } from 'features/app/utils/storage';
import Basket from './basket/basket';
import axios from 'axios';
import { useGlobal } from 'reactn';


function search(nameKey, myArray){
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i].dataName === nameKey) {
          return i;
      }
  }
}

// let basketData = [];



let _selectedCategories = []
let theWidgets = [];
const DataLibrary = () => {

    let dashboardName = getFromLS('createDashName');
    let dashboardCategory = getFromLS('createDashcat');


    const [selectedCategories, setSelectedCategories] = useState([]);
    const [ globalBasketCount, setGlobalBasketCount ] = useGlobal('basketCount');
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ appWidgets, setAppWidgets ] = useGlobal('appWidgets');
    const [ basketItems, setBasketItems ] = useGlobal('basketItems');
    
    _selectedCategories = basketItems    
    
    //const [selectedCategories, setSelectedCategories] = useState(appDatas.slice(1,3));
    const onCategoryChange = (e) => {
      
        _selectedCategories = [...selectedCategories];
        console.log(basketItems)
        
        console.log(e.checked)
        if (e.checked) {
            _selectedCategories.push(e.value);
            console.log(_selectedCategories)
            setBasketItems(_selectedCategories);
            setGlobalBasketCount(_selectedCategories.length);
            console.log(globalBasketCount)
            // setToLS('basket', _selectedCategories)
        }
        else {
            for (let i = 0; i < _selectedCategories.length; i++) {
                const selectedCategory = _selectedCategories[i];

                if (selectedCategory.key === e.value.key) {
                    _selectedCategories.splice(i, 1);
                    setBasketItems(_selectedCategories.splice(i, 0));
                    // setToLS('basket', _selectedCategories)
                    break;
                }
            }
        }
        if ( e.checked ) {
          //addToBasket(e.value)
        }
        
        //setState(_selectedCategories)
        // let basketData = _selectedCategories;
        // setToLS('basket',_selectedCategories)
        setBasketItems(_selectedCategories);
        setSelectedCategories(_selectedCategories);
        setGlobalBasketCount(_selectedCategories.length)
    }
  const [user, setUser, updateUser] = useLocalState(
    {
      "dataName": "Application UXI",
      "Summary": "View global Application User Experiemce",
      "fields": ["Application Name, Avg UXI"]
    }
  );

  
  const childToParent = (childdata) => {
    setVisibleLeft(false)
  }
 
  const [visibleLeft, setVisibleLeft] = useState(false);


  return (
    <div className="p-p-5">
            <p className="basic-text p-m-0">Dashboard Label <b>{ dashboardName }</b></p>
            <p className="basic-text p-mt-0">Dashboard Category <b>{ dashboardCategory }</b></p>
          <DataSearch />
          {/* <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false )} className='p-sidebar-md'>
            <h3 className='sidebar-header p-mb-3'>Create Dashboard Basket Items</h3>
            <Basket childToParent={childToParent} />
          </Sidebar> */}
          <div className="container-fluid p-0"> 
            <div className="row">
              {appWidgets.map((data) => (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" key={data.key}>
                    <Card title={data.dataName} className="p-3 pb-5 data-library-card">
                  <div className="p-m-0" style={{ lineHeight: '1.5' }}>
                    <div className="card p-d-flex p-jc-left">
                      <p className='basic-text'>{data.Summary}</p>
                    </div>
                    <div key={data.key} className="p-field-checkbox">
                        <Checkbox inputId={data.key} name="category" value={data} onChange={onCategoryChange} checked={_selectedCategories.some((item) => item.key === data.key)} disabled={data.key === 'R'} />
                        <label htmlFor={data.key}>Add to basket</label>
                    </div>
                  </div>
                </Card>
                </div>
              ))}
            </div>
          </div>
        </div>

  )
}

export default DataLibrary;
// class DataLibrary extends Component {

  

//   static get defaultProps() {

//     return {
//       className: "layout",
//       cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
//       rowHeight: 30
//     };
//   }

//   componentDidMount() {
//   }

//   resetLayout() {
//     this.setState({ layouts: {} });
//   }

//   onLayoutChange(layout, layouts) {
//     saveToLS("layouts", layouts);
//     this.setState({ layouts });
//   }

//   render() {
    
//   }
// }

// export default DataLibrary;