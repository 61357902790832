/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import 'moment-timezone';
import { useGlobal } from 'reactn';
let theNewsItems = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}

const RpStormNews = () => {
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const [ globalNewsType, setGlobalNewsType ] = useGlobal('newsType');
    const [ globalNewsItems, setGlobalNewsItems ] = useGlobal('newsItems');
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const sortOptions = [
        {label: 'Name Asc', value: 'name'},
        {label: 'Name Descending', value: '!name'},
    ];

    const getNewsItems = () => {
        axios.get(globalDbENv  + ':7443/'+ globalDbName + '/News', config)
            .then((res)=>{
                var tmpNewsItems = []
                theNewsItems = res.data;
                for (let item of theNewsItems) {
                    if ( item.parentCategory === globalNewsType ) {
                        tmpNewsItems.push(item);
                    }
                }
                setGlobalNewsItems(tmpNewsItems)
            }).catch((err)=>{
                console.log(err);
            })
    }

    useEffect(() => {
        getNewsItems()
    }, [globalNewsType]);

    useEffect(() => {
        // if ( getFromLS('canRunApi') === true ) {
            axios.get(globalDbENv  + ':7443/'+ globalDbName + '/News', config)
            .then((res)=>{
                var tmpNewsItems = []
                theNewsItems = res.data;
                console.log(res.data)
                
                // businessWorkflowData = [];
                // const businessWorkflow = res.data
                // console.log(typeof businessWorkflow)
                // setToLS("globalBwItems", businessWorkflow);
                
                // console.log(getFromLS("globalBwItems"));
                // console.log(typeof businessWorkflowData)
                console.log(globalNewsType)
                for (let item of theNewsItems) {
                    if ( item.parentCategory === globalNewsType ) {
                        console.log(item);
                        tmpNewsItems.push(item);
                    }
                    
                    // console.log(item)
                    // businessWorkflowData.push({'business_workflow': item["Business Workflow"], 'score': parseInt(item['Score']), 'status': item['Status'], 'timeframe': item['Timeframe']});
                }

                setGlobalNewsItems(tmpNewsItems)
            }).catch((err)=>{
                console.log(err);
            })
        
    }, []);

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    

    const renderListItem = (data) => {
        return (
            <div className="col-12">
                <div className="product-list-item p-4">
                    {/* <img src={`images/product/${data.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} /> */}
                    <div className="product-list-detail">
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        {/* <Rating value={data.rating} readOnly cancel={false}></Rating> */}
                        <div className='product-category'>
                        <i className="pi pi-tag product-category-icon"></i><span className="product-category">Category: </span><span className="product-category">{data.category}</span>
                        </div>
                    </div>
                    <div className="product-list-action inline-block">
                        {/* <Button label="Read More"></Button> */}
                        Read More <i className='pi pi-chevron-right'></i>
                        {/* <Button icon="pi pi-shopping-cart" label="Read More" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button> */}
                        {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                    </div>
                </div>
            </div>
        );
    }

    const renderGridItem = (data) =>{
        return (
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="product-grid-item card p-4">
                    <div className="product-grid-item-top">
                    {/* <img className='p-0 m-0' src={`images/product/${data.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} /> */}
                        {/* <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data.category}</span>
                        </div> */}
                        {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
                    </div>
                    <div className="product-grid-item-content">
                    {/* <img src={`images/product/${data.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} /> */}
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        <div className='product-category'>
                        <i className="pi pi-tag product-category-icon"></i><span className="product-category">Category: </span><span className="product-category">{data.category}</span>
                        </div>
                        {/* <Rating value={data.rating} readOnly cancel={false}></Rating> */}
                    </div>
                    <div className="product-grid-item-bottom mt-3">
                        {/* <span className="product-price">{data.category}</span> */}
                        {/* <Button label="Read More"></Button> */}
                        <div>Read More <i className='pi pi-chevron-right'></i></div>
                        {/* <Button icon="pi pi-shopping-cart" label="Read More" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button> */}
                    </div>
                </div>
            </div>
        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const renderHeader = () => {
        return (
            <div>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-6'>
                            {/* <Dropdown options={sortOptions} value={sortKey} optionLabel="label" placeholder="Sort By Name" onChange={onSortChange}/> */}
                        </div>
                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 text-right'>
                            <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }

    




    const header = renderHeader();
    
  return (
            

    <div className='container-fluid'>
            
        <div className='row panel-content loose title-container pl-2 pr-2'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                <div className="panel-title p-pt-2 p-pl-2">
                    <p stylename="margin-top: 0;">Storm News {globalNewsType}</p>
                </div>
            </div>
        </div>
        <div className='row pl-2 p-r-2'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-5 p-4'>
                { globalNewsType === 'service-update' &&
                    <div>
                        <p className="biguns-header fw300 p-mt-0 p-mb-2">CLEAN SERVICES.</p>
                        <p className="biguns-header fw200 up p-mt-0">Service Sub.</p>
                    </div>
                }
                { globalNewsType === 'news' &&
                    <div>
                        <p className="biguns-header fw300 p-mt-0 p-mb-2">KNOWLEDGE IS POWER.</p>
                        <p className="biguns-header fw200 up p-mt-0">Service Sub.</p>
                    </div>
                }
                { globalNewsType === 'reports' &&
                    <div>
                        <p className="biguns-header fw300 p-mt-0 p-mb-2">SUMMARY.</p>
                        <p className="biguns-header fw200 up p-mt-0">Service Sub.</p>
                    </div>
                }

            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-7 p-0'>
            <div className="dataview-demo">
            <div className="card reports-grid">
                <DataView value={globalNewsItems} layout={layout} header={header}
                        itemTemplate={itemTemplate} paginator rows={9}
                        sortOrder={sortOrder} sortField={sortField} />
            </div>
        </div>
            </div>
        </div>
    </div>
        
  );
};

export default RpStormNews;

