'use strict';

import React, { Component, createClass, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'moment-timezone';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

let config = {
  headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
  }
}


let collectionFields = [];
const Step1 = () => {
  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
  const [ dynDashWidgetCollectionOptions, setDynDashWidgetCollectionOptions ] = useGlobal('dyn_dash_widget_collection_options');
  const [ dynDashWidgetCollectionSelected, setDynDashWidgetCollectionSelected ] = useGlobal('dyn_dash_widget_collection_selected');
  const [ dynDashWidgetCollectionSelectedFieldsOptions, setDynDashWidgetCollectionSelectedFields ] = useGlobal('dyn_dash_widget_collection_selected_fields_options');
  const [ dynDashWidgetCollectionSelectedFieldsOptionSelected, setDynDashWidgetCollectionSelectedFieldsOptionSelected ] = useGlobal('dyn_dash_widget_collection_selected_fields_option_selected');
  
  
  

  const [selectedCountries, setSelectedCountries] = useState(null);

  const getFieldsFromCollection = (collection_name) => {
    
    console.log(collection_name)
    console.log(globalDbName)

    axios.get(globalDbENv  + ':7443/'+ globalDbName + '/' + collection_name +'', config)
    .then((res)=>{
      console.log(res.data)
      console.log(Object.getOwnPropertyNames(res.data[0]));
      collectionFields = Object.getOwnPropertyNames(res.data[0]);
      
      collectionFields
      .forEach((v, k) => {
        console.log(v)
        console.log(k)
        if (v === '_id' || v === 'account_id') {
          collectionFields.splice(k, 1)
        }
        //subscribedServicesArray[0].items.push({ intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, command: (event) => { HandleDash({ label: v.dash_name, id: v.dash_id, type: v.dash_type }); } })
        //  subscribedServicesArray[0].children.push({intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, onClick: (event) => HandleDash(event)});
     })
     console.log(collectionFields)
     setDynDashWidgetCollectionSelectedFields(collectionFields);
  // console.lo
      // availableCollections = res.data;
      // setDynDashWidgetCollectionOptions(res.data)
        // QuoteData = res.data;
        // setGlobalLoginQuoteItems(QuoteData)
    }).catch((err)=>{
        console.log(err);
    })
  }
  

  useEffect(() => {
    // if ( getFromLS('canRunApi') === true ) {
    axios.get(globalDbENv  + ':7443/'+ globalDbName + '/dyn_dash_available_collections', config)
    .then((res)=>{
      console.log(res.data)
      setDynDashWidgetCollectionOptions(res.data)
      // setDynDashWidgetCollectionSelectedFields()
        // QuoteData = res.data;
        // setGlobalLoginQuoteItems(QuoteData)
    }).catch((err)=>{
        console.log(err);
    })
    
  }, []);

  const onCityChange = (e) => {
    console.log(e.value.collection_name);
    setDynDashWidgetCollectionSelected(e.value);
    getFieldsFromCollection(e.value.collection_name);
    setDynDashWidgetCollectionSelectedFieldsOptionSelected([]);
  }

  const onFieldsSelect = (e) => {
    console.log(e.value);
    setDynDashWidgetCollectionSelectedFieldsOptionSelected(e.value);
    // setDynDashWidgetCollectionSelected(e.value);
    // getFieldsFromCollection(e.value.collection_name);
  }

  const countryTemplate = (option) => {
    return (
        <div className="country-item">
            <div>{option}</div>
        </div>
    );
  }

  const selectedCountriesTemplate = (option) => {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <div>{option}</div>
            </div>
        );
    }

    return "Select Fields";
  }
  const panelFooterTemplate = () => {
    const selectedItems = selectedCountries;
    const length = selectedItems ? selectedItems.length : 0;
    return (
        <div className="py-2 px-3">
            <b>{length}</b> item{length > 1 ? 's' : ''} selected.
        </div>
    );
  }

  return (
    <div className="step step1">
        <div className="">
          <form id="Form" className="form-horizontal">
            <div className="form-group">
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                    <label className="col-md-12 control-label">
                      <h5>Choose a collection containing the data you wish to use in the widget.</h5>
                      <Dropdown value={dynDashWidgetCollectionSelected} style={{ width: '100%' }} options={dynDashWidgetCollectionOptions} onChange={onCityChange} optionLabel="friendly_name" placeholder="Select collection" />
                    </label>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                  { dynDashWidgetCollectionSelected &&
                    <label className="col-md-12 control-label">
                      <h5>Choose the fields from the collection you would like to use.</h5>
                      <MultiSelect style={{ width: '100%' }} value={dynDashWidgetCollectionSelectedFieldsOptionSelected} options={dynDashWidgetCollectionSelectedFieldsOptions} onChange={onFieldsSelect}  placeholder="Select Fields" className="multiselect-custom" itemTemplate={countryTemplate} selectedItemTemplate={selectedCountriesTemplate} panelFooterTemplate={panelFooterTemplate} />
                    </label>
                  }
                  </div>
                </div>
              </div>
              
            </div>
          </form>
        </div>
      </div>
  );
}

export default Step1;