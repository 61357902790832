import React, { Component, createClass, useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import 'moment-timezone';
import { useGlobal } from 'reactn';
import { Carousel } from 'primereact/carousel';
import Modal from 'react-modal';
import { ModalContext } from '../../modal/modalContext'
import ErrorComponent from '../../error-component';
import { ApiGet } from 'apicalls/api_get';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

let QuoteData = [];


let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "withCredentials": false,
  }
}

const ShowErrorModal = () => {
  let { handleModal } = useContext(ModalContext);
  alert('called')
  handleModal(<ErrorComponent />)
}

const LoginQuotes = () => {
  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
  const [globalWithCors, setGlobalWithCors] = useGlobal('corsEnabled');
  const [globalLoginQuoteItems, setGlobalLoginQuoteItems] = useGlobal('loginQuoteItems');
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {

    ApiGet(globalDbENv  + ':7443/'+ globalDbName + '/login_quotes')
      .then((res) => {
        console.log(res.data)
        QuoteData = res.data;
        setGlobalLoginQuoteItems(QuoteData)
      }).catch((err) => {
        handleModal(<ErrorComponent />)
        console.log(err);
      })
  }, []);


  const productTemplate = (product) => {
    return (
      <div className="product-item">
        <div className="product-item-content">
          <div>
            <h4 className="mb-1">{product.ticker_description}</h4>
          </div>
        </div>
      </div>
    );
  }




  return (
    <div className="carousel-demo tips-card mt-1">
      <div className="card">
        {globalLoginQuoteItems &&
          <Carousel value={globalLoginQuoteItems} numVisible={1} numScroll={1} orientation="vertical" circular="1" verticalViewPortHeight="100px" showNavigators={0} showIndicators={false}
            autoplayInterval={4000} itemTemplate={productTemplate} />
        }
      </div>
      {/* <button
        className="mt-6 rounded  bg-purple-700 text-purple-100 px-5 h-12"
        onClick={() => handleModal(<ContentComponent />)}
      >tango</button> */}
    </div>
  );

}

export default LoginQuotes;


function ContentComponent() {
  let { handleModal } = React.useContext(ModalContext);
  return (
    <>
      <div>Hello here !!!</div>
      <button
        className="h-8 px-3 text-white bg-red-500 text-xs rounded"
        onClick={handleModal}
      >
        Close modal
      </button>
    </>
  );
}
