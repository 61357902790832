import styled from 'styled-components';

export const StyledFrame = styled.div`
    .dashboard-preview {
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        background: transparent !important;
        width: 96.5vw;
        height: 92.5vh;
        border-width: 0;
        background: #eeeeee;
        flex-grow: 1;
        justify-content: center;
        align-items: flex-start;
    }
`;
