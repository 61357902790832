import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionSelectors from './slice';

const useDashboards = () => {
    const dispatch = useDispatch();
    const loading = useSelector(actionSelectors.selectLoading);
    const error = useSelector(actionSelectors.selectError);
    const success = useSelector(actionSelectors.selectSuccess);
    const data = useSelector(actionSelectors.selectData);
    const selected = useSelector(actionSelectors.selectDashboardSelected);

    const handleDash = React.useCallback((value) => dispatch(actionSelectors.set(value)), [dispatch]);
    
    const handleDashInit = React.useCallback(() => {
        dispatch(actionSelectors.init());
    }, [dispatch]);

    return {
        data,
        selected,
        error,
        loading,
        success,
        handleDash,
        handleDashInit
    };
};

export default useDashboards;
