/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';

import 'moment-timezone';
import { getFromLS, setToLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { TenantLookupService } from '../components/service/tenantLookup';

const theIncidents = [];
const webAppPerfObject = [
    { "labels": [] },
    { "datasets": [] },
];
const webAppPerfObjectUsers = [
    { "labels": [] },
    { "datasets": [] },
];
const webAppPerfLabels = { "labels": [] };
const webAppPerfDataset = { "datasets": [] };

const webAppPerfLabelsUsers = { "labels": [] };
const webAppPerfDatasetUsers = { "datasets": [] };

const webAppPerfUserCount = [];
const webAppPerfLoadTimeAvg = [];

const webAppPerfNetBackend = [];
const webAppPerfNetClient = [];

const webAppPerfLoadTimeMax = [];
const webAppPerCountries = [
    { "name": "All", "code": "all" }
];
const webAppViewType = [
    { "name": "Loadtime", "code": "loadtime" },
    { "name": "Network", "code": "network" },
];
const shouldShow = false;
let incidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}



const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
}
const lightOptions = {
    stacked: false,
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
};

const WebappPerformanceStackedChart = (mood) => {
    
    const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
    const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
    const [globalWebAppPerformanceChartData, setGlobalWebAppPerformanceChartData] = useGlobal('webAppPerformanceChartData');
    const [globalWebAppPerformanceChartDataUsers, setGlobalWebAppPerformanceChartDataUsers] = useGlobal('webAppPerformanceChartDataUsers');
    const [globalWebAppPerformanceChartView, setGlobalWebAppPerformanceChartView] = useGlobal('webAppPerformanceChartView');
    const [ globalCurrTenant, setGlobalCurrTenant ] = useGlobal('currentTenant');
    
    const [globalWebPerformanceAppList, setGlobalWebPerformanceAppList] = useGlobal('WebPerformanceAppList');
    const [selectedCity1, setSelectedCity1] = useState({ "name": "All", "code": "all" });
    const [ globalCurrentTenant, setGlobalCurrentTenant ] = useGlobal('currentTenant');
    const [selectedCity2, setSelectedCity2] = useState({ "name": "Loadtime", "code": "loadtime" });
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const tenantService = new TenantLookupService();
    const [city, setCity] = useState(null);
    const [webAppPerfObject] = useState([
        { "labels": [] },
        { "datasets": [] },
    ]);
    const theCurrTenant = tenantService.translate();
    useEffect(() => {
        //getGetWebPerfData();
      }, []);

      
    

    const categories = [{name: 'Users', key: 'A'}, {name: 'Loading Times', key: 'M'}];

    const [selectedCategory, setSelectedCategory] = useState(categories[1]);
    const [basicData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Users',
                backgroundColor: '#42A5F5',
                data: [65, 59, 80, 81, 56, 55, 40]
            }
        ]
    });

    

    const chartData ={
        labels: ["App 1", "App 2", "App 3", "App 4"],
        datasets: [
            {
                type: "line",
                label: "Loadtime Avg",
                borderColor: "#4500ff",
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                data: [20, 65, 52, 48, 56, 76, 42]
            },
            {
                type: "line",
                label: "Loadtime Max",
                borderColor: "#ff0000",
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                data: [50, 25, 12, 48, 56, 76, 42]
            }
        ]
    };

    
    

    let [cities] = useState([
        { name: 'All devices', code: 'all' },
        { name: 'Desktop', code: 'desktop' },
        { name: 'Mobile', code: 'mobile' },
        { name: 'Vdi', code: 'vdi' }
    ]);
    const onCityChange = (e) => {
        setSelectedCity1(e.value);
        filterWebAppByCountry(e.value);
    }

    const onCityChange1 = (e) => {
        setSelectedCity2(e.value);
        filterWebAppByType(e.value);
    }

    const onGraphTypeChange = (e) => {
        setSelectedCategory(e)
    }

    let incicentsApps = [
        "All Applications"
    ];
    const createApplicationList = (data) => {
        var tmpArr = []
        const unique = [...new Set(data.map(item => item["Application_Name"]))];
        tmpArr = [...unique, ...incicentsApps]
        setGlobalWebPerformanceAppList(tmpArr)
        
    }


    const filterWebAppByType = (type) => {
        
        if ( type.name === 'Network') {
            alert('net')
            axios.get(globalDbENv + ':7447/' + globalDbName + '/webApplicationPerformance', config)
                .then((res) => {
                    setGlobalWebAppPerformanceChartData([])
                    setGlobalWebAppPerformanceChartDataUsers([])
                    var tmpLabels = []
                    incidents = res.data
                    for (let item of incidents) {
                        console.log(item)
                        // if ( item["Application_Name"] === selectedCity1.name ) {
                        //     // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                        //     theIncidents.push(item);
                        //     tmpLabels.push(item.Timeframe)
                        //     webAppPerfUserCount.push(item["User Count:"])
                        //     webAppPerfNetBackend.push(parseFloat(item["Page Load Backend Time Avg (s)"]).toFixed(2))
                        //     webAppPerfNetClient.push(parseFloat(item["Page Load Client Time Avg (s)"]).toFixed(2))
                        // } else {
                            // console.log(selectedCity2.name )
                            // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                            theIncidents.push(item);
                            tmpLabels.push(item.Timeframe)
                            webAppPerfUserCount.push(item["User Count:"])
                            webAppPerfNetBackend.push(parseFloat(item["Page Load Backend Time Avg (s)"]).toFixed(2))
                            webAppPerfNetClient.push(parseFloat(item["Page Load Client Time Avg (s)"]).toFixed(2))
                        //}

                        
                            
                           
                        
                    }

                    webAppPerfLabels.labels = tmpLabels;
                    webAppPerfDataset.datasets = chartData.datasets;

                    webAppPerfObject.labels = tmpLabels;
                    webAppPerfObject.datasets = chartData.datasets;


                    webAppPerfLabelsUsers.labels = tmpLabels;
                    webAppPerfDatasetUsers.datasets = basicData.datasets;

                    webAppPerfObjectUsers.labels = tmpLabels;
                    webAppPerfObjectUsers.datasets = basicData.datasets;

                    webAppPerfObject.datasets[1].data = webAppPerfNetBackend;
                    webAppPerfObject.datasets[0].data = webAppPerfNetClient;

                    webAppPerfObjectUsers.datasets[0].data = webAppPerfUserCount;

                    console.log(webAppPerfObject)
                    setGlobalWebAppPerformanceChartData(webAppPerfObject)
                    setGlobalWebAppPerformanceChartDataUsers(webAppPerfObjectUsers)
                    [cities] = webAppPerCountries;
                    
                    //console.log(webAppPerfObject)
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            //getGetWebPerfData();
        }
        
    }
    

    const filterWebAppByCountry = (country) => {
        
        axios.get(globalDbENv + ':7447/' + globalDbName + '/webApplicationPerformance', config)
                .then((res) => {
                    setGlobalWebAppPerformanceChartData([])
        setGlobalWebAppPerformanceChartDataUsers([])
                    var tmpLabels = []
                    incidents = res.data
                    for (let item of incidents) {
                        if ( country === "All") {
                            // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                            theIncidents.push(item);
                            tmpLabels.push(item.Timeframe)
                            webAppPerfUserCount.push(item["User Count:"])
                            webAppPerfLoadTimeAvg.push(parseFloat(item["Load Time Average (s)"]).toFixed(2))
                            webAppPerfLoadTimeMax.push(parseFloat(item["Page load Network Time Avg (s)"]).toFixed(2))
                            // if(webAppPerCountries.some(person => person.name === item["Country"])){
                            //     console.log("Object found inside the array.");
                            // } else{
                            //     console.log("Object not found.");
                            //     webAppPerCountries.push({ "name": item["Country"], "code": item["Application_Name"] })
                            // }
                        }
                        if ( item["Application_Name"] === country) {
                            // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                            theIncidents.push(item);
                            tmpLabels.push(item.Timeframe)
                            webAppPerfUserCount.push(item["User Count:"])
                            webAppPerfLoadTimeAvg.push(parseFloat(item["Load Time Average (s)"]).toFixed(2))
                            webAppPerfLoadTimeMax.push(parseFloat(item["Page load Network Time Avg (s)"]).toFixed(2))
                            // if(webAppPerCountries.some(person => person.name === item["Application_Name"])){
                            //     console.log("Object found inside the array.");
                            // } else{
                            //     console.log("Object not found.");
                            //     webAppPerCountries.push({ "name": item["Application_Name"], "code": item["Application_Name"] })
                            // }
                        }
                        
                        
                    }

                    webAppPerfLabels.labels = tmpLabels;
                    webAppPerfDataset.datasets = chartData.datasets;

                    webAppPerfObject.labels = tmpLabels;
                    webAppPerfObject.datasets = chartData.datasets;


                    webAppPerfLabelsUsers.labels = tmpLabels;
                    webAppPerfDatasetUsers.datasets = basicData.datasets;

                    webAppPerfObjectUsers.labels = tmpLabels;
                    webAppPerfObjectUsers.datasets = basicData.datasets;

                    webAppPerfObject.datasets[1].data = webAppPerfLoadTimeAvg;
                    webAppPerfObject.datasets[0].data = webAppPerfLoadTimeMax;

                    webAppPerfObjectUsers.datasets[0].data = webAppPerfUserCount;
                    setGlobalWebAppPerformanceChartData(webAppPerfObject)
                    setGlobalWebAppPerformanceChartDataUsers(webAppPerfObjectUsers)
                    [cities] = webAppPerCountries;
                    
                }).catch((err) => {
                    console.log(err);
                })
    }
    let isItEmpty = false;
    const [globalWebAppPerformanceDataAvailable, setGlobalWebAppPerformanceDataAvailable] = useGlobal('webAppPerfDataAvailable');
    const getGetWebPerfData = () => {

        // setGlobalWebAppPerformanceChartData([])
        // setGlobalWebAppPerformanceChartDataUsers([])

        if (getFromLS('canRunApi') === true) {
            
            axios.get(globalDbENv + ':7447/' + globalDbName + '/webApplicationPerformance', config)
                .then((res) => {
                    var tmpLabels = []
                    incidents = res.data
                    createApplicationList(res.data)
                    for (let item of incidents) {
                        if ( item["account_id"] === theCurrTenant ) {
                            // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                            theIncidents.push(item);
                            tmpLabels.push(item.Timeframe)
                            webAppPerfUserCount.push(item["User Count:"])
                            webAppPerfLoadTimeAvg.push(parseFloat(item["Load Time Average (s)"]).toFixed(2))
                            webAppPerfLoadTimeMax.push(parseFloat(item["Page load Network Time Avg (s)"]).toFixed(2))
                            // if(webAppPerCountries.some(person => person.name === item["Application_Name"])){
                            //     console.log("Object found inside the array.");
                            // } else{
                            //     console.log("Object not found.");
                            //     webAppPerCountries.push({ "name": item["Application_Name"], "code": item["Application_Name"] })
                            // }
                        }
                        
                        
                        
                    }
                    

                    webAppPerfLabels.labels = tmpLabels;
                    webAppPerfDataset.datasets = chartData.datasets;

                    webAppPerfObject.labels = tmpLabels;
                    webAppPerfObject.datasets = chartData.datasets;


                    webAppPerfLabelsUsers.labels = tmpLabels;
                    webAppPerfDatasetUsers.datasets = basicData.datasets;

                    webAppPerfObjectUsers.labels = tmpLabels;
                    webAppPerfObjectUsers.datasets = basicData.datasets;

                    // webAppPerfObject.datasets[2].data = webAppPerfUserCount;
                    webAppPerfObject.datasets[1].data = webAppPerfLoadTimeAvg;
                    webAppPerfObject.datasets[0].data = webAppPerfLoadTimeMax;

                    webAppPerfObjectUsers.datasets[0].data = webAppPerfUserCount;
                    setGlobalWebAppPerformanceChartData(webAppPerfObject)
                    setGlobalWebAppPerformanceChartDataUsers(webAppPerfObjectUsers)
                    [cities] = webAppPerCountries;
                    console.log(globalWebAppPerformanceChartData)
                    if (globalWebAppPerformanceChartData.labels.length > 0) {
                        console.log('array is not empty');
                        isItEmpty = 'false';
                        setGlobalWebAppPerformanceDataAvailable(isItEmpty)
                      } else {
                        isItEmpty = 'true';
                        setGlobalWebAppPerformanceDataAvailable(isItEmpty)
                      }

                    console.log(isItEmpty)
                    console.log(globalWebAppPerformanceDataAvailable)
                }).catch((err) => {
                    console.log(err);
                })
        }
    }

    // useEffect(() => {
    //     alert(globalWebAppPerformanceDataAvailable)
    //   }, [mood])
    // useEffect(() => {
        
        
        
    //     if (getFromLS('canRunApi') === true) {
            
    //         axios.get(globalDbENv + ':7447/' + globalDbName + '/webApplicationPerformance', config)
    //             .then((res) => {
                    
    //                 var tmpLabels = []
    //                 incidents = res.data
    //                 console.log(incidents)
    //                 createApplicationList(res.data)
    //                 for (let item of incidents) {
    //                     if ( item["account_id"] === parseInt(theCurrTenant) ) {
    //                         // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
    //                         theIncidents.push(item);
    //                         tmpLabels.push(item.Timeframe)
    //                         webAppPerfUserCount.push(item["User Count:"])
    //                         webAppPerfLoadTimeAvg.push(parseFloat(item["Load Time Average (s)"]).toFixed(2))
    //                         webAppPerfLoadTimeMax.push(parseFloat(item["Page load Network Time Avg (s)"]).toFixed(2))
    //                     }
    //                 }
                    
    //                 webAppPerfLabels.labels = tmpLabels;
    //                 webAppPerfDataset.datasets = chartData.datasets;

    //                 webAppPerfObject.labels = tmpLabels;
    //                 webAppPerfObject.datasets = chartData.datasets;


    //                 webAppPerfLabelsUsers.labels = tmpLabels;
    //                 webAppPerfDatasetUsers.datasets = basicData.datasets;

    //                 webAppPerfObjectUsers.labels = tmpLabels;
    //                 webAppPerfObjectUsers.datasets = basicData.datasets;

    //                 // webAppPerfObject.datasets[2].data = webAppPerfUserCount;
    //                 webAppPerfObject.datasets[1].data = webAppPerfLoadTimeAvg;
    //                 webAppPerfObject.datasets[0].data = webAppPerfLoadTimeMax;

    //                 webAppPerfObjectUsers.datasets[0].data = webAppPerfUserCount;
    //                 setGlobalWebAppPerformanceChartData(webAppPerfObject)
    //                 setGlobalWebAppPerformanceChartDataUsers(webAppPerfObjectUsers)
    //                 [cities] = webAppPerCountries;
    //             }).catch((err) => {
    //                 console.log(err);
    //             })
    //     }

    // }, []);

    

    
    

    return (
        <div className='welcome-widget'>

            {globalWebAppPerformanceDataAvailable === 'true' &&
            <div className="card">
                <div className='container-fluid'>
                    <div className='row custom-widget-titles'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 text-left'>
                            <span className='mr-3 title'>Web App Performance</span>
                        </div>
                    </div>
                    
                    <div className='row custom-widget-titles chartheight'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 align-left'>
                            {selectedCategory.name === 'Users' &&
                                <Chart type="bar" data={globalWebAppPerformanceChartDataUsers} options={basicOptions}   />
                            }
                            {selectedCategory.name === 'Loading Times' &&
                                <Chart type="line" data={globalWebAppPerformanceChartData} options={lightOptions} />
                            }
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 align-left'>
                                
                                {/* <div className="field-radiobutton inline-flex">
                                {
                                    categories.map((category) => {
                                        return (
                                            
                                            <div key={category.key} className="field-radiobutton">
                                                <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => onGraphTypeChange(e.value)}  checked={selectedCategory.key === category.key} disabled={category.key === 'R'} />
                                                <label htmlFor={category.key} className="mr-2">{category.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                </div> */}
                        </div>
                    </div>
                    <div className='row custom-widget-titles'>
                        <div className='col-12 col-sm-12 col-md-66 col-lg-4'>
                            <Dropdown className="width-100-percent" value={selectedCategory} options={categories} onChange={(e) => onGraphTypeChange(e.value)}  optionLabel="name" placeholder="Select View" />
                        </div>
                        <div className='col-12 col-sm-12 col-md-66 col-lg-4'>
                            <Dropdown className="width-100-percent" value={selectedCity1} options={globalWebPerformanceAppList} onChange={onCityChange} placeholder="Select Application" />
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                            <Dropdown className="width-100-percent" value={selectedCity2} options={webAppViewType} onChange={onCityChange1} optionLabel="name" placeholder="Select View" />
                        </div>
                    </div>
                </div>
                
            </div>
            }
            {globalWebAppPerformanceDataAvailable === 'false' &&
            <div className="card">
                <div className='container-fluid'>
                    <div className='row custom-widget-titles'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 text-left'>
                            <span className='mr-3 title'>Web App Performance</span>
                        </div>
                    </div>
                </div>
                Nothing to show
            </div>
            }
            
        </div>
    );
};

export default WebappPerformanceStackedChart;

