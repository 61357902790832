/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';
import TrafficLight from 'react-trafficlight';


import 'moment-timezone';
import { getFromLS, setToLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';


let businessWorkflowData = [];
const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}





const BusinessWorkflowTrafficLight = () => {
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const [ globalBwItems, setGlobalBwItems ] = useGlobal('bwItems');

    

    

    useEffect(() => {
        // if ( getFromLS('canRunApi') === true ) {
            axios.get(globalDbENv  + ':7448/'+ globalDbName + '/latestWorkflows', config)
            .then((res)=>{
                businessWorkflowData = [];
                const businessWorkflow = res.data
                // console.log(typeof businessWorkflow)
                setToLS("globalBwItems", businessWorkflow);
                
                // console.log(getFromLS("globalBwItems"));
                // businessWorkflowData = getFromLS("globalBwItems");
                // console.log(typeof businessWorkflowData)

                for (let item of businessWorkflow) {
                    // console.log(item)
                    businessWorkflowData.push({'business_workflow': item["Business Workflow"], 'score': parseInt(item['Score']), 'status': item['Status'], 'timeframe': item['Timeframe']});
                }

                // console.log(businessWorkflowData)
                //setGlobalBwItems(JSON.stringify(res.data));
                // console.log(globalBwItems)
                
                // for (let item of incidents) {
                //     theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                // }act
                // console.log(deviceStabilityIndex)
            }).catch((err)=>{
                console.log(err);
            })
            //fetch('./BusinessWorkflow.json').then(res => res.json()).then(d => d.data);
        // }
        
    }, [null]);


    
    
  return (


            <div className="card welcome-widget">
                <h5>Business Workflow</h5>
                <div className='container'>
                    <div className='row traffic-lights'>
                    {businessWorkflowData.map((data) => ( // <= only 2 items (
                        <div className='col-6 col-sm-6 col-md-6 col-lg-3 text-center'>
                            {data.score > 90 &&
                                <div className='text-center'>
                                    <span className='inline-block'>{data.score}</span>
                                    <div><TrafficLight GreenOn /></div>
                                </div>
                            }
                            {data.score < 90 &&
                                <div className='text-center'>
                                    <span className='inline-blockl'>{data.score}</span>
                                    <div><TrafficLight YellowOn /></div>
                                </div>
                            }
                            <p>{data.business_workflow}</p>
                        </div>
                    ))}
                        {/* <div className='col-12 col-sm-12 col-md-6 col-lg-6 text-center'>
                            
                            <TrafficLight RedOn YellowOn />
                            <h5>STORM Dev</h5>
                        </div> */}
                    </div>
                    {/* <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 text-center'>
                            
                            <TrafficLight YellowOn />
                            <h5>NetIM 2</h5>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 text-center'>
                            
                            <TrafficLight GreenOn />
                            <h5>Riverbed</h5>
                        </div>
                    </div> */}
                </div>
                <div>
                    
                    
                    
                    
                </div>
            </div>
        
  );
};

export default BusinessWorkflowTrafficLight;

