/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';
import HelpSearch from './help_search';
import HelpAccordion from './help_accordion';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

const DataTableStripedDemo = () => {
    const [products, setProducts] = useState(data);
    // const theData = 
    // [
    //     {"id": "1000","deviceName": "f230fh0g3","ipAddress": "Bamboo Watch","downTime": "Product Description"},
    //     {"id": "1001","deviceName": "nvklal433","ipAddress": "Black Watch","downTime": "Product Description"},
    //     {"id": "1002","deviceName": "zz21cz3c1","ipAddress": "Blue Band","downTime": "Product Description"},
    //     {"id": "1003","deviceName": "244wgerg2","ipAddress": "Blue T-Shirt","downTime": "Product Description"},
    //     {"id": "1004","deviceName": "h456wer53","ipAddress": "Bracelet","downTime": "Product Description"}
    // ]

    useEffect(() => {
        fetch('service/down.json')
          .then((res) => res.json())
          .then((data) => setProducts({...data}))
          .then((data) => alert({...data}));
      }, []);

    return (
        <div>
            <div className="card">
                <DataTable value={deviceStatuses} stripedRows responsiveLayout="scroll">
                    <Column field="AGENT_CURRENT_STATUS" header="Current Status"></Column>
                    <Column field="DEVICE_PERFORMANCE_SCORE" header="Performance Score"></Column>
                    <Column field="HEALTH_STATUS" header="Status"></Column>
                </DataTable>
            </div>
        </div>
    );
}

/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */

const deviceStatuses = deviceStatus.value;

const devicesStatus = deviceStatuses.reduce((catsSoFar, { AGENT_CURRENT_STATUS, DEVICE_HEALTH_SCORE }) => {
if (!catsSoFar[AGENT_CURRENT_STATUS]) catsSoFar[AGENT_CURRENT_STATUS] = [];
catsSoFar[AGENT_CURRENT_STATUS].push(DEVICE_HEALTH_SCORE);
return catsSoFar;
}, {});

const AverageDisconnectedHealth = () => {
    for(var i = 0; i < devicesStatus.Disconnected.length; i++) {
        // total += grades[i];
    }
}
AverageDisconnectedHealth()

 const uxiChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  const stabilityChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [100, 50, 300],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  
  const lightOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
  };
  
  
  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
  };
  const devicesHealthBar = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Devices Health',
            backgroundColor: '#ffffff',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
  };
  const userExperienceBar = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'User Experience',
            backgroundColor: '#ff7373',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
  };
  const devicesLowLatency = {
    labels: ['MSC-AP-LT-053', 'MSC-AP-LT-054', 'MSC-AP-LT-055', 'MSC-AP-LT-056', 'MSC-AP-LT-057', 'MSC-AP-LT-058', 'MSC-AP-LT-059'],
    datasets: [
        {
            label: 'Percentage above threshold',
            backgroundColor: '#42A5F5',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
  };
  const stackedData = {
    labels: ['Kim Hunt', 'Aaron De Pury', 'Aanyah Cook', 'Aaron Emberson', 'Abdul Haq', 'Aaron Porter', 'Aaron Reid'],
    datasets: [{
        type: 'bar',
        label: 'Web Browsing',
        backgroundColor: '#42A5F5',
        data: [
            50,
            25,
            12,
            48,
            90,
            76,
            42
        ]
    }, {
        type: 'bar',
        label: 'CDV Viewer',
        backgroundColor: '#66BB6A',
        data: [
            21,
            84,
            24,
            75,
            37,
            65,
            34
        ]
    }, {
        type: 'bar',
        label: 'Other',
        backgroundColor: '#FFA726',
        data: [
            41,
            52,
            24,
            74,
            23,
            21,
            32
        ]
    }]
  };
  const horizontalOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
  };
  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
  };
  const resizeStop = () => {
    alert('stopped')
  }

  function getFromLS(key) {
    let ls = {};
    global.localStorage.removeItem("rgl-8");
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
      
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

 

class HelpSupport extends Component {
    

    constructor(props) {
        super(props);
        
        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts))
        };
    }

    static get defaultProps() {
        
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 30
        };
    }

    componentDidMount() {
    }

    resetLayout() {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

  render() {
    return (
        <div>
            <div className="panel-content loose title-container p-ml-3 p-mr-3 p-mt-3 p-mb-3" key="1" data-grid={{ w: 12, h: 2, x: 8, y: 0, minW: 2, minH: 3 }}>
                <div className="panel-title">
                    <p stylename="margin-top: 0;">Help &amp; Support</p>
                    <small>Service ></small>
                </div>
            </div>
            <div className="p-grid p-p-3">
                <div className="p-col-4">
                <HelpSearch />
                <p className="basic-text">Start typing the help topic required.</p>
                </div>
                <div className="p-col-8">
                    <HelpAccordion />
                </div>
            </div>
      </div>
        
    )
  }
}

export default HelpSupport;