import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import useAuth from 'features/auth/use';

const ErrorComponent = ({error}) => {

    const { handleLogin, handleLogout } = useAuth();
    const header = (
        <img alt="Card" className='mb-4' src="images/usercard.png" onError={(e) => e.target.src='./error-dog.png'} />
    );
    const footer = (
        <span>
            <Button label="Logout" icon="pi pi-times" className="p-button-danger ml-2" onClick={handleLogout}/>
        </span>
    );
    return (
        <Card title="An error has occurred" subTitle="Network Error" style={{ width: '100%' }} className="text-center" footer={footer} header={header}>
                <p className="m-0" style={{lineHeight: '1.5'}}>We have safely logged you out, you can close this window and re-login.</p>
            </Card>
        // <Card fill align="center" justify="center" data-testid="main-error-element">
        //     <h1>
        //     An error has occurred 
        //     </h1>
        //     <p color="status-critical">{error && `Error code: : ${error.errorCode}`}</p>
        //     <div className="image-container">
        //         <img className="dog" alt="dog" src="./error-dog.png" />
        //     </div>
        //     <div>
        //         Please contact Administrator
        //     </div>
        // </Card>
    );
};

export default ErrorComponent;
