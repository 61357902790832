import { createSelector, createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data: null,
    selected: null,
    loading: false,
    success: false,
    error: null
};

const dashboards = createSlice({
    name: 'dashboards',
    initialState: initialState,
    reducers: {
        init(state) {
            state.data = null;
            state.selected = null;
            state.success = false;
            state.error = null;
            state.loading = false;
        },
        load(state) {
            state.loading = true;
            state.success = false;
            state.error = null;
        },
        set(state, action) {
            state.selected = action.payload;
        },
        success(state, action) {
            state.data = action.payload;
            state.error = null;
            state.success = true;
            state.loading = false;
        },
        error(state, action) {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        }
    }
});

export const {init, load, set, success, error} = dashboards.actions;

const selectDomain = (state) => state.dashboards || initialState;
export const selectLoading = createSelector([selectDomain], state => state.loading);
export const selectSuccess = createSelector([selectDomain], state => state.success);
export const selectError = createSelector([selectDomain], state => state.error);
export const selectData = createSelector([selectDomain], state => state.data);
export const selectDashboardSelected = createSelector([selectDomain], state => state.selected);

export default dashboards.reducer;
