import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from 'lodash';
import {useTheme} from './theme/useTheme';
import { setToLS, getFromLS } from './utils/storage';

const Container = styled.ul`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    padding: 0px;
    list-style: none;
`;




export const ThemeSelector = (props) => {
    
    const themesFromStore = getFromLS('all-themes');
    const [data, setData] = useState(themesFromStore.data);
    const [themes, setThemes] = useState([]);
    const {setMode} = useTheme();
    const themeSwitcher = selectedTheme => {
        console.log(selectedTheme);
        console.log(props.setter);
        setMode(selectedTheme);
        if ( props.setter) {
            props.setter(selectedTheme);
        } else {
            //window.location.reload(false);
            setThemes(_.keys(data));
        }
        setToLS('themeSelected', selectedTheme.name);
        
    };

    useEffect(() => {
        setThemes(_.keys(data));
    }, [data]);

    useEffect(() => {
        props.newTheme &&
            updateThemeCard(props.newTheme);
    }, [])

    const updateThemeCard = theme => {
        const key = _.keys(theme)[0];
        const updated = {...data, [key]:theme[key]};
        setData(updated);
    }

    const ThemeCard = props => {
        
        return(
            
                <li onClick={ (theme) => themeSwitcher(props.theme) } >
                    <div className="theme-color-bulb" style={{backgroundColor: `${data[_.camelCase(props.theme.name)].colors.body}`}}></div>
                    <span className="theme-colour-label">{props.theme.name}</span>
                </li>
            
            // <Wrapper style={{backgroundColor: `${data[_.camelCase(props.theme.name)].colors.body}`, 
            //         color: `${data[_.camelCase(props.theme.name)].colors.text}`, 
            //         fontFamily: `${data[_.camelCase(props.theme.name)].font}`}}>
            //         <span>Click on the button to set this theme</span>
            //     <ThemedButton onClick={ (theme) => themeSwitcher(props.theme) }
            //         style={{backgroundColor: `${data[_.camelCase(props.theme.name)].colors.button.background}`, 
            //         color: `${data[_.camelCase(props.theme.name)].colors.button.text}`,
            //         fontFamily: `${data[_.camelCase(props.theme.name)].font}`}}>
            //         {props.theme.name}
            //     </ThemedButton>
            // </Wrapper>
        )
    }

    return (
        <div>
            <Container>
            {
                themes.length > 0 && 
                    themes.map(theme =>(
                        <ThemeCard theme={data[theme]} key={data[theme].id} />
                    ))
            }
            </Container>
        </div>
    )
}
export default ThemeSelector;