// import React, { useState } from 'react';
// import { Dropdown } from 'primereact/dropdown';
// import useTenant from  'features/tenant/use';
// import useAuth from 'features/auth/use';
// import { setToLS, getFromLS } from 'features/app/utils/storage';


// const DropdownTenant = () => {
//     const [selectedTenant, setTenant] = useState(null);
//     const params = useTenant();
//     const { success } = useAuth();


//     function getCurrentTenant(){
//         let theCurrTenant = []
//         if ( getFromLS('currentTenant') !== undefined ){
//             console.log(getFromLS('currentTenant'))
//             theCurrTenant = getFromLS('currentTenant')
//             setTenant(getFromLS('currentTenant'));
//             return theCurrTenant
//         }
//     }
//     const listItems = [];
//     // setCount(count + 1)
//     if (params.success && success) {
//         params.list
//         .forEach((v,k) => {
//             // listItems.push({label: v.displayName, onClick: () => params.handleTenant(v.displayName)});
//             listItems.push({label: v.displayName});
//         });
//     }


//     const onTenantChange = (e) => {
//         setToLS('currentTenant',e.value)
//         setTenant(e.value);
//         console.log(e.value.label)
//         params.handleTenant(e.value.label)
//     }

//     return(
//         <Dropdown value={getCurrentTenant()} options={listItems} onChange={onTenantChange} optionLabel="label" placeholder="Select tenant" />
//     )
// }

// export default DropdownTenant;

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Tree } from 'primereact/tree';
import useTenant from 'features/tenant/use';
import useAuth from 'features/auth/use';
import { getFromLS, setToLS, removeFromLS } from 'features/app/utils/storage';
import FormControl from '@mui/material/FormControl';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import axios from 'axios';
import useDashboards from 'features/dashboards/use';
import { TenantLookupService } from '../../pages/maindash/components/service/tenantLookup';
import { FiltersService } from '../../pages/maindash/components/service/incidentAppsDropdown';

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}

const DropdownTenant = ({ childToParent }) => {
    const { data, handleDash } = useDashboards();
    const history = useHistory();
    const tenantService = new TenantLookupService();
    // const [selectedTenant, setSelectedTenant] = useState(getFromLS('currentTenant'));
    const [globalVisibleLeft, setGlobalVisibleLeft] = useGlobal('visibleLeft');
    const [globalAllTenants, setGlobalAllTenants] = useGlobal('allTenants');
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const [globalTenantCount, setGlobalTenantCount] = useGlobal('tenantCount');
    const [globalCurrentTenant, setGlobalCurrentTenant] = useGlobal('currentTenant');
    const [globalDashboardCount, setGlobalDashboardCount] = useGlobal('dashboardCount');
    const [globalWynDashboards, setGlobalWynDashboards] = useGlobal('wynDashboards');
    const [ globalApplicationUxi, setGlobalApplicationUxi ] = useGlobal('applicationUxi');
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const params = useTenant();
    const { success, handleLogout } = useAuth();
    const filterService = new FiltersService();

    
    const nodeTemplate = (node, options) => {
        let label = <b onClick={(event) => { console.log(event); }}>{node.label}</b>;

        return (
            <span className={options.className}>
                {label}
            </span>
        )
    }

    const listItems = [];
    const tenantsArrayItems = [
    ];
    const tenantsArray = [
        {
            key: "0",
            label: 'My Tenants',
            children: []
        }
    ];

    const HandleAternity = (event) => {
        setToLS('aternity', 'true')
        setGlobalVisibleLeft(false)
        handleDash({ event })
    }

    const HandleDash = (dash) => {
        // console.log(dash)
        removeFromLS('aternity')
        setToLS('viewingDashboard', dash.item)
        history.push('/')
        setGlobalVisibleLeft(false)
        handleDash({ id: dash.item.id, type: dash.item.type })
    }

    const selectedCountryTemplate = (option, props) => {

        if (option) {
            return (
                <div>
                    <p className='small-text'>Selected Tenant</p>
                    <div>{option.label}</div>
                    <div className='left-sidebar-tenant-badge'><Badge value={globalTenantCount} className="p-badge-info no-of-dashboards float-right"></Badge></div>

                </div>

            );
        }

        return (
            <div>
                <span>
                    {props.placeholder}
                </span>
            </div>

        );
    }

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.label}</div>
            </div>
        );
    }

    // setCount(count + 1)

    const getUsersTenants = () => {
        params.list
            .forEach((v, k) => {
                listItems.push({ label: v.displayName });
                tenantsArrayItems.push({ label: v.displayName })
                tenantsArray[0].children.push({ label: v.displayName, command: (event) => childToParent(event) })
                // listItemsTree[0].push({label: v.displayName, onClick: () => params.handleTenant(v.displayName)});
            });
        setGlobalAllTenants(listItems)
        setGlobalTenantCount(listItems.length)
        console.log(globalAllTenants)
        getWynDashboards();
    }

    useEffect(() => {
        // alert(data)
        // alert('switched')
    }, [null]);


    useEffect(() => {
        if (params.success && success) {
            params.list
            .forEach((v,k) => {
                listItems.push({label: v.displayName});
                tenantsArrayItems.push({label: v.displayName})
                tenantsArray[0].children.push({label: v.displayName, command: (event) => childToParent(event)})
                // listItemsTree[0].push({label: v.displayName, onClick: () => params.handleTenant(v.displayName)});
            });
            setGlobalAllTenants(listItems)
            setGlobalTenantCount(listItems.length)
            console.log(globalAllTenants)
        }
        
     }, [params.success]);

    // useEffect(() => {
    //     if (globalTenantCount === 0) {
    //         getUsersTenants();
    //     }
    //     if (params.success && success) {
    //         getUsersTenants();
    //     }



    // }, []);
    let newArr = [];
    const getWynDashboards = () => {
        console.log(globalWynDashboards)
        console.log(data)
        if ( success && data ) {
            let links = [];
            links = success && data.map((v, k) => <Button plain hoverIndicator key={k} onClick={() => handleDash({ id: v.id, type: v.type })}> <Card><p truncate>{v.title}</p></Card> </Button>);
            setToLS('all-dashboards', data);

            setToLS('all-dashboards-safe', data);
            const getAllDashboards = getFromLS('all-dashboards');
            // Empty array on fresh call

            var loopData = [];

            if (getAllDashboards) {
                var i;
                for (i = 0; i < getAllDashboards.length; i++) {
                    loopData = { id: getAllDashboards[i].id, title: getAllDashboards[i].title, type: getAllDashboards[i].type, command: (event) => { HandleDash(event); } }
                    newArr.push(loopData)
                }
                var aternityDash = {
                    "id": "aternity",
                    "title": "Aternity",
                    "type": "dbd",
                    command: (event) => { HandleAternity(event); }
                }
                loopData = aternityDash;
                newArr.push(loopData)
                console.log(newArr)
                setGlobalWynDashboards(newArr)
                // setGlobalDashboardCount(newArr.length);
                setGlobalDashboardCount(newArr.length);
            }

            // Sort the list alphabetially
            newArr.sort((a, b) => (a.title > b.title) ? 1 : -1)
        }
    }
    

    const onCityChange = (e) => {
        params.handleTenant(e.value.label)
        console.log(globalTenantSwitched)
        setGlobalTenantSwitched(true)
        
        console.log(e.value)
        console.log(globalApplicationUxi)

        // filterService.incidentsAppsDropdownOptions().then(data => console.log(data));
        // alert(filterService.incidentsAppsDropdownOptions());
        // setSelectedTenant(e.value);
        // console.log(selectedTenant)
        setToLS('currentTenant', e.value)
        setGlobalCurrentTenant(e.value)
        getApplicationUXiData(e.value.code)
        getWynDashboards();
        
        // window.location.reload();
        //setGlobalVisibleLeft(false)
        
    }

    
    let theCurrTenant;
    const getApplicationUXiData = (code) => {
        console.log(code)
        theCurrTenant = tenantService.translate();
        console.log(tenantService.translate())
        console.log(code);
        let typeToAnalyse = 'averageuxi';
        switch(code) {
            case 'all':
              typeToAnalyse = 'averageUxi'
              break;
            case 'mobile':
              typeToAnalyse = 'averageUxiMobile'
              break;
            case 'desktop':
                typeToAnalyse = 'averageUxiDesktop'
              break;
            case 'vdi':
                typeToAnalyse = 'averageUxiWeb'
              break;
            default:
                typeToAnalyse = 'averageUxi'
          }
        axios.get(globalDbENv + ':7443/'+ globalDbName +'/'+typeToAnalyse, config)
            .then((res)=>{
                console.log(res.data)
                console.log(theCurrTenant.toString())
                for (let item of res.data) {
                    console.log(item._id)
                    console.log(typeof item._id)
                    if ( item._id === Number(theCurrTenant) ) {
                        setGlobalApplicationUxi(item.avguxi)
                    }
                    //theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
            }).catch((err)=>{
                console.log(err);
            })
    }

    return (
        <div className='baseline-middle inline-block tenant-dropdown-select'>
            {/* <div className='mobile-only'>
        <Tree value={tenantsArray} nodeTemplate={nodeTemplate} className="transparent-bg user-menu"/>
        </div> */}
            {/* <Dropdown value={selectedTenant} options={listItems} onChange={onCityChange} optionLabel="label" placeholder="Select tenant" /> */}
            <Dropdown value={globalCurrentTenant} options={globalAllTenants} onChange={onCityChange} optionLabel="label" filter filterBy="label" placeholder="Select a Tenant"
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />

        </div>
    )
}

export default DropdownTenant;