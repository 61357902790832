import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionSelectors from './slice';

const useWyn = () => {
    const dispatch = useDispatch();
    const loading = useSelector(actionSelectors.selectLoading);
    const error = useSelector(actionSelectors.selectError);
    const success = useSelector(actionSelectors.selectSuccess);
    const data = useSelector(actionSelectors.selectData);
    
    const handleWynInit = React.useCallback(() => {
        dispatch(actionSelectors.init());
    }, [dispatch]);

    React.useEffect(() => {
        return () => dispatch(actionSelectors.init());
    }, [dispatch]);
    
    return {
        data,
        error,
        loading,
        success,
        handleWynInit
    };
};

export default useWyn;
