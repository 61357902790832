/* eslint-disable no-unused-vars */
import React, { Component, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const activityName = [];
const activityVolume = [];



let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}

const BusinessActivitiesSummary = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalCanRunApi, setGlobalCanRunApi ] = useGlobal('canRunApi');

    const basicData = {
        labels: activityName,
        datasets: [
            {
                label: 'Activity',
                data: activityVolume,
                fill: false,
                borderColor: '#474747',
                tension: .4
            }
        ]
    };
    
    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    useEffect(() => {
        if ( globalCanRunApi === true ) {
            axios.get(globalDbENv+'/business_activities_summary',config)
            .then((res)=>{
                const businessActivitiesSummary = res.data
                for (let item of businessActivitiesSummary) {
                    activityName.push(item.APPLICATION_NAME + ' - ' + item.ACTIVITY_NAME);
                    activityVolume.push(item.TOTAL_ACTIVITY_VOLUME);
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
      }, []);
    
  return (
    <div>
        <div className="card">
            <Chart width="100%" height="200px" type="line" data={basicData} options={basicOptions} />
        </div>
    </div>
  );
};

export default BusinessActivitiesSummary;
