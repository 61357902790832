/* eslint-disable no-unused-vars */
import React, { Component, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import moment from 'moment'
import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const itemRows = [];
const deviceStabilityIndex = [];
const standard = [];


let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}   

const DeviceStability = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalCanRunApi, setGlobalCanRunApi ] = useGlobal('canRunApi');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');

    const basicData = {
        labels: itemRows,
        datasets: [
            {
                label: 'Device Stability',
                data: deviceStabilityIndex,
                fill: false,
                borderColor: '#474747',
                tension: .4
            },
            {
                label: 'Industry Benchmark',
                data: standard,
                fill: false,
                borderColor: '#E9CB53',
                tension: .4
            }
        ]
    };

    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    useEffect(() => {
        if ( globalCanRunApi === true ) {
            axios.get(globalDbENv + ':7443/'+ globalDbName +'/device_stability',config)
            .then((res)=>{
                const deviceStability = res.data
                for (let item of deviceStability) {
                    itemRows.push(moment(item.TIMEFRAME).format('MMMM Do YYYY, h:mm:ss a'));
                    deviceStabilityIndex.push(item["Avg Stability Index"]);
                    standard.push(7.4);
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
      }, [globalCanRunApi, globalDbENv]);
    
  return (
    <div>
        <div className="card">
            <Chart width="100%" height="200px" type="line" data={basicData} options={basicOptions} />
        </div>
    </div>
  );
};

export default DeviceStability;
