import { combineReducers } from 'redux';
import auth from 'features/auth/slice';
import dashboards from 'features/dashboards/slice';
import tenants from 'features/tenant/slice';
import wyn from 'features/wyn/slice';

const rootReducer = combineReducers({
    auth,
    dashboards,
    tenants,
    wyn
});

export default rootReducer;
