/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import 'moment-timezone';
import { DataScroller } from 'primereact/datascroller';
import { NewsService } from './NewsService';  
    

const NewsUpdates = () => {

    const [products, setProducts] = useState([]);
    const newsService = new NewsService();

    useEffect(() => {
        newsService.getProducts().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (data) => {
        return (
            <div className="service-update-item">
                <div className="p-grid">
                    <div className="p-col p-p-0">
                        <p className='title'>{data.name}</p>
                        <p className='summary'>{data.description}</p>
                        <p><a href="#">Read More</a></p>
                    </div>
                </div>
            </div>
        );
    }

    
  return (
    <div>
        <div className="card">
            <DataScroller className='service-updates-scroller' value={products} itemTemplate={itemTemplate} rows={5} inline scrollHeight="500px" />
        </div>
    </div>
    // <ComposableMap
    //   projection="geoAzimuthalEqualArea"
    //   projectionConfig={{
    //     rotate: [58, 20, 0],
    //     scale: 400
    //   }}
    // >
    //      <ZoomableGroup zoom={1} />
    //   <Geographies geography={geoUrl}>
    //     {({ geographies }) =>
    //       geographies
    //         .filter(d => d.properties.REGION_UN === "Americas")
    //         .map(geo => (
    //           <Geography
    //             key={geo.rsmKey}
    //             geography={geo}
    //             fill="#EAEAEC"
    //             stroke="#D6D6DA"
    //           />
    //         ))
    //     }
    //   </Geographies>
    //   {markers.map(({ name, coordinates, markerOffset }) => (
    //     <Marker key={name} coordinates={coordinates}>
    //       <g
    //         fill="none"
    //         stroke="#FF5533"
    //         strokeWidth="2"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         transform="translate(-12, -24)"
    //       >
    //         <circle cx="12" cy="10" r="3" />
    //         <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
    //       </g>
    //       <text
    //         textAnchor="middle"
    //         y={markerOffset}
    //         style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
    //       >
    //         {name}
    //       </text>
    //     </Marker>
    //   ))}
    // </ComposableMap>
  );
};

export default NewsUpdates;
