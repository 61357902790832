import React, { Component, createClass, useEffect, useState, useRef } from 'react';
import NewsTicker, {Directions} from '../react-advanced-news-ticker';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { TenantLookupService } from 'pages/maindash/components/service/tenantLookup';

import axios from 'axios';

let config = {
  headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
  }
}

let theTickers = [];
const tickerDescriptions = [];

const StormTips = () => {

  const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
  const [ globalTickers, setGlobalTickers ] = useGlobal('tickers');
  const tenantService = new TenantLookupService();
  const theCurrTenant = tenantService.translate();
  const ref = useRef(null);

  const data = [
        {"id": "1000","code": "f230fh0g3","name": "Bamboo Watch","description": "Product Description","image": "bamboo-watch.jpg","price": 65,"category": "Accessories","quantity": 24,"inventoryStatus": "INSTOCK","rating": 5},
        {"id": "1001","code": "nvklal433","name": "Black Watch","description": "Product Description","image": "black-watch.jpg","price": 72,"category": "Accessories","quantity": 61,"inventoryStatus": "INSTOCK","rating": 4},
        {"id": "1002","code": "zz21cz3c1","name": "Blue Band","description": "Product Description","image": "blue-band.jpg","price": 79,"category": "Fitness","quantity": 2,"inventoryStatus": "LOWSTOCK","rating": 3},
        {"id": "1003","code": "244wgerg2","name": "Blue T-Shirt","description": "Product Description","image": "blue-t-shirt.jpg","price": 29,"category": "Clothing","quantity": 25,"inventoryStatus": "INSTOCK","rating": 5},
        {"id": "1004","code": "h456wer53","name": "Bracelet","description": "Product Description","image": "bracelet.jpg","price": 15,"category": "Accessories","quantity": 73,"inventoryStatus": "INSTOCK","rating": 4},
        {"id": "1005","code": "av2231fwg","name": "Brown Purse","description": "Product Description","image": "brown-purse.jpg","price": 120,"category": "Accessories","quantity": 0,"inventoryStatus": "OUTOFSTOCK","rating": 4},
        {"id": "1006","code": "bib36pfvm","name": "Chakra Bracelet","description": "Product Description","image": "chakra-bracelet.jpg","price": 32,"category": "Accessories","quantity": 5,"inventoryStatus": "LOWSTOCK","rating": 3},
        {"id": "1007","code": "mbvjkgip5","name": "Galaxy Earrings","description": "Product Description","image": "galaxy-earrings.jpg","price": 34,"category": "Accessories","quantity": 23,"inventoryStatus": "INSTOCK","rating": 5},
        {"id": "1008","code": "vbb124btr","name": "Game Controller","description": "Product Description","image": "game-controller.jpg","price": 99,"category": "Electronics","quantity": 2,"inventoryStatus": "LOWSTOCK","rating": 4},
        {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3}
  ]

  useEffect(() => {
    console.log(data)
    setProducts(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const productTemplate = (product) => {
    return (
      <div className="product-item">
        <div className="product-item-content">
            <div>
                <h4 className="mb-1">{product.ticker_description}</h4>
            </div>
        </div>
    </div>
    );
  }

  useEffect(() => {
    var labels = []

    if ( getFromLS('canRunApi') === true ) {
        axios.get(globalDbENv + ':7443/'+ globalDbName + '/tips_ticker', config)
        .then((res)=>{
            var tmpDesc = [];
            const ticks = res.data
            console.log(ticks)
            
            // for (let item of ticks) {
            //   console.log(item.ticker_description)
            //   tmpDesc.push(item.ticker_description)
            // }

            theTickers.push( ticks );
            console.log(theTickers);
            setGlobalTickers(theTickers[0]);
            console.log(globalTickers)
            
        }).catch((err)=>{
            console.log(err);
        })
    }
    
    
  }, [globalDbENv, globalDbName, globalTickers, setGlobalTickers]);

  return (
    <div className="carousel-demo tips-card">
            <div className="card">
                <Carousel value={globalTickers} numVisible={1} numScroll={1} orientation="vertical" verticalViewPortHeight="100px" showNavigators={0} showIndicators={false}
                    autoplayInterval={4000} itemTemplate={productTemplate} />
            </div>
        </div>
  );

}

export default StormTips;

