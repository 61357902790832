import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }
  .dashboard-toolbar{
    background-color: ${({ theme }) => theme.colors.dashboardToolbar};
  }
  .layout-config-button.p-button.p-button-icon-only{
    background-color: ${({ theme }) => theme.colors.triggerBg};
    color: ${({ theme }) => theme.colors.triggerColor};
  }
  .p-sidebar-left .p-sidebar-header{
    background: ${({ theme }) => theme.colors.sidebarHeaderBgColor} !important;
    color: ${({ theme }) => theme.colors.sidebarHeaderColor} !important;
  }
  .sidebar-seperator-title {
    color: ${({ theme }) => theme.colors.sidebarTextColour};
  }
  .p-sidebar-left .p-sidebar-header .sidebar-title{
    color: ${({ theme }) => theme.colors.h3title} !important;
  }
  .p-sidebar-right .p-sidebar-header .sidebar-title{
    color: ${({ theme }) => theme.colors.h3title} !important;
  }
  .p-sidebar-left .p-sidebar-header .p-sidebar-close-icon{
    // background: ${({ theme }) => theme.colors.body} !important;
    color: ${({ theme }) => theme.colors.body} !important;
  }
  .p-sidebar-right .p-sidebar-header .p-sidebar-close-icon{
    // background: ${({ theme }) => theme.colors.body} !important;
    color: ${({ theme }) => theme.colors.body} !important;
  }
  .sidebar-header{
    color: ${({ theme }) => theme.colors.sidebarHeaderColor};
  }
  .top-bar-bg, .top-bar-bg .p-dropdown-label {
    background: ${({ theme }) => theme.colors.topbarbg};
    color: ${({ theme }) => theme.colors.topbarTextColour} !important;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: ${({ theme }) => theme.colors.topbarTextColour};
  }
  .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: ${({ theme }) => theme.colors.topbarLinkTextColour};
  }
  .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link .p-menuitem-text:hover {
    color: ${({ theme }) => theme.colors.topbarLinkTextHoverColour} !important;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: transparent !important;
  }
  .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: ${({ theme }) => theme.colors.topbarLinkTextColour} !important;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: ${({ theme }) => theme.colors.text};
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: ${({ theme }) => theme.colors.topbarButtonHoverColour};
    color: ${({ theme }) => theme.colors.topbarTextColour};
  }
  .main-dash-component{
    background: ${({ theme }) => theme.colors.topbarbg};
  }
  .sidebar-trigger .p-button {
    background: ${({ theme }) => theme.colors.topbarPanelOpener};
    color: ${({ theme }) => theme.colors.topbarPanelOpenerColor};
  }
  .sidebar-trigger .p-button:not(a):not(.p-disabled):hover {
    background: ${({ theme }) => theme.colors.topbarPanelOpenerHover};
    color: ${({ theme }) => theme.colors.topbarPanelOpenerHoverColor};
  }
  .p-splitbutton button{
    color: ${({ theme }) => theme.colors.splitbuttoncolor} !important;
    background: ${({ theme }) => theme.colors.splitbuttonbackground} !important;
    border: ${({ theme }) => theme.colors.splitbuttonborder} !important;
  }
  .p-sidebar {
    background: ${({ theme }) => theme.colors.sidebarBGColour};
    color: ${({ theme }) => theme.colors.sidebarTextColour};
  }
  .p-sidebar .p-sidebar-content .p-badge {
    background: ${({ theme }) => theme.colors.sidebarHeaderBgColor} !important;
    color: ${({ theme }) => theme.colors.sidebarBadgeColor} !important;
  }
  .layout-config-button.p-button:not(a):not(.p-disabled):hover {
    background: ${({ theme }) => theme.colors.sidebarThemeHover};
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.sidebarThemeHover};
  }
  .panel-title-container{
    background: ${({ theme }) => theme.colors.panels.paneltitlecontainer} !important;
  }
  a {
    color: ${({ theme }) => theme.colors.link.text};
    cursor: pointer;
  }

  .p-menu.p-menu-overlay{
    background: ${({ theme }) => theme.colors.menuoverlaybg};
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
      color: ${({ theme }) => theme.colors.menuoverlaytextcolor};
  }

  .p-dialog-header{
    background: ${({ theme }) => theme.colors.dialog.headerBg} !important;
    color: ${({ theme }) => theme.colors.dialog.headerColor} !important;
  }

  .no-of-dfashboards{
    color: ${({ theme }) => theme.colors.h3title} !important;
  }

  .p-dialog-footer{
    background: ${({ theme }) => theme.colors.dialog.footerBg} !important;
    color: ${({ theme }) => theme.colors.dialog.footerColor} !important;
  }

  .side-panel-menu.p-panelmenu .p-panelmenu-header > a {
    background: ${({ theme }) => theme.colors.panels.panelmenuanchors} !important;
  }

  .side-panel-menu.p-panelmenu .p-toggleable-content .p-panelmenu-content {
    border: 0px;
    background: ${({ theme }) => theme.colors.panels.panelmenuanchors} !important;
  }

  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    color: ${({ theme }) => theme.colors.menuoverlaytextcolor} !important;
  }

  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon:before {
    color: ${({ theme }) => theme.colors.menuoverlaytextcolor} !important;
  }
  .p-panelmenu .p-menuitem-text {
    color: ${({ theme }) => theme.colors.menuoverlaytextcolor} !important;
  }

  .p-panelmenu .p-panelmenu-header > a {
    color: ${({ theme }) => theme.colors.menuoverlaytextcolor} !important;
    background: ${({ theme }) => theme.colors.transparentbg} !important;
  }

  .p-panelmenu .p-panelmenu-header.p-highlight > a:active {
    background: ${({ theme }) => theme.colors.transparentbg} !important;
    color: #ffffff;
  }

  .p-dialog-header .p-dialog-title{
      font-weight: 200;
  }

  .p-dialog-header .p-dialog-content{
      padding: 25px;
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
  }

  button.cancel {
    background-color: #ff0000;
    color: ${({ theme }) => theme.colors.button.text};
  }
  .sidebar-dash-list-button{
    background: ${({ theme }) => theme.colors.sidebarBGColour};
  }
  .sidebar-dash-list-button.p-button:hover {
    background: ${({ theme }) => theme.colors.sidebarListButton} !important;
    color: #ffffff;
    border-color: #0d89ec;
  }

  .p-tieredmenu.p-tieredmenu-overlay {
    background: ${({ theme }) => theme.colors.sidebarBGColour} !important;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  }

  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: ${({ theme }) => theme.colors.body};
  }

  .p-submenu-list .p-menu-item .p-menuitem-link {
    background: ${({ theme }) => theme.colors.body};
  }

  .p-submenu-list .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: ${({ theme }) => theme.colors.text};
  }
  
  button:disabled {
    background-color: #ebebeb;
    color: #333333;
  }

  .MuiStepLabel-label{
    color: ${({ theme }) => theme.colors.text};
  }
  .MuiStepLabel-label.Mui-active{
    color: ${({ theme }) => theme.colors.text} !important;
  }

  .bg-white-go{
    .MuiBox-root.css-8atqhb{
      background: ${({ theme }) => theme.colors.contentBackground} !important;
    }
  }

  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #1064EA;
    color: #FFFFFF;
    font-family: ${({ theme }) => theme.font};
  }

  .dashboard-toolbar button{
    color: ${({ theme }) => theme.colors.panels.headerBgPanelTitleTextColour};
    background: ${({ theme }) => theme.colors.panels.headerBgPanelTitle};
    margin-left: 10px;
    &:hover{
      color: black;
    }
  }

  .panel-content.title-container{
    background: ${({ theme }) => theme.colors.panels.headerBgPanelTitle};
    color: ${({ theme }) => theme.colors.panels.headerBgPanelTitleTextColour};
    // border-bottom: 2px solid ${({ theme }) => theme.colors.dashboardToolbar};
  }
  .panel-content.title-container button{
    border: 1px solid ${({ theme }) => theme.colors.panels.headerBgPanelTitleTextColour};
  }
  .panel-content .p-card-subtitle{
    color: ${({ theme }) => theme.colors.panels.whiteText};
  }
  .panel-content.panela1{
    background: ${({ theme }) => theme.colors.panels.panela1};
  }
  .panel-content.panela2{
    background: ${({ theme }) => theme.colors.panels.panela2};
  }
  .panel-content.panela3{
    background: ${({ theme }) => theme.colors.panels.panela3};
  }
  .panel-content.panela4{
    background: ${({ theme }) => theme.colors.panels.panela4};
  }
  .panel-content.panela1 .p-card{
    background: ${({ theme }) => theme.colors.panels.pcard1};
    padding: 10px;
  }
  .panel-content.panela2 .p-card{
    background: ${({ theme }) => theme.colors.panels.pcard2};
    color: white;
    padding: 10px;
  }
  .panel-content.panela3 .p-card{
    background: ${({ theme }) => theme.colors.panels.pcard3};
    padding: 10px;
  }
  .panel-content.panela4 .p-card{
    background: ${({ theme }) => theme.colors.panels.pcard4};
    padding: 10px;
  }
  .MuiFormControl-root label{
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }
  .MuiInput-root input{
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }

  .MuiFormHelperText-root{
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }

  .MuiOutlinedInput-root{
    border: 1px solid ${({ theme }) => theme.colors.panels.border} !important;
  }

  .MuiOutlinedInput-root .MuiSvgIcon-root{
    color: ${({ theme }) => theme.colors.panels.svgIcon} !important;
  }

  .MuiOutlinedInput-root .MuiSelect-select{
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }

  .MuiBox-root .p-card{
    background: ${({ theme }) => theme.colors.panels.pcardbg} !important;
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }

  #nt-example1 li{
    background: ${({ theme }) => theme.colors.panels.whiteText} !important;
    color: ${({ theme }) => theme.colors.panels.paneltext} !important;
  }

  .custom-panels-grey-raw-complementary{
    background: ${({ theme }) => theme.colors.panels.raw} !important;
  }
  .custom-panels-grey-lightest-complementary{
    background: ${({ theme }) => theme.colors.panels.lightest} !important;
  }
  .custom-panels-grey-lightest-complementary .panel-title{
    color: ${({ theme }) => theme.colors.panels.paneltitle} !important;
  }

  .custom-panels-alert-complimentary{
    background: ${({ theme }) => theme.colors.panels.alert} !important;
    color: white;
  }

  .custom-panels-alert-complimentary .p-datatable-table .p-datatable-tbody tr{
    background: ${({ theme }) => theme.colors.panels.alert} !important;
    color: white;
  }
  .custom-panels-grey-light-complementary{
    background: ${({ theme }) => theme.colors.panels.light} !important;
  }
  .custom-panels-grey-medium-complementary{
    background: ${({ theme }) => theme.colors.panels.medium} !important;
    color: ${({ theme }) => theme.colors.panels.whiteText} !important;
  }
  .custom-panels-grey-dark-complementary{
    background: ${({ theme }) => theme.colors.panels.dark} !important;
  }
  .custom-panels-grey-darkest-complementary{
    background: ${({ theme }) => theme.colors.panels.darkest} !important;
    color: ${({ theme }) => theme.colors.panels.whiteText} !important;
  }
  .custom-panels-grey-darkest-complementary .panel-title{
    color: ${({ theme }) => theme.colors.panels.paneltitlelight} !important;
  }
  .custom-panels-grey-darkest-complementary #nt-example1 li{
    color: ${({ theme }) => theme.colors.panels.paneltitlelight} !important;
  }
`;
