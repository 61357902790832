/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { setToLS, getFromLS } from 'features/app/utils/storage';
import { Button } from 'primereact/button';
import useGlobalCounter from '../../../../store/basket/userProvider'
import { Card } from 'primereact/card';
import Parser from 'html-react-parser';
import { useGlobal } from 'reactn';



const Basket = ({ childToParent }) => {
    // const [state, setState] = useGlobalCounter()
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [ basketItems, setBasketItems ] = useGlobal('basketItems');

    function search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].dataName === nameKey) {
                return i;
            }
        }
    }

    // let basketData = getFromLS('basket');
    let basketData = basketItems;
    

    
    const removeFromBasket = (item) => {
        let currBasket = getFromLS('basket')
        const removeIndex = currBasket.findIndex(itemi => itemi.id === item.id);
        var resultObject = search(item.dataName, currBasket);
        currBasket.splice(currBasket.indexOf(resultObject), 1)
        basketData = currBasket;
        setToLS('basket', currBasket)
        childToParent('close')
    }

    const chartVisOptions = [
        {
          "id": 0,
          "label": "Line Chart",
          "Type": "line"
        },
        {
          "id": 1,
          "label": "Bar Chart",
          "Type": "bar"
        }
      ]

    // const chartVisOptions = () => {

    // }

    const clearBasket = () => {
        localStorage.removeItem('basket');
        basketData = [];
        setToLS('basket',[])
        childToParent('close')
    }

    const renderButton = (data) => {
        let theMarkup;
        
        if(data.includes("line") === true) {
            theMarkup = "<i className='pi pi-chart-line vis-option-icon' onClick={clearBasket}></i>"
        //   return (
        //       <i className='pi pi-chart-line vis-option-icon'>
        //       </i>
        //   );
        } 
        if(data.includes("bar") === true) {
            theMarkup += "<i className='pi pi-chart-bar vis-option-icon' onClick={clearBasket}></i>"
            // return (
            //     <i className='pi pi-chart-bar vis-option-icon'>
            //     </i>
            // );
          } 
          return Parser(theMarkup)
      }

    const addToBasketArray = (item) => {
        basketData.push(item);
        setToLS('basket', basketData)
    }

    
    const hasVisualisation = (props) => {
        const isLoggedIn = props.isLoggedIn
        if ( isLoggedIn ) {
            return "asssigned";
        }
        return "Not assigned";
    }
    const [state, setState] = useGlobalCounter()
    return (
        
        <div>
            <div>
                {basketData.map((data, idx) => (
                    <Card className='p-p-3 basket'>
                        <div className="p-grid p-align-center">
                            <div className="p-col">
                                <p className='basket-item-header'>{data.dataName}</p>
                                <p className='basket-item-summary'>{data.Summary}</p>
                                <p className='basket-item-visualisation'>Assigned Visualisation Option: None</p>
                                <p className='basket-item-available-visualisations'>Available Visualisations</p>
                                <div className='p-pl-4 p-pb-4 p-pr-4'>
                                {renderButton(data.chart_options)}
                                </div>
                            </div>
                        </div>
                        <div className="p-col align-right">
                            <Button label="Remove" className="p-button-danger" styleName="margin-right: 10px;" icon="pi pi-trash" onClick={() => removeFromBasket(data)} />
                        </div>
                    </Card>

                ))}

            </div>
            <Button className="p-button-sm p-mt-5" label="Clear basket" onClick={() => clearBasket()} />
        </div>

    )
}
export default Basket;
