/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';
import { TenantLookupService } from '../components/service/tenantLookup';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}



let stackedOptions = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    aspectRatio: 1,
    plugins: {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};
let theCurrTenant;
const DeviceStabilityChart = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const [ globaDeviceStability, setGlobaDeviceStability ] = useGlobal('deviceStability');
    // const [ globalClosedIncidents, setGlobalClosedIncidents ] = useGlobal('closedIncidents');
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const tenantService = new TenantLookupService();
    
    const [devStabilityObject] = useState([
        { "labels": [] },
        { "datasets": [] },
    ]);

    const theCurrTenant = tenantService.translate();
    
    const getDeviceStabilityData = () => {
        if ( getFromLS('canRunApi') === true ) {
            setGlobaDeviceStability([])
            axios.get(globalDbENv+ ':7447/'+ globalDbName +'/averageStabilityIndex', config)
            .then((res)=>{
                const incidents = res.data
                var tmpAvg = 0;
                // console.log(theCurrTenant)
                // console.log(typeof theCurrTenant)
                for (let item of incidents) {
                    if ( item["_id"] === parseInt(theCurrTenant) ) {
                        console.log(item)
                        console.log(devStabilityObject)
                        tmpAvg = item.avguxi
                        // devStabilityObject[1].push(item["avguxi"]);
                        //theIncidents.push({'avg': item["avguxi"], 'industry_benchmark': 74});
                    }
                }

                devStabilityObject.labels = stackedData.labels
                devStabilityObject.datasets = stackedData.datasets

                stackedData.datasets[0].data[0] = tmpAvg
                //stackedData.datasets[0].data[1] = 7.4
                // devStabilityObject.datasets[0].data = 4
                // devStabilityObject.datasets[1].data = 10;
                
                console.log(stackedData.datasets[0].data[0])
                //console.log(theIncidents[0].avg)
                //devStabilityObject.datasets.data.push(theIncidents[0].avg)
                console.log(devStabilityObject)

                setGlobaDeviceStability(devStabilityObject)
                // setGlobaDeviceStability(devStabilityObject)
                // [stackedData].datasets[0].data = theIncidents[0].avg
                // console.log(stackedData)
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    useEffect(() => {
        getDeviceStabilityData()
        
    }, [globalTenantSwitched]);

    const [stackedData] = useState({
        labels: [''],
        datasets: [{
            label: 'Stability Avg',
            backgroundColor: '#42A5F5',
            data: [3]
        },{
            label: 'Industry Standard',
            backgroundColor: '#71c223',
            data: [7.4]
        }]
    });

    
    
    
  return (
    <div className='welcome-widget'>
            

            <div className="card chartheight">
                <h5>Device Stability</h5>
                <Chart type="bar" data={globaDeviceStability} options={stackedOptions} />
            </div>
        </div>
  );
};

export default DeviceStabilityChart;

