/* eslint-disable no-unused-vars */
import React from "react";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup, Graticule } from "react-simple-maps";


const geoUrl =
  "https://cdn.jsdelivr.net/npm/world-atlas@2.0.2/countries-110m.json";

const markers = [
  { id: 1, markerOffset: 15, score: "100", coordinates: [151.207, -33.867] },
  { id: 2, markerOffset: 15, score: "100", coordinates: [-80.752, 32.216] },
  { id: 3, markerOffset: 15, score: "100", coordinates: [-118.353, 34.138] },
  { id: 4, markerOffset: 15, score: "100", coordinates: [-86.93, 35.751] },
  { id: 5, markerOffset: 15, score: "99", coordinates: [-77.508, 37.642] },
  { id: 6, markerOffset: -30, score: "100", coordinates: [-58.1551, 6.8013] },
  { id: 7, markerOffset: -30, score: "100", coordinates: [-57.5759, -25.2637] },
  { id: 8, markerOffset: 15, score: "100", coordinates: [-55.2038, 5.852] },
  { id: 9, markerOffset: 15, score: "100", coordinates: [-56.1645, -34.9011] },
  { id: 10, markerOffset: 15, score: "100", coordinates: [-66.9036, 10.4806] },
  { id: 11, markerOffset: 15, score: "100", coordinates: [-77.0428, -12.0464] },
  { id: 12, markerOffset: 15, score: "100", coordinates: [24.677570, 47.487964] }
];

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627
  },
  zoom: 1
};

const MapChartSVG = () => {
  return (
    <div>
    <ComposableMap>
      <ZoomableGroup zoom={1}>
      <Graticule stroke="#F53" />
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#000000"
              stroke="#474747"
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, score, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={4} fill="#987" stroke="#fff" strokeWidth={2} />
          {/* <text
            textAnchor="right"
            y={markerOffset}
            style={{ fontFamily: "Heebo, sans-serif", fontWeight: "500", fontSize: "14px", fill: "#dedede" }}
          >
            Score: {score}
          </text> */}
        </Marker>
      ))}
      </ZoomableGroup>
    </ComposableMap>
  </div>
    // <ComposableMap
    //   projection="geoAzimuthalEqualArea"
    //   projectionConfig={{
    //     rotate: [58, 20, 0],
    //     scale: 400
    //   }}
    // >
    //      <ZoomableGroup zoom={1} />
    //   <Geographies geography={geoUrl}>
    //     {({ geographies }) =>
    //       geographies
    //         .filter(d => d.properties.REGION_UN === "Americas")
    //         .map(geo => (
    //           <Geography
    //             key={geo.rsmKey}
    //             geography={geo}
    //             fill="#EAEAEC"
    //             stroke="#D6D6DA"
    //           />
    //         ))
    //     }
    //   </Geographies>
    //   {markers.map(({ name, coordinates, markerOffset }) => (
    //     <Marker key={name} coordinates={coordinates}>
    //       <g
    //         fill="none"
    //         stroke="#FF5533"
    //         strokeWidth="2"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         transform="translate(-12, -24)"
    //       >
    //         <circle cx="12" cy="10" r="3" />
    //         <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
    //       </g>
    //       <text
    //         textAnchor="middle"
    //         y={markerOffset}
    //         style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
    //       >
    //         {name}
    //       </text>
    //     </Marker>
    //   ))}
    // </ComposableMap>
  );
};

export default MapChartSVG;
