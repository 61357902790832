/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}





const DeviceStabilityDoughnutChart = () => {
    

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');

    useEffect(() => {
        if ( getFromLS('canRunApi') === true ) {
            axios.get(globalDbENv+'/incidents', config)
            .then((res)=>{
                const incidents = res.data
                
                for (let item of incidents) {
                    theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
                // console.log(deviceStabilityIndex)
            }).catch((err)=>{
                console.log(err);
            })
        }
        
    }, []);

    const [stackedData] = useState({
        labels: ['January'],
        datasets: [{
            type: 'bar',
            label: 'your avg stability',
            backgroundColor: '#42A5F5',
            data: [
                7
            ]
        }, {
            type: 'bar',
            label: 'Industry benchmark',
            backgroundColor: '#66BB6A',
            data: [
                2
            ]
        }, {
            type: 'bar',
            label: 'Industry benchmark',
            backgroundColor: '#000000',
            data: [
                3
            ]
        }]
    });

    let stackedOptions = {
            maintainAspectRatio: false,
            indexAxis: 'y',
            aspectRatio: 1,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
    };
    
    
  return (
    <div className='welcome-widget'>
            

            <div className="card">
                <h5>Device Stability</h5>
                <Chart type="bar" data={stackedData} options={stackedOptions} />
            </div>
        </div>
  );
};

export default DeviceStabilityDoughnutChart;

