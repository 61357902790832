/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}





const Incidents = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');

    useEffect(() => {
        if ( getFromLS('canRunApi') === true ) {
            axios.get(globalDbENv  + ':7443/'+ globalDbName +'/incidents', config)
            .then((res)=>{
                const incidents = res.data
                console.log(typeof incidents)
                
                for (let item of incidents) {
                    theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
                // console.log(deviceStabilityIndex)
            }).catch((err)=>{
                console.log(err);
            })
        }
        
    }, []);
    
    
  return (
    <div>
        {theIncidents.map((data, idx) => idx < 2 && ( // <= only 2 items (
            <div className='incident-data p-mb-2' key={idx}>
                {/* <p>Application Name</p>
                <p className='data'>{data.application_name}</p> */}
                {/* <p className='data'>App / Activity Name</p> */}
                <p className='font-12'>{data.application_name} / {data.activity_name}</p>
                <p className='font-12'>Opened {data.open_time}</p>
                <p className='font-12'>Closed {data.close_time}</p>
                {/* <p>Status: </p> */}
                {/* <p>Open time</p>
                <p className='data'>{data.open_time}</p>
                <p>Close time</p>
                <p className='data'>{data.close_time}</p> */}
            </div>
        ))}
    </div>
  );
};

export default Incidents;
