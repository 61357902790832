/* eslint-disable no-unused-vars */
import React, { Component, useState, useEffect } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Chart } from 'primereact/chart';
import Incidents from './charts/incidents';

//// import Button from '@mui/material/Button';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { setToLS } from 'features/app/utils/storage';


import MapChartSVG from './map/mapChartSVG';
import DeviceStability from './charts/device-stability';
import WebAppPerformance from './charts/web-app-performance';
import WebPerformance from './charts/web-performance';
import EnterpriseApps from './charts/enterprise_apps';
import BusinessActivitiesSummary from './charts/business-activities-summary';
import { useHistory } from 'react-router-dom';
import useDashboards from 'features/dashboards/use';
import TipsOfTheDay from '../../../components/ticker/tips';
import moment from 'moment';
import axios from 'axios';

import { getFromLS } from 'features/app/utils/storage';

import { useGlobal } from 'reactn';














const Welcome = () => {
  
  
  const [subServices, setSubServices] = useState({});
  const [ avatar, setAvatar ] = useGlobal('avatar');
  const [ visibleLeft, setVisibleLeft ] = useGlobal('visibleLeft');
  const [ globalCurrentTenant, setGlobalCurrentTenant ] = useGlobal('currentTenant');
  const [ globalSubscribedServices, setGlobalSubscribedServices ] = useGlobal('subscribedServices');
  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ globalCanRunApi, setGlobalCanRunApi ] = useGlobal('canRunApi');
  setToLS('basket',[]);

  let config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
    }
  }

  useEffect(() => {
    setGlobalCanRunApi(true)
  }, [globalCanRunApi])

  useEffect(() => {
    axios(globalDbENv + "/subscribed_services", config)
      .then(data => setGlobalSubscribedServices(data.data))
      .then(console.log(globalSubscribedServices))
  }, [])




  const { loading, success, data, handleDash } = useDashboards();
  const history = useHistory();

  // const resetLayout = () => {
  //   this.setState({ layouts: {} });
  // }

  const HandleDash = (dash) => {
    console.log(dash)
    // removeFromLS('aternity')
    //setToLS('viewingDashboard', dash.item)
    history.push('/')
    handleDash({ id: dash.id, type: dash.type })
  }

  const welcomeDate = () => {
    return moment().format("MMMM Do YYYY");
  }

  

  return (
    <div className='welcome-header'>
      <div className='container-fluid p-0'>
      <div className='row panel-content loose title-container m-0'>
          <div className='col-12 col-sm-12 col-md-9 col-lg-9'>
            <div className="panel-title p-pt-2 p-pl-2">
              <p stylename="margin-top: 0;" className="yello-text">Overview.
                {/* {welcomeDate()} */}
              </p>
              <p className='small-text'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
            </div>
          </div>
          {/* <div className='col-12 col-sm-12 col-md-5 col-lg-5 hide-mobile p-0'>
            <div className='service-status'>
              <p className='my-services p-0 mr-2 mb-0 yellow-text'>My subscribed Services</p>

              {globalSubscribedServices ? globalSubscribedServices.map(item =>
                <Button key={item.dash_id} type="button" className={"mr-2 p-button-sm " + item.button_colour_class} onClick={() => HandleDash({ id: item.dash_id, type: item.dash_type })}><i className="pi pi-circle-fill ok-stat" />{item.dash_name}</Button>
              ) : null}
            </div>
          </div> */}
          <div className='col-12 col-sm-12 col-md-3 col-lg-3 hide-mobile p-0 pt-3'>
            <TipsOfTheDay />
          </div>
          
        </div>
        {/* <div className='row panel-content loose title-container m-0'>
          <div className='col-12 col-sm-12 col-md-7 col-lg-4'>
            <div className="panel-title p-pt-2 p-pl-2">
              <p stylename="margin-top: 0;" className="yello-text">Overview.
              </p>
              <p className='small-text'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-5 col-lg-8 hide-mobile p-0'>
            <div className='service-status'>
              <p className='my-services p-0 mr-2 mb-0 yellow-text'>My subscribed Services</p>

              {globalSubscribedServices ? globalSubscribedServices.map(item =>
                <Button key={item.dash_id} type="button" className={"mr-2 p-button-sm " + item.button_colour_class} onClick={() => HandleDash({ id: item.dash_id, type: item.dash_type })}><i className="pi pi-circle-fill ok-stat" />{item.dash_name}</Button>
              ) : null}
            </div>
          </div>
        </div> */}
      </div>
      <div className='container-fluid bubble-enable'>
        <div className='row mt-4'>
          <div className='col-12 col-md-12 col-sm-12 col-lg-3'>
            <div className='bubble'>
              <div className="panel-title">
                Incidents <Badge value="2" className='maindash-badge'></Badge>
                <p className='panel-description'>Viewing incidents </p>
              </div>
              <Incidents />
            </div>
            <div className='bubble mt-4'>
              <div className="panel-title">
                Web Performance
                <p className='panel-description'>Viewing average load time (Seconds)</p>
              </div>
              <WebPerformance />
            </div>
            
          </div>
          <div className='col-12 col-md-12 col-sm-12 col-lg-9'>
            <div className='bubble'>
              <div className="panel-title p-mb-0">
                Device Stability
                <p className='panel-description'>Viewing global device stability against industry benchmark </p>
              </div>
              <DeviceStability />
            </div>
            <div className='bubble mt-4'>
              <div className="panel-title">
                Web App Performance
                <p className='panel-description'>Application load time (milliseconds)</p>
              </div>
              <WebAppPerformance />
            </div>
            
            <div className='row mt-4'>
              <div className='col-12 col-md-12 col-sm-12 col-lg-4'>
                <div className='bubble'>
                  <p className="panel-title">UXI (Load time)</p>
                  <WebAppPerformance />
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-md-12 col-sm-12 col-lg-12'>
                    <div className='bubble'>
                      <div className="panel-title">
                        Enterprise Apps
                        <p className='panel-description'>User experience rating</p>
                      </div>
                      <div className="card">
                        <EnterpriseApps />
                        {/* <Chart width="100%" height="100%" type="bar" data={basicData} options={basicOptions} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12 col-sm-12 col-lg-5'>
                <div className='bubble'>
                  <div className="panel-title">
                    Business Activities Summary
                    <p className='panel-description'>Viewing business activity across applications </p>
                  </div>
                  <BusinessActivitiesSummary />
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-md-12 col-sm-12 col-lg-12'>
                    <div className='bubble'>
                      <div className="panel-title p-mb-0">
                        Global UXI
                        <p className='panel-description p-mb-0'>Viewing global user experience</p>
                      </div>
                      <MapChartSVG />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12 col-sm-12 col-lg-3'>
                <div className='bubble'>
                  <p className="panel-title">Zero Trust - <b>Threats</b></p>
                  <small>Last 24 hours</small>
                  <table width="100%">
                    <tbody>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Identified threats resolved" />Tip</td>
                      <td>635</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Firewall triggered by custom policies" />PM</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Risk level categories identifed" />CAT</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="DNS threats" />BIND</td>
                      <td>0</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  )

}

export default Welcome;