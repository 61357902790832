import { getFromLS } from 'features/app/utils/storage';

export class TenantLookupService {

    translate() {

      if ( getFromLS("canRunApi") ){
      
        const currTenant = getFromLS("currentTenant");
        const currTenantLabel = currTenant.label;

        switch(currTenantLabel) {
            case 'FusionMode':
              this.currentTenantID = '1841'
              break;
            case 'Demo #1':
              this.currentTenantID = '1'
              break;
            default:
              // code block
          }
        return this.currentTenantID;
      }
    }
}