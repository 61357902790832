/* eslint-disable no-unused-vars */
// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.css';
// import 'primeflex/primeflex.css';
// import '../../index.css';
// import ReactDOM from 'react-dom';

import React, { Component } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';


const customIcons = (
    <React.Fragment>
        <button className="p-sidebar-icon p-link p-mr-1">
            <span className="pi pi-print" />
        </button>
        <button className="p-sidebar-icon p-link p-mr-1">
            <span className="pi pi-arrow-right" />
        </button>
    </React.Fragment>
);

const SidebarBasket = () => {
    // const [ state, dispatch ] = React.useContext(UserContext)
    // visibleLeft: false,
    //         visibleRight: false,
    //         visibleTop: false,
    //         visibleBottom: false,
    //         visibleFullScreen: false,
    //         visibleCustomToolbar: false
        
    return (
        <div>
            <div className="card">
                <Sidebar visible={true} onHide={() => this.setState({ visibleLeft: false })}>
                    <h3>Left Sidebar</h3>
                </Sidebar>

            </div>
        </div>
    )
};



                
export default SidebarBasket;