import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/common/private-route';
import GuestOnlyRoute from 'components/common/guest-only-route';
import Maindash from 'pages/maindash';
import DynamicPage from 'pages/dynamic/get';
import Home from 'pages/home';
import NotFound from 'pages/not-found';
import Login from 'pages/login';
import App from 'features/app';
import ErrorComponent from 'components/error-component';

const Routes = () => (
    <BrowserRouter>
            <App data-testid='App'>
                <Switch>
                    <PrivateRoute exact path="/" component={Home} data-testid='AppHomeRoute'/>
                    <PrivateRoute path="/home" component={Home} data-testid='AppHomeRoute'/>
                    <Route  path="/dynamic" component={DynamicPage} data-testid='AppMaindashRoute'/>
                    <PrivateRoute  path="/maindash" component={Maindash} data-testid='AppMaindashRoute'/>
                    <GuestOnlyRoute path="/login" component={Login} data-testid='AppLoginRoute'/>
                    <Route path="/error" component={ErrorComponent} data-testid='AppErrorRoute'/>
                    <Route component={NotFound} data-testid='AppNotFoundRoute'/>
                </Switch>
            </App>
    </BrowserRouter>
);

export default Routes;
