import { getFromLS } from 'features/app/utils/storage';
import axios from 'axios';

export class FiltersService {

    

    incidentsAppsDropdownOptions() {
        

        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "withCredentials": false,
            }
        }

        let globalDbName = 'dev'
        let globalDbENv = 'iav.dev.stormportal.net'

      if ( getFromLS("canRunApi") ){
      
        const currTenant = getFromLS("currentTenant");
        const currTenantLabel = currTenant.label;

        axios.get(globalDbENv + ':7447/'+ globalDbName + '/incidents', config)

            .then((res)=>{
                // let tmpObj = {};
                console.log(res.data)
                // theCurrTenant = tenantService.translate();
                // console.log(res.data)
                // for (let item of res.data) {
                //     if ( item.account_id === theCurrTenant ) {
                //         if ( item.Status === 'Open' ) {
                //             incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                //             openCount.push(item);
                //         }
                //         if ( item.Status === 'Closed' ) {
                //             incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                //             closedCount.push(item);
                //         }
                //         // console.log(openCount)
                //         // console.log(incicentsApps)
                //         setGlobalIncidentsAppList(incicentsApps)
                //         chartData.datasets[0].data[0] = openCount.length
                //         console.log(chartData.datasets[1])
                //         chartData.datasets[1].data[0] = closedCount.length
                //         setGlobalOpenIncidents(chartData);
                //     }
                // }
                // console.log(chartData.datasets[0].data[0])
                // console.log(globalOpenIncidents)
                return res.data;
                
            }).catch((err)=>{
                console.log(err);
            })

        
        
      }
    }
}