import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actionSelectors from './slice';
import { load } from 'features/dashboards/slice';
import { logout } from 'features/auth/slice';
import { setToLS, getFromLS } from '../../features/app/utils/storage';

import axios from 'axios';

export function getAzureAccessToken(state) {
    return state.auth.data.accessToken;
}

export function getAzureIdToken(state) {
    return state.auth.data.idToken;
}

export function getAzureAccountName(state) {
    return state.auth.data.account.name;
}

export function getAzureExpiration(state) {
    return state.auth.data.account.idTokenClaims.exp;
}

export function getTenantSelected(state) {
    return state.tenants.selected;
}

export function* acquireToken() {
    try {
        const accessToken = yield select(getAzureAccessToken);
        const idToken = yield select(getAzureIdToken);
        const name = yield select(getAzureAccountName);
        const tenant = yield select(getTenantSelected);
        const expiresAt = yield select(getAzureExpiration)
        const wynTokenResponse = yield call(getWynToken, name, accessToken, idToken, expiresAt, tenant);
        yield put(actionSelectors.success(wynTokenResponse.data));
        yield put(load());
        setToLS('canRunApi',true)
    } catch (e) {
        yield put(logout());
        alert('throw error screen')
        console.error('error while connecting to wyn server', e)
    }
}

export function* getWynToken(name, accessToken, idToken, expiresAt, tenant) {
    const expiryDate = new Date(expiresAt * 1000).toISOString();
    let wynBaseUrl = '';
    try {
        if ( getFromLS('corsEnabled') === 'true' ){
            wynBaseUrl = 'http://localhost:8050/https://iav.dev.stormportal.net:7445/connect/token';
        } else {
            wynBaseUrl = 'https://iav.dev.stormportal.net:7445/connect/token';
        }
        
        // const wynBaseUrl = 'https://iav.stormportal.net:443/connect/token'; --LIVE
        const data = `grant_type=password&username=${name}&password=***&expires_at=${expiryDate}&tenant_path=/${tenant}&client_id=integration&client_secret=eunGKas3Pqd6FMwx9eUpdS7xmz&id_token=${idToken}&access_token=${accessToken}`;
        const response = yield axios.post(wynBaseUrl, data);
        return response;
    } catch(e) {
        console.log(e)
        alert(e)
        yield put(actionSelectors.error(e));
    }
}

export default function* wynSaga() {
    yield all([
        takeLatest(actionSelectors.load.type , acquireToken)
    ]);
}
