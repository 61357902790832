import { all } from 'redux-saga/effects';
import authSaga from 'features/auth/saga';
import dashboardsSaga from 'features/dashboards/saga';
import tenantsSaga from 'features/tenant/saga';
import wynSaga from 'features/wyn/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        dashboardsSaga(),
        tenantsSaga(),
        wynSaga()
    ]);
}
