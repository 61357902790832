/* eslint-disable no-unused-vars */
import React, { Component, useState, useEffect } from 'react';

/* New Widgets */

//// import Button from '@mui/material/Button';
import { setToLS } from 'features/app/utils/storage';


import { useHistory } from 'react-router-dom';
import useDashboards from 'features/dashboards/use';
import TipsOfTheDay from '../../components/ticker/tips'

import { TenantLookupService } from 'pages/maindash/components/service/tenantLookup';



import moment from 'moment';
import axios from 'axios';

import { getFromLS } from 'features/app/utils/storage';

import { useGlobal } from 'reactn';














const Welcome2 = () => {


    const [subServices, setSubServices] = useState({});
    const [avatar, setAvatar] = useGlobal('avatar');
    const [visibleLeft, setVisibleLeft] = useGlobal('visibleLeft');
    const [globalCurrentTenant, setGlobalCurrentTenant] = useGlobal('currentTenant');
    const [globalSubscribedServices, setGlobalSubscribedServices] = useGlobal('subscribedServices');
    const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
    const [globalCanRunApi, setGlobalCanRunApi] = useGlobal('canRunApi');
    const tenantService = new TenantLookupService();

    const theCurrTenant = tenantService.translate();

    setToLS('basket', []);

    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "withCredentials": false,
        }
    }

    useEffect(() => {
        setGlobalCanRunApi(true)
    }, [globalCanRunApi])

    useEffect(() => {
        axios(globalDbENv + "/subscribed_services", config)
            .then(data => setGlobalSubscribedServices(data.data))
            .then(console.log(globalSubscribedServices))
    }, [])




    const { loading, success, data, handleDash } = useDashboards();
    const history = useHistory();

    // const resetLayout = () => {
    //   this.setState({ layouts: {} });
    // }

    const HandleDash = (dash) => {
        console.log(dash)
        // removeFromLS('aternity')
        //setToLS('viewingDashboard', dash.item)
        history.push('/')
        handleDash({ id: dash.id, type: dash.type })
    }

    const welcomeDate = () => {
        return moment().format("MMMM Do YYYY");
    }



    return (
        <div className='welcome-header'>
            <div className='container-fluid p-0'>
                <div className='row panel-content loose title-container m-0'>
                    <div className='col-12 col-sm-12 col-md-9 col-lg-9'>
                        <div className="panel-title p-pt-2 p-pl-2">
                            <p stylename="margin-top: 0;" className="yello-text">Overview.
                                {/* {welcomeDate()} */}
                            </p>
                            <p className='small-text'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-3 col-lg-3 hide-mobile p-0 pt-3'>
                        <TipsOfTheDay />
                    </div>

                </div>
            </div>
            <div className='container-fluid bubble-enable main-dash-welcome-page'>
                <div className='row mt-4 welcome-page-widget-row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
                        Here
                    </div>
                </div>

            </div>
        </div>
    )

}

export default Welcome2;