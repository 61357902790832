/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}





const DoughnutChart = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');

    useEffect(() => {
        if ( getFromLS('canRunApi') === true ) {
            axios.get(globalDbENv+'/incidents', config)
            .then((res)=>{
                const incidents = res.data
                
                for (let item of incidents) {
                    theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
                // console.log(deviceStabilityIndex)
            }).catch((err)=>{
                console.log(err);
            })
        }
        
    }, []);

    const [chartData] = useState({
        labels: ['A', 'B', 'C'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });
    
    
  return (
    <div className="card welcome-widget">
            

            <div>
                <h5>Doughnut Chart</h5>
                <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
            </div>
        </div>
  );
};

export default DoughnutChart;

