import { createSelector, createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selected: null,
    list: null,
    dialog: true,
    loading: false,
    success: false,
    error: null
};

const tenants = createSlice({
    name: 'tenants',
    initialState: initialState,
    reducers: {
        init(state) {
            state.selected = null;
            state.list = null;
            state.dialog = true;
            state.success = false;
            state.error = null;
            state.loading = false;
        },
        load(state) {
            state.loading = true;
            state.success = false;
            state.error = null;
        },
        set(state, action) {
            state.selected = action.payload;
            state.dialog = false;
        },
        reset(state) {
            state.selected = null;
            state.dialog = true;
        },
        success(state, action) {
            state.list = action.payload;
            state.error = null;
            state.success = true;
            state.loading = false;
        },
        error(state, action) {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        }
    }
});

export const {init, load, set, reset, success, error} = tenants.actions;

const selectDomain = (state) => state.tenants || initialState;
export const selectLoading = createSelector([selectDomain], state => state.loading);
export const selectSuccess = createSelector([selectDomain], state => state.success);
export const selectError = createSelector([selectDomain], state => state.error);
export const selectTenants = createSelector([selectDomain], state => state.list);
export const selectTenantSelected = createSelector([selectDomain], state => state.selected);
export const selectDialog = createSelector([selectDomain], state => state.dialog);

export default tenants.reducer;
