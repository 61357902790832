import { useEffect, useState } from 'react';
import { setToLS, getFromLS } from '../utils/storage';
import _ from 'lodash';
import { useGlobal } from 'reactn';

export const useTheme = () => {
    const themes = getFromLS('all-themes');
    const [theme, setTheme] = useGlobal('currentTheme');
    const [themeLoaded, setThemeLoaded] = useState(false);
    const [ globalTheme, setGlobalTheme ] = useGlobal('currentTheme');

    const setMode = mode => {
        setToLS('theme', mode)
        setTheme(mode);
    };

    const getFonts = () => {
        const allFonts = _.values(_.mapValues(themes.data, 'font'));
        return allFonts;
    }

    useEffect(() => {
        const localTheme = globalTheme;
        localTheme ? setTheme(localTheme) : setTheme(themes.data.seaWave);
        setThemeLoaded(true);
    }, []);
    
    return { theme, themeLoaded, setMode, getFonts };
};
