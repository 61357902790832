import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actionSelectors from './slice';
import axios from 'axios';



export function getAzureToken(state) {
    return state.auth.data.accessToken;
}

export function* getAllGroups(accessToken) {
    try {
        const config = { 'Authorization': `Bearer ${accessToken}`, 'Content-type': `application/json`};
        const data = {
            'securityEnabledOnly': `true`
        }
        // This call gets all groups (lower security)
        // const response = yield axios.get(`https://graph.microsoft.com/v1.0/groups`, {headers: config});

        // Get Member Group ID's (The right way)
        const response = yield axios.post(`https://graph.microsoft.com/v1.0/me/getMemberGroups`, data, {headers: config});
        return response;
    } catch (e) {
        console.log('error getting group tenants from microsoft graph', e);
        throw(e)
    }
}


export function* getTenantsList() {
    try {
        const accessToken = yield select(getAzureToken);
        const azureGroups = yield call(getAllGroups, accessToken);
        const azureGroupIdList = azureGroups.data.value

        // Loop through Member Groups ID's call to get data for each
        const config = { 'Authorization': `Bearer ${accessToken}`};
        let requests = [];
        let theAzureGroupsSecure = [];
        for(let i=0; i <azureGroupIdList.length; i++) {
            requests[i] = yield call(axios.get, `https://graph.microsoft.com/v1.0/groups/`+azureGroupIdList[i], {headers: config});
            theAzureGroupsSecure.push(requests[i].data)
        }
        // sort list (not working currently)
        theAzureGroupsSecure.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1)

        // Put the groups to store for app
        yield put(actionSelectors.success(Object.values(theAzureGroupsSecure)));
    } catch (e) {
        yield put(actionSelectors.error(e));
        console.error(e)
    }
}

export default function* tenantsSaga() {
    yield all([
        takeLatest(actionSelectors.load.type , getTenantsList),
    ]);
}
