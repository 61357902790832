

export const setToLS = (key, value) => {
    // console.log(value)
    window.localStorage.setItem(key, JSON.stringify(value));
}

export const getFromLS = key => {
    const value = window.localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
}

export const getFromSS = key => {
    const value = window.sessionStorage.getItem(key)
    
    if (value) {
        return JSON.parse(value);
    }
}

export const removeFromLS = key => {
    const value = window.localStorage.removeItem(key);

    if (value) {
        return JSON.parse(value);
    }
}