import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import useAuth from 'features/auth/use';

export function getRedirectPath(auth) {
    return auth ? '/' : null;
}

const GuestOnlyRoute = ({component: Component, ...rest}) => {
    const { data } = useAuth();
    const redirectPath = getRedirectPath(!!data);
    
    return (<Route {...rest} render={props => (
        !redirectPath ? (
            <Component {...props} />
        ) : (
            <Redirect to={{
                pathname: redirectPath,
                state: {from: props.location}
            }}
            />
        )
    )}/>);
};

GuestOnlyRoute.propTypes = {
    component: PropTypes.any.isRequired,
    auth: PropTypes.object
};

export default React.memo(GuestOnlyRoute);
