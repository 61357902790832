/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';
import { AutoComplete } from 'primereact/autocomplete';




  function getFromLS(key) {
    let ls = {};
    global.localStorage.removeItem("rgl-8");
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
      
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

  
class HelpSearch extends Component {
    

    constructor(props) {
        super(props);
        
        this.state = {
            selectedCity: null,
            filteredCities: null,
            // layouts: JSON.parse(JSON.stringify(originalLayouts))
        };

        this.searchCity = this.searchCity.bind(this);
        this.groupedItemTemplate = this.groupedItemTemplate.bind(this);
        this.groupedCities = [
            {
                label: 'Widgets', code: 'Widgets',
                items: [
                    { label: 'Adding Widgets', value: 'Adding_Widgets' },
                    { label: 'Removing Widgets', value: 'Removing_Widgets' }
                ]
            },
            {
                label: 'Dashboards', code: 'Dashboards',
                items: [
                    { label: 'Create new dashboard', value: 'Chicago' },
                    { label: 'Edit dashboard', value: 'Los Angeles' },
                    { label: 'Remove dashboard', value: 'New York' }
                ]
            },
            {
                label: 'Connectivity', code: 'Connectivity',
                items: [
                    { label: 'Connectivity Help 1', value: 'Kyoto' },
                    { label: 'Connectivity Help 1', value: 'Osaka' },
                    { label: 'Connectivity Help 1', value: 'Tokyo' },
                    { label: 'Connectivity Help 1', value: 'Yokohama' }
                ]
            }
        ];
    }

    searchCity(event) {
        let query = event.query;
        let filteredCities = [];

        for (let country of this.groupedCities) {
            let filteredItems = country.items.filter((item) => item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1);
            if (filteredItems && filteredItems.length) {
                filteredCities.push({...country, ...{items: filteredItems}});
            }
        }

        this.setState({ filteredCities });
    }

    groupedItemTemplate(item) {
        return (
            <div className="p-d-flex p-ai-center country-item">
                {/* <img alt={item.name} src={`showcase/demo/images/flag_placeholder.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${item.code.toLowerCase()}`} /> */}
                <div>{item.label}</div>
            </div>
        );
    }

    static get defaultProps() {
        
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 30
        };
    }

    componentDidMount() {
        
    }

    resetLayout() {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

  render() {
    return (
        <div>
            <AutoComplete className="width100Percent" value={this.state.selectedCity} suggestions={this.state.filteredCities} completeMethod={this.searchCity} field="label" placeholder="Search Help" optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={this.groupedItemTemplate} onChange={(e) => this.setState({ selectedCity: e.value })}/>
      </div>
        
    )
  }
}

export default HelpSearch;