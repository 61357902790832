export class NewsService {

    getReportItems() {
        return fetch('./data/appBar/reports.json').then(res => res.json()).then(d => d.data);
    }

    getNewsItems() {
        return fetch('./data/appBar/news-updates.json').then(res => res.json()).then(d => d.data);
    }

    getServiceUpdateItems() {
        return fetch('./data/appBar/service-updates.json').then(res => res.json()).then(d => d.data);
    }

}