import API from '../apiservice/ApiManager';
import { setToLS, getFromLS } from '../features/app/utils/storage';

// const dbenv = getFromLS('dbenvname')
// const corsEnabled = getFromLS('corsEnabled')

// let BASE_URL = 'https://iav.dev.stormportal.net:7443/dev/';
// if (dbenv === 'dev') {
// 	if (corsEnabled === 'false') {
// 		BASE_URL = 'https://iav.dev.stormportal.net:7443/dev/';
// 	}
// 	if (corsEnabled === 'true') {
// 		BASE_URL = 'http://localhost:8050/https://iav.dev.stormportal.net:7443/dev/';
// 	}
//   }

export const ApiGet = (url) => {
    return API.get(url);
}