/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

const DataTableStripedDemo = () => {
    const [products, setProducts] = useState(data);

    useEffect(() => {
        fetch('service/down.json')
          .then((res) => res.json())
          .then((data) => setProducts({...data}))
          .then((data) => alert({...data}));
      }, []);

    return (
        <div>
            <div className="card">
                <DataTable value={deviceStatuses} stripedRows responsiveLayout="scroll">
                    <Column field="AGENT_CURRENT_STATUS" header="Current Status"></Column>
                    <Column field="DEVICE_PERFORMANCE_SCORE" header="Performance Score"></Column>
                    <Column field="HEALTH_STATUS" header="Status"></Column>
                </DataTable>
            </div>
        </div>
    );
}

const deviceStatuses = deviceStatus.value;

const devicesStatus = deviceStatuses.reduce((catsSoFar, { AGENT_CURRENT_STATUS, DEVICE_HEALTH_SCORE }) => {
if (!catsSoFar[AGENT_CURRENT_STATUS]) catsSoFar[AGENT_CURRENT_STATUS] = [];
catsSoFar[AGENT_CURRENT_STATUS].push(DEVICE_HEALTH_SCORE);
return catsSoFar;
}, {});

const AverageDisconnectedHealth = () => {
    for(var i = 0; i < devicesStatus.Disconnected.length; i++) {
        // total += grades[i];
    }
}
AverageDisconnectedHealth()

 const uxiChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  const stabilityChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [100, 50, 300],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  
  const lightOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
  };
  
  const stackedData = {
    labels: ['Kim Hunt', 'Aaron De Pury', 'Aanyah Cook', 'Aaron Emberson', 'Abdul Haq', 'Aaron Porter', 'Aaron Reid'],
    datasets: [{
        type: 'bar',
        label: 'Web Browsing',
        backgroundColor: '#42A5F5',
        data: [
            50,
            25,
            12,
            48,
            90,
            76,
            42
        ]
    }, {
        type: 'bar',
        label: 'CDV Viewer',
        backgroundColor: '#66BB6A',
        data: [
            21,
            84,
            24,
            75,
            37,
            65,
            34
        ]
    }, {
        type: 'bar',
        label: 'Other',
        backgroundColor: '#FFA726',
        data: [
            41,
            52,
            24,
            74,
            23,
            21,
            32
        ]
    }]
  };
  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
  };

  function getFromLS(key) {
    let ls = {};
    global.localStorage.removeItem("rgl-8");
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
      
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }


class Overview extends Component {
    

    constructor(props) {
        super(props);
        
        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts))
        };
    }

    static get defaultProps() {
        
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 30
        };
    }

    componentDidMount() {
    }

    resetLayout() {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

  render() {
    return (
        <div>
        {/* <button onClick={() => this.resetLayout()}>Reset Layout</button> */}
        <ResponsiveReactGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={30}
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
        >
          <div className="panel-content title-container" key="1" data-grid={{ w: 12, h: 2, x: 8, y: 0, minW: 2, minH: 3 }}>
            <div className="panel-title">
                <p stylename="margin-top: 0;">Overview</p>
                <small>Overview > All devices</small>
            </div>
          </div>
          <div className="panel-content custom-panels-ededed-complementary swerveb" key="2" data-grid={{ w: 3, h: 13, x: 0, y: 0, minW: 2, minH: 3 }}>
            <p className="panel-title">Device Status
                {/* <Badge value="15" severity="danger" size="small"></Badge> */}
            </p>
            <div>
                <Card className="bg094009" title={devicesStatus.Reporting.length + devicesStatus.Disconnected.length} subTitle="Total Devices" style={{ width: '100%' }}></Card>
            </div>
            <Card className="bg094009" title={devicesStatus.Disconnected.length} subTitle="Disconnected Devices" style={{ width: '100%' }}></Card>
            <Card className="bg094009" title={devicesStatus.Reporting.length} subTitle="Reporting Devices" style={{ width: '100%' }}></Card>
            {/* <Card className="bg094009" title={devicesStatus.Reporting.length} subTitle="Average Disconnected Health" style={{ width: '100%' }}></Card> */}
          </div>
          <div className="panel-content  custom-panels-ededed-complimentary swervec" key="3" data-grid={{ w: 9, h: 25, x: 3, y: 0, minW: 2, minH: 3 }}>
            <p className="panel-title">All devices <Badge value={deviceStatuses.length} severity="danger" size="small"></Badge></p>
            <DataTableStripedDemo />
            
          </div>
          <div className="panel-content panela1" key="4" data-grid={{ w: 2, h: 25, x: 7, y: 0, minW: 2, minH: 3 }}>
          <p className="panel-title">Notifications</p>
          <Card className="bg094009" title="41,000" subTitle="Devices enabled" style={{ width: '100%' }}>
            
              
          </Card>
          <Card className="bg094009" title="24,516" subTitle="Devices active" style={{ width: '100%' }}>
            
          </Card>
          <Card className="bg094009" title="14,516" subTitle="Devices with bandwidth issues" style={{ width: '100%' }}>
            
          </Card>
          <Card className="bg6f0809" title="8,023" subTitle="Devices offline" style={{ width: '100%' }}>
            
          </Card>
          <Chart type="bar" data={stackedData} options={stackedOptions} />
          </div>
          <div className="panel-content panela4" key="5" data-grid={{ w: 3, h: 25, x: 10, y: 0, minW: 2, minH: 3 }}>
            UXI
            <Chart type="pie" data={uxiChartData} options={lightOptions} style={{ position: 'relative', width: '80%' }} />
            
            <p>Stability Index</p>
            <Chart type="pie" data={stabilityChartData} options={lightOptions} style={{ position: 'relative', width: '80%' }} />
          </div>
          
        </ResponsiveReactGridLayout>
      </div>
        
    )
  }
}

export default Overview;