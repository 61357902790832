/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from 'components/routes';
import reportWebVitals from './reportWebVitals';
import store from './store';
// import {useGlobalState, createGlobalState} from 'state-pool';
import { GlobalCounterProvider } from './store/basket/userProvider';
import { setGlobal } from 'reactn';
import { useGlobal } from 'reactn';
import Modal from 'react-modal';
import { ModalProvider } from 'components/modal/modalContext';
import './assets/bootstrap.css';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';


import './assets/style.css';

import './assets/style.scss';

import * as themes from './features/app/theme/schema.json';
import { setToLS, getFromLS } from './features/app/utils/storage';


const Index = () => {
    //alert(process.env.REACT_APP_ENV)
    setGlobal({
      webAppPerfDataAvailable: null,
      visibleLeft: false,
      visibleBasket: false,
      basketCount: 0,
      dashboardCount: 0,
      tenantCount: 0,
      tenantSwitched: false,
      bwItems: [],
      canRunApi: false,
      applicationUxi: 2.75,
      currentUserDetails: [],
      wynDashboards: getFromLS('all-dashboards'),
      dbenv: 'https://iav.dev.stormportal.net',
      dbenvname: 'dev',
      corsEnabled: 'true',
      startupKey: true,
      currentTenant: getFromLS('currentTenant'),
      allTenants: getFromLS('allTenants'),

      tickers: [],

      openIncidents: null,
      closedIncidents: null,
      incidentAppList: null,
      newsItems: null,
      newsType: 'news',

      loginQuoteItems: null,

      selectedOdometer: null,

      getAppDataFlag: false,
      appWidgets: [],
      basketItems: [],
      customDashboards: [],
      customDashboardsSidebar: [],
      subscribedServices: [],

      deviceStabilityAvg: null,

      dynamicDashContent: [],
      dynamicData: null,

      performanceComparisonChartdata: null,
      performanceComparisonDefault: { "name": "OnLoad Stats", "code": "onload" },
      performanceComparisonXAxis: [{"one":1}],
      performanceComparisonPageViews: [],
      performanceComparison: [],

      webAppPerformanceChartData: null,
      webAppPerformanceChartDataUsers: null,
      webAppPerformanceChartView: 'Users',

      dyn_dash_widget_collection_options: null,
      dyn_dash_widget_collection_selected: null,
      dyn_dash_widget_collection_selected_fields_options: null,
      dyn_dash_widget_collection_selected_fields_option_selected: null,

      dyn_dash_widget_available_chart_types: [],

      dyn_dash_name: null,
      dyn_dash_widget_name: null,
      dyn_dash_widget_visualisation: null,
      

      

      
      currentTheme: {
        "id": "T_001",
        "name": "Light",
        "repColour": "p-button-primary",
        "colors": {
            "body": "#FFFFFF",
            "triggerBg": "#002858",
            "triggerColor": "#ffffff",
            "sidebarHeaderBgColor": "#191919",
            "sidebarHeaderColor": "#000000",
            "h3title": "#ffffff",
            "text": "#000000",
            "topbarbg": "rgb(195 217 215)",
            "cogsimage": "cogwheel-light.png",
            "contentBackground": "#ffffff",
            "dashboardToolbar": "rgb(93 100 99)",
            "topbarPanelOpener": "#000000",
            "topbarPanelOpenerColor": "#ffffff",
            "topbarPanelOpenerHover": "#f6a11c",
            "topbarPanelOpenerHoverColor": "#000000",
            "topbarTextColour": "#000000",
            "topbarLinkTextColour": "#686868",
            "topbarLinkTextHoverColour": "#0000000",
            "topbarButtonHoverColour": "transparent",
            "sidebarBGColour": "#d8e2e7",
            "sidebarTextColour": "#353535",
            "sidebarListButton": "#8a8a8a",
            "sidebarThemeHover": "#ffffff",
            "sidebarThemeHovertext": "#000000",
            "menuoverlaybg": "#ffffff",
            "menuoverlaytextcolor": "#000000",
            "transparentbg": "transparent",
            "button": {
                "text": "#FFFFFF",
                "background": "#000000"
            },
            "link": {
                "text": "teal",
                "opacity": 1
            },
            "dialog": {
                "headerBg": "#FDEACB",
                "headerColor": "#000000",
                "footerBg": "#FDEACB",
                "footerColor": "#ffffff"
            },
            "panels": {
                "pcardbg": "#f7f7f7",
                "alert": "rgb(221, 153, 144)",
                "svgIcon": "rgb(0,0,0)",
                "border": "transparent",
                "headerBg": "#dceae9",
                "headerBgPanelTitle": "#222227",
                "headerBgPanelTitleTextColour": "black",
                "paneltitlecontainer": "#22227",
                "panelmenuanchors": "#dceae9",
                "panela1": "#dceae9",
                "panela2": "#c5c5c5",
                "panela3": "#939393",
                "panela4": "#5a5a5a",
                "pcard1": "#cecece",
                "pcard2": "#a9a8a8",
                "pcard3": "#717171",
                "pcard4": "#5a5a5a",
                "raw": "#c3d9d7",
                "lightest": "rgb(241,241,241)",
                "light": "rgb(181,181,181)",
                "medium": "#b5b5b5",
                "dark": "rgb(0,181,255)",
                "darkest": "#ffffff",
                "whiteText": "#ffffff",
                "paneltitle": "#000000",
                "paneltitlelight": "#ffffff",
                "paneltext": "#000000"
            },
            "splitbuttoncolor": "#000000",
            "splitbuttonbackground": "#ffffff",
            "splitbuttonborder": "0px solid #8e8e8e"
        },
        "font": "Heebo, sans-serif"
      }
    });

    // const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');

    const authenticate = () => {
      return new Promise(resolve => setTimeout(resolve, 3000)) // 2 seconds
    }

    useEffect(() => {
      const withCORS = process.env.REACT_APP_CORS_SERVER;
      // alert(withCORS);
      const currEnv = process.env.REACT_APP_ENV;
      // alert(currEnv)
      // if (withCORS === 'false') {
      //   setEnvData(process.env.REACT_APP_CORS_SERVER)
      //   setGlobal.corsEnabled = 'false'; 
      //   setToLS('corsEnabled','false')
      // }
      // if (withCORS === 'true') {
      //   setEnvData(process.env.REACT_APP_CORS_SERVER)
      //   setGlobal.corsEnabled = 'true'; 
      //   setToLS('corsEnabled','true')
      // }
      if (currEnv === 'local') {
        setEnvData(process.env.REACT_APP_ENV)
        setGlobal.dbenv = "http://localhost:8000/local/" 
        setToLS('dbenv','http://localhost:8000/local/')
      }
      if (currEnv === 'dev') {
        setEnvData(process.env.REACT_APP_ENV)
        setToLS('dbenv','https://iav.dev.stormportal.net')
        setToLS('dbenvname',currEnv)
        if (withCORS === 'false') {
          setToLS('corsEnabled','false')
          setGlobal.dbenv = 'https://iav.dev.stormportal.net'
        }
        if (withCORS === 'true') {
          setGlobal.dbenv = 'http://localhost:8050/https://iav.dev.stormportal.net'
          setToLS('corsEnabled','true')
        }
      }
      if (currEnv === 'prod') {
        setEnvData(process.env.REACT_APP_ENV)
        setGlobal.dbenv = "https://iav.stormportal.net:7443/production/"
        setToLS('dbenv','https://iav.stormportal.net:7443/production/')
      }
      
      // window.addEventListener('load', (event) => {
      //   const ele = document.getElementById('ipl-progress-indicator')
      //   if(ele){
      //     // fade out
      //     ele.classList.add('available')
      //     setTimeout(() => {
      //       // remove from DOM
      //       ele.outerHTML = ''
      //     }, 2000)
      //   }
      // });
      authenticate().then(() => {
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
          // fade out
          ele.classList.add('available')
          setTimeout(() => {
            // remove from DOM
            ele.outerHTML = ''
          }, 2000)
        }
      })
      
    },[null]);
    
    const [envData, setEnvData] = useState(null);
    const [corsData, setCorsData] = useState(null);
    // const [ globalTheme, setGlobalTheme ] = useGlobal('currentTheme');
    

    
    
    setToLS('startup', true);
    setToLS('all-themes', themes.default);
    var themeSet = getFromLS('theme');
    if ( !themeSet ) {
      setToLS('theme', {
        "id": "T_001",
        "name": "Light",
        "repColour": "p-button-primary",
        "colors": {
            "body": "#FFFFFF",
            "triggerBg": "#002858",
            "triggerColor": "#ffffff",
            "sidebarHeaderBgColor": "#191919",
            "sidebarHeaderColor": "#000000",
            "h3title": "#ffffff",
            "text": "#000000",
            "topbarbg": "rgb(195 217 215)",
            "cogsimage": "cogwheel-light.png",
            "contentBackground": "#ffffff",
            "dashboardToolbar": "rgb(123 170 164)",
            "topbarPanelOpener": "#000000",
            "topbarPanelOpenerColor": "#ffffff",
            "topbarPanelOpenerHover": "#f6a11c",
            "topbarPanelOpenerHoverColor": "#000000",
            "topbarTextColour": "#000000",
            "topbarLinkTextColour": "#686868",
            "topbarLinkTextHoverColour": "#0000000",
            "topbarButtonHoverColour": "transparent",
            "sidebarBGColour": "#d8e2e7",
            "sidebarTextColour": "#353535",
            "sidebarListButton": "#8a8a8a",
            "sidebarThemeHover": "#ffffff",
            "sidebarThemeHovertext": "#000000",
            "menuoverlaybg": "#ffffff",
            "menuoverlaytextcolor": "#000000",
            "transparentbg": "transparent",
            "button": {
                "text": "#FFFFFF",
                "background": "#000000"
            },
            "link": {
                "text": "teal",
                "opacity": 1
            },
            "dialog": {
                "headerBg": "#FDEACB",
                "headerColor": "#000000",
                "footerBg": "#FDEACB",
                "footerColor": "#ffffff"
            },
            "panels": {
                "pcardbg": "#f7f7f7",
                "alert": "rgb(221, 153, 144)",
                "svgIcon": "rgb(0,0,0)",
                "border": "transparent",
                "headerBg": "#dceae9",
                "headerBgPanelTitle": "#222227",
                "headerBgPanelTitleTextColour": "black",
                "paneltitlecontainer": "#22227",
                "panelmenuanchors": "#dceae9",
                "panela1": "#dceae9",
                "panela2": "#c5c5c5",
                "panela3": "#939393",
                "panela4": "#5a5a5a",
                "pcard1": "#cecece",
                "pcard2": "#a9a8a8",
                "pcard3": "#717171",
                "pcard4": "#5a5a5a",
                "raw": "#c3d9d7",
                "lightest": "rgb(241,241,241)",
                "light": "rgb(181,181,181)",
                "medium": "#b5b5b5",
                "dark": "rgb(0,181,255)",
                "darkest": "#ffffff",
                "whiteText": "#ffffff",
                "paneltitle": "#000000",
                "paneltitlelight": "#ffffff",
                "paneltext": "#000000"
            },
            "splitbuttoncolor": "#000000",
            "splitbuttonbackground": "#ffffff",
            "splitbuttonborder": "0px solid #8e8e8e"
        },
        "font": "Heebo, sans-serif"
      });
    }
    
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

    Modal.setAppElement('body');
  
    return(
        <React.StrictMode>
        <Provider store={store}>
        <GlobalCounterProvider>
        <ModalProvider>
        {/* <div>
      <button onClick={openModal}>Open Modal</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form>
      </Modal>
    </div> */}
            <Routes/>
            </ModalProvider>
        </GlobalCounterProvider>
        </Provider>
        </React.StrictMode>
    )
  }
  
  ReactDOM.render(
      <Index />,
    document.getElementById('root')
  );
  


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
