/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';


class Wfa extends Component {
    

    constructor(props) {
        super(props);
        
        this.state = {
            
        };
    }

    static get defaultProps() {
        
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 30
        };
    }

    componentDidMount() {
    }


  render() {
    return (
        <div className='container-fluid'>
            <div className='row panel-content loose title-container pl-2 pr-2'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                    <div className="panel-title p-pt-2 p-pl-2">
                        <p stylename="margin-top: 0;">WFA</p>
                    </div>
                </div>
            </div>
            <div className='row pl-2 p-r-2 mt-3'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                    <p className="biguns-header fw300 p-mt-0 p-mb-2">THE WFA.</p>
                    <p className="biguns-header fw200 up p-mt-0">Service Sub.</p>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-7'>
                    Status
                </div>
            </div>
        </div>
    )
  }
}

export default Wfa;