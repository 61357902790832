// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
// import rootReducer from './reducers';
// import rootSaga from './sagas';
// import { ENABLE_REDUX_DEBUGGING } from 'config';

// const sagaMiddleware = createSagaMiddleware();

// const middleware = [...getDefaultMiddleware(), sagaMiddleware];

// const preloadedState = {};

// const store = configureStore({
//     reducer: rootReducer,
//     middleware,
//     devTools: ENABLE_REDUX_DEBUGGING,
//     preloadedState,
// });

// sagaMiddleware.run(rootSaga);

// export default store;



import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducers'
import mySaga from './sagas'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
const store = createStore(reducer, applyMiddleware(sagaMiddleware))

// then run the saga
sagaMiddleware.run(mySaga)

// render the application

export default store;