import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from "styled-components";
import WebFont from 'webfontloader';
import { GlobalStyles } from './theme/GlobalStyles';
import {useTheme} from './theme/useTheme';
import ThemeSelector from './ThemeSelector';
import { Sidebar } from 'primereact/sidebar';
import { useSelector } from 'react-redux';
import * as authActionSelectors from '../../features/auth/slice';
import * as sliceActionSelectors from '../../features/auth/slice';
import useAuth from 'features/auth/use';
import { useGlobal } from 'reactn';
import { setToLS, getFromLS } from 'features/app/utils/storage';

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "config/authConfig";
import { MsalProvider } from '@azure/msal-react';

const Container = styled.div`
  margin: px auto 0px auto;
`;

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}




export function getRedirectPath(selectedThemeGo) {
  return selectedThemeGo
}

const App = ({children}) => {

  const [ globalCurrentUserDetails, setGlobalCurrentUserDetails ] = useGlobal('currentUserDetails');

  msalInstance.addEventCallback((event) => {
    // const [ globalCurrentUserDetails, setGlobalCurrentUserDetails ] = useGlobal('currentUserDetails');
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
          setToLS('currentUserInfo',account)
          msalInstance.setActiveAccount(account);
      }
  });
  

  
  const { handleLogin, handleLogout } = useAuth();
  const nextState = sliceActionSelectors.initialState;
  // console.log(nextState)
  const authSuccess = useSelector(authActionSelectors.selectSuccess);
  
  // console.log(authSuccess)
  // const [visibleLeft, setVisibleLeft] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const {theme, themeLoaded, getFonts} = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  // console.log(selectedTheme)
  
  // const [showDialog, setShowDialog] = useState(false);
  const [newTheme] = useState();

  
  

  
  function ThemeSlider(){
    if ( visibleRight === false ) {
        setVisibleRight(true)
    } else {
        setVisibleRight(false)
    }
  }
  

  useEffect(() => {
    WebFont.load({
      google: {
        families: getFonts()
      }
    });
  }, []);

  useEffect(() => {
    // if ( authSuccess === false ) {
    //   alert('loggin out');
    //   handleLogout();
    // } 
  }, []);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme, themeLoaded]);
  

 
  
  

  
  

  return (
    
    <>
    {
      
      themeLoaded && <ThemeProvider theme={ selectedTheme }>
        <GlobalStyles/>
        <Container>
          
          {/* <Menubar model={getMenuItems()} start={start} end={end} className="top-bar-bg" /> */}
          {/* <AppBar data-testid='NavBarHead'/> */}
          <MsalProvider instance={msalInstance}>
            {React.Children.only(children)}
          </MsalProvider> 
          
          <div>
            
            
            {/* <Menubar model={items} start={start} end={end} className="top-bar-bg" /> */}
            {/* <div>
              <button onClick={() => ThemeSlider()} className="p-button p-component p-link layout-config-button p-button-icon-only"><span className="p-button-icon p-c pi pi-cog theme-text-content"></span><span className="p-button-label p-c">&nbsp;</span></button>
            </div>
            <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight( false )} className="theme-switcher-sidebar">
                <h4 className="sidebar-header">Theme Colours</h4>
                <ThemeSelector setter={ setSelectedTheme } onClick={(e) => setVisibleRight( false)} newTheme={ newTheme }/>
            </Sidebar> */}

            {/* <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false )}>
                <h3 className='sidebar-header'>Create Dashboard</h3>
                <p className="panel-title">Basket</p>
            </Sidebar> */}
            
          </div>
          
          
          
        </Container>
      </ThemeProvider>
    }
    </>
    
  );

}
export default App;
