import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionSelectors from './slice';
import { load } from 'features/wyn/slice';

const useTenant = () => {
    const dispatch = useDispatch();

    const loading = useSelector(actionSelectors.selectLoading);
    const error = useSelector(actionSelectors.selectError);
    const success = useSelector(actionSelectors.selectSuccess);
    const list = useSelector(actionSelectors.selectTenants);
    const selected = useSelector(actionSelectors.selectTenantSelected);
    const dialog = useSelector(actionSelectors.selectDialog);

    React.useEffect(() => {
        if (success && list.length === 1) {
            dispatch(actionSelectors.set(list[0].displayName));
            dispatch(load());
        }
    }, [dispatch, success, list]);

    const handleTenant = React.useCallback((value) => {
        dispatch(actionSelectors.set(value));
        dispatch(load());
    }, [dispatch]);
    
    React.useEffect(() => {
        dispatch(actionSelectors.reset());
        dispatch(actionSelectors.load());
    }, [dispatch]);

    React.useEffect(() => {
        return () => dispatch(actionSelectors.init());
    }, [dispatch]);
    
    return {
        dialog,
        selected,
        list,
        error,
        loading,
        success,
        handleTenant
    };
};

export default useTenant;
