/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { Card } from 'primereact/card';
import deviceStatus from './service/device_status.json';
import WidgetSearch from './widget_search';
import { setToLS, getFromLS } from '../../../features/app/utils/storage';
import Parser from 'html-react-parser';
import { useGlobal } from 'reactn';


/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */

const deviceStatuses = deviceStatus.value;

const devicesStatus = deviceStatuses.reduce((catsSoFar, { AGENT_CURRENT_STATUS, DEVICE_HEALTH_SCORE }) => {
  if (!catsSoFar[AGENT_CURRENT_STATUS]) catsSoFar[AGENT_CURRENT_STATUS] = [];
  catsSoFar[AGENT_CURRENT_STATUS].push(DEVICE_HEALTH_SCORE);
  return catsSoFar;
}, {});

const AverageDisconnectedHealth = () => {
  for (var i = 0; i < devicesStatus.Disconnected.length; i++) {
    // total += grades[i];
  }
}
AverageDisconnectedHealth()

const renderButton = (data, idx) => {
  let theMarkup;
  
  if(data.includes("line") === true) {
      theMarkup = "<i className='pi pi-chart-line vis-option-icon' chart-type='Line chart' basket-item="+idx+"></i>"
  //   return (
  //       <i className='pi pi-chart-line vis-option-icon'>
  //       </i>
  ///  / );
  } 
  if(data.includes("bar") === true) {
      theMarkup += "<i className='pi pi-chart-bar vis-option-icon' chart-type='Bar chart' basket-item="+idx+"></i>"
      // return (
      //     <i className='pi pi-chart-bar vis-option-icon'>
      //     </i>
      // );
    } 
    
    return Parser(theMarkup)
}

const RenderChartChoice = (idx) => {
  
  const [ basketItems, setBasketItems ] = useGlobal('basketItems');
  const currentBasket = basketItems
  const newArray = currentBasket
  return newArray[idx].visualisation
  
  
  // console.log(data.includes("line", 0));
  // let theMarkup;
  
  // if(data.includes("line") === true) {
  //     theMarkup = "<i className='pi pi-chart-line vis-option-icon' chart-type='Line chart' basket-item="+idx+"></i>"
  // //   return (
  // //       <i className='pi pi-chart-line vis-option-icon'>
  // //       </i>
  // //   );
  // } 
  // if(data.includes("bar") === true) {
  //     theMarkup += "<i className='pi pi-chart-bar vis-option-icon' chart-type='Bar chart' basket-item="+idx+"></i>"
  //     // return (
  //     //     <i className='pi pi-chart-bar vis-option-icon'>
  //     //     </i>
  //     // );
  //   } 
  //   console.log(theMarkup)
}



const WidgetLibrary = () => {
  const [ basketItems, setBasketItems ] = useGlobal('basketItems');

  const setVisual = (data) => {
    
    const theBasketId = data.target.getAttribute("basket-item")
    const theChartType = data.target.getAttribute("chart-type")
    const currentBasket = basketItems;
    // console.log(currentBasket)
  
    const newArray = currentBasket
    newArray[theBasketId].visualisation = theChartType
    //setToLS('basket',newArray)
    setBasketItems(newArray)
    //RenderChartChoice(theBasketId)
    // setBasketItems(newArray)
  
  
  }

  // useEffect(() => {
  //   console.log(basketItems)
  //   // const currentBasket = basketItems
  //   // setBasketItems(currentBasket)
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

 
  const basketData = basketItems;
  console.log(basketData)
  
  
  return(
    <div className="p-p-5">
      <WidgetSearch />
      <div className="p-grid p-mt-3">
      {basketData.map((data, idx) => (
          <div className="p-col-6" key={data.key}>
            <Card className='p-p-3 basket'>
              <div className="p-grid p-align-center">
                  <div className="p-col">
                      <p className='basket-item-header'>{data.dataName}</p>
                      <p className='basket-item-visualisation'>Assigned Visualisation Option: <strong>{RenderChartChoice(idx)}</strong></p>
                      {/* <div className='p-pl-3 p-pr-3'>
                        <Message severity="error" text="Message Content" className='alert'/>
                      </div> */}
                      <p className='basket-item-available-visualisations p-mb-3'>Available Visualisations</p>
                      <div className='p-pl-4 p-pb-4 p-pr-4 do-bottom'>
                      <div onClick={setVisual}>
                        {renderButton(data.chart_options, idx)}
                      </div>
                      </div>
                  </div>
              </div>
            </Card>
          </div>
          

      ))}
      </div>
    </div>
  )
}

export default WidgetLibrary;