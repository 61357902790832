import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";

const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useIsAuthenticated();
    return (
        <Route {...rest} render={props => (
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}
                />
            )
        )}/>
    );
};
export default PrivateRoute;
