import React, { useState } from 'react';
import { Button } from 'primereact/button';
import LoadingPage from 'components/ui/loading-page';
import useAuth from 'features/auth/use';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import videoData from '../../assets/data/videoData.json';
import LoginQuotes from 'components/ticker/tips/login-quotes';

const Login = () => {
    const onHide = () => {
        showLoginDialog(false);
    }

    const videos = videoData;
    const randomNumber = Math.floor(Math.random() * videos.length);
    const currentVideo = videos[randomNumber];

    const [showLoginStatus, showLoginDialog] = useState(true);
    const { handleLogin, loading } = useAuth();

    return (
        <div className='login-page'>
            <div className='mobile-swirl-logo static'>
                <img alt="Storm" src="./storm-grey-storm-logo-noswirl.png" width="200px" />
            </div>
            <div className='mobile-swirl-logo animate'>
                <img alt="Storm" src="./swirl.png" className="logo-swirl rotating" />
            </div>
            
            <div>
            <div>
                <video autoPlay={true} muted loop id="myVideo">
                    <source src={"./loginvideos/" + currentVideo} type="video/mp4"></source>
                    Your browser does not support HTML5 video.
                </video>
                {loading && <LoadingPage data-testid='LoadingComponent' />}
                <Dialog maskClassName="login-dialog" showHeader={false} visible={showLoginStatus} modal className="login-dialog" onHide={() => onHide()}
                    draggable={false} resizable={false} dismissableMask={false} closable={false} closeOnEscape={false}>

                    <div className="container-fluid p-0 m-0">

                        <div className='row'>
                            <div className='col-4 col-sm-4 col-mg-2 col-lg-2 login-dialog-left'>
                                <i className="pi pi-lock pl-4"></i>
                            </div>
                            <div className='col-8 col-sm-8 col-mg-10 col-lg-10 login-dialog-right text-right pr-5 p-4' onClick={() => handleLogin()}>
                                {/* <div className='hide-mobile'>
                                    <img alt="Storm" src="./storm-grey-storm-logo-noswirl.png" width="200px" />
                                    <img alt="Storm" src="./swirl.png" className="logo-swirl rotating" />
                                </div> */}
                                <div className='mobile-cta'>
                                <span className='login-msg hide-mobile'>
                                    {/* Digital Collaboration Experience */}
                                    <LoginQuotes />
                                </span>
                                <span className='login-btn'>Login</span>
                                <i className="pi pi-chevron-right"></i>
                                </div>
                                
                                {/* <Button
                                    data-testid="login-button"
                                    gap="small"
                                    type="button"
                                    align="center"
                                    className="p-button-success mt-3"
                                    icon="pi pi-lock-open"
                                    label="Begin Login"
                                    onClick={() => handleLogin()}
                                    autoFocus
                                /> */}
                                {/* <div className='hide-mobile mt-4'>
                                    <p><strong>Note:</strong> Once logged in, if you have more than one 'Tenant', you will be asked to choose which to administer</p>
                                    <p>Within the portal you can at anytime switch between 'Tenants'.</p>
                                    <p className="teneo-flag"><small>A Teneo product v.2.0</small></p>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>


                    {/* <div className="p-grid">
                    <div className="p-col login-dialog-left">
                    <i className="pi pi-lock"></i>
                    </div>
                    <div className="p-col-8 login-dialog-right">

                        <div className="pad-70">
                            <img alt="Storm" src="./storm-grey-storm-logo-noswirl.png" width="200px"/>
                            <img alt="Storm" src="./swirl.png" className="logo-swirl rotating"/>
                            <p data-testid="WelcomeParagraph" className="welcome-paragraph bold">To begin please click the login button below to login via Microsoft.</p>
                            <Button 
                                data-testid="login-button"
                                gap="small"
                                type="button" 
                                align="center" 
                                className="p-button-success" 
                                icon="pi pi-lock-open"
                                label="Begin Login"
                                onClick={() => handleLogin()}
                                autoFocus
                            />
                            <p><strong>Note:</strong> Once logged in, if you have more than one 'Tenant', you will be asked to choose which to administer</p>
                            <p>Within the portal you can at anytime switch between 'Tenants'.</p>
                            <p className="teneo-flag"><small>A Teneo product v.2.0</small></p>
                        </div>
                        
                    </div>
                </div> */}



                </Dialog>
            </div>
            </div>
            <p data-testid="WelcomeParagraph" className="welcome-paragraph bold">Please click the login button above to continue</p>
        </div>
    );
};

export default Login;
