import useAuth from 'features/auth/use';

const WelcomeName = () => {
    const { data } = useAuth();
    const name = data?.account?.name;
    return <div data-testid="welcome-name-element">
        {name ? <span size="medium" color='plain'>{name}</span> : null}
        </div>
};

export default WelcomeName;
