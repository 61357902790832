/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

import { TenantLookupService } from '../components/service/tenantLookup';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}



const chartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

const IncidentsLineChart = () => {

    const tenantService = new TenantLookupService();
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalOpenIncidents, setGlobalOpenIncidents ] = useGlobal('openIncidents');
    const [ globalIncidentsAppList, setGlobalIncidentsAppList ] = useGlobal('incidentAppList');
    const [ globalCurrTenant, setGlobalCurrTenant ] = useGlobal('currentTenant');
    const [ globalClosedIncidents, setGlobalClosedIncidents ] = useGlobal('closedIncidents');
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const [selectedCity1, setSelectedCity1] = useState(null);

    useEffect(() => {
        getIncidents("All Applications")
      }, [globalTenantSwitched]);

    let cities = [
        { name: 'New York'},
        { name: 'Rome'}
    ];

    let chartData = {
        labels: [''],
        datasets: [
            {
                label: 'Open',
                backgroundColor: '#FFA726',
                data: [0]
            },
            {
                label: 'Closed',
                backgroundColor: '#71c223',
                data: [100]
            }
        ]
    };

    

    const onCityChange = (e) => {
        // console.log(e.value)
        setSelectedCity1(e.value);
        getIncidents(e.value)
    }
    let theCurrTenant;
    let openCount = [];
    let closedCount = [];
    let incicentsApps = [
        "All Applications"
    ];
    const createApplicationList = (data) => {
        var tmpArr = []
        console.log(data)
        const unique = [...new Set(data.map(item => item["Activity Name"]))];
        console.log(unique)
        console.log(incicentsApps)
     
        tmpArr = [...unique, ...incicentsApps]
        console.log(tmpArr)
        setGlobalIncidentsAppList(tmpArr)
    }
    useEffect(() => {
        if ( getFromLS('canRunApi') === true ) {
            // console.log(tenantService.translate())
            
            //Do open incidents
            axios.get(globalDbENv + ':7447/'+ globalDbName + '/incidents', config)
            .then((res)=>{
                let tmpObj = {};
                createApplicationList(res.data)
                theCurrTenant = tenantService.translate();
                // console.log(res.data)
                for (let item of res.data) {
                    if ( item.account_id === theCurrTenant ) {
                        if ( item.Status === 'Open' ) {
                            // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                            openCount.push(item);
                        }
                        if ( item.Status === 'Closed' ) {
                            // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                            closedCount.push(item);
                        }
                        // console.log(incicentsApps)
                        
                    }
                }
                chartData.datasets[0].data[0] = openCount.length
                chartData.datasets[1].data[1] = closedCount.length
                setGlobalOpenIncidents(chartData);
                // console.log(chartData.datasets[0].data[0])
                // console.log(globalOpenIncidents)
                
            }).catch((err)=>{
                console.log(err);
            })
            chartData.datasets[0].data[0] = openCount.length
            chartData.datasets[1].data[1] = closedCount.length
            setGlobalOpenIncidents(chartData);
        }
        
    }, [globalCurrTenant]);

    const getIncidents = (appName) => {
        
        theCurrTenant = tenantService.translate();
        
        axios.get(globalDbENv + ':7447/'+ globalDbName + '/incidents', config)
            .then((res)=>{
                let tmpObj = {};
                createApplicationList(res.data)
                // console.log(res.data)
                for (let item of res.data) {
                    if ( item.account_id === theCurrTenant ) {
                        
                        if ( item["Activity Name"] === appName ) {
                            // console.log(item)
                            if ( item.Status === 'Open' ) {
                                // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                                openCount.push(item);
                            }
                            if ( item.Status === 'Closed' ) {
                                // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                                closedCount.push(item);
                            }
                            // console.log(openCount)
                            // console.log(incicentsApps)
                            // setGlobalIncidentsAppList(incicentsApps)
                            // cities = incicentsApps;
                            // setGlobalOpenIncidents(item.openIncidents);
                            
                        }
                        if ( appName === "All Applications" ) {
                            // console.log(item)
                            if ( item.Status === 'Open' ) {
                                // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                                openCount.push(item);
                            }
                            if ( item.Status === 'Closed' ) {
                                
                                // incicentsApps.indexOf(item["Activity Name"]) === -1 ? incicentsApps.push(item["Activity Name"]) : console.log("This item already exists");
                                closedCount.push(item);
                            }
                            // console.log(openCount)
                            // setGlobalIncidentsAppList(incicentsApps)
                            // cities = incicentsApps;
                            // setGlobalOpenIncidents(item.openIncidents);
                            // chartData.datasets[0].data[0] = openCount.length
                            // chartData.datasets[1].data[0] = closedCount.length
                            // console.log(chartData)
                            // setGlobalOpenIncidents(chartData);
                        }
                        
                    }
                   
                    // if ( item["Activity Name"] === "All Applications" ) {
                    //     if ( item.Status === 'Open' ) {
                    //         openCount.push(item);
                    //     }
                    //     if ( item.Status === 'Closed' ) {
                    //         closedCount.push(item);
                    //     }
                    //     chartData.datasets[0].data[0] = openCount.length
                    //     chartData.datasets[0].data[1] = closedCount.length
                    //     setGlobalOpenIncidents(chartData);
                    // }
            
                }
                chartData.datasets[0].data[0] = openCount.length
                chartData.datasets[1].data[0] = closedCount.length
                setGlobalOpenIncidents(chartData);
                setGlobalClosedIncidents(chartData);

               
                // alert(uxiValue)
                //const incidents = res.data
                // console.log(chartData.datasets[0].data[0])
                // console.log(globalOpenIncidents)
                
                // for (let item of incidents) {
                //     theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                // }
                // console.log(deviceStabilityIndex)
            }).catch((err)=>{
                console.log(err);
            })
    }
    
    
    
  return (
   
            

            <div className="card welcome-widget chartheight">
                
                            <h5>Incidents</h5>
                            
                        
                    
                        <Chart type="bar" data={globalOpenIncidents} options={chartOptions} />
                        <Dropdown className="width-100-percent label-no-padding-no-margin mt-3" value={selectedCity1} options={globalIncidentsAppList} onChange={onCityChange} placeholder="Select an application" />
                    
                </div>
                
                
            
        
  );
};

export default IncidentsLineChart;

