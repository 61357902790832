/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';
import { TenantLookupService } from '../components/service/tenantLookup';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';

const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}


const comparisonLabels = { "labels": [] };
let comparisonOnloadTime = [];
let pageViews = [];

const webAppPerCountries = [
    { "name": "OnLoad Stats", "code": "onload" },
    { "name": "Page Views", "code": "pageviews" }
];

const basicAppLoadTimeOptions = {
    maintainAspectRatio: false,
    aspectRatio: .6,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

const PagePerformanceComparison = (mood) => {

    const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
    const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
    const [globalperformanceXAxis, setGlobalperformanceXAxis] = useGlobal('performanceComparisonXAxis');
    const [globalperformancePageViews, setGlobalperformancePageViews] = useGlobal('performanceComparisonPageViews');
    const [globalperformanceDatasets, setGlobalperformanceDatasets] = useGlobal('performanceComparisonDatasets');
    const [globalperformanceDefault, setGlobalperformanceDefault] = useGlobal('performanceComparisonDefault');
    const [globalCurrTenant, setGlobalCurrTenant] = useGlobal('currentTenant');
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const [globalperformanceChartdata, setGlobalperformanceChartdata] = useGlobal('performanceComparisonChartdata');
    const [selectedCity1, setSelectedCity1] = useGlobal('performanceComparisonDefault');

    const tenantService = new TenantLookupService();
    const theCurrTenant = tenantService.translate();

    let [comparisonObject] = useState([
        { "labels": [] },
        { "datasets": [] },
    ]);

    useEffect(() => {
        getPerfCompData(globalperformanceDefault)
    }, [globalTenantSwitched]);

    const chartData = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: '',
                data: [65, 59, 80, 81, 56],
                backgroundColor: '#ffa627',
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            }
        ]
    };

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
        getPerfCompData(e.value)
    }

    const getPerfCompData = (type) => {
        comparisonOnloadTime = [];
        pageViews = [];
        comparisonObject = [];
        console.log(globalperformanceChartdata)
        if (getFromLS('canRunApi') === true) {
            axios.get(globalDbENv + ':7443/' + globalDbName + '/Demo-BTT-table-for-page-performance-comparison-table', config)
                .then((res) => {
                    var tmpLabels = []
                    const comparison = res.data

                    for (let item of comparison) {
                        if (item["account_id"] === theCurrTenant) {
                            tmpLabels.push(item.Date)
                            comparisonOnloadTime.push(parseFloat(item["Onload (s)"]).toFixed(2))
                            pageViews.push(item["Page Views"])
                        }
                    }

                    comparisonObject.labels = tmpLabels;
                    comparisonObject.datasets = chartData.datasets;

                    if (type.name === 'Page Views') {
                        comparisonObject.datasets[0].data = pageViews;
                        comparisonObject.datasets[0].label = "Page Views Timings (Seconds)";
                    } else {
                        comparisonObject.datasets[0].data = comparisonOnloadTime;
                        comparisonObject.datasets[0].label = "Onload Timings (Seconds)";
                    }

                    setGlobalperformanceChartdata(comparisonObject);

                }).catch((err) => {
                    console.log(err);
                })
        }
    }

    return (
        <div className='welcome-widget'>
            <div className='container-fluid'>
                <div className='row custom-widget-titles'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 text-left'>
                        <span className='mr-3 title'>Avg web application load time</span>
                    </div>
                </div>
                <div className='row custom-widget-titles chartheight'>

                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 align-left'>
                        {selectedCity1.name === 'OnLoad Stats' &&
                            <Chart type="line" data={globalperformanceChartdata} options={basicAppLoadTimeOptions} />
                        }
                        {selectedCity1.name === 'Page Views' &&
                            <Chart type="bar" data={globalperformanceChartdata} options={basicAppLoadTimeOptions} />
                        }
                        {/* <Chart type="line" data={globalperformanceChartdata} options={basicOptions} /> */}
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                        <Dropdown className="width-100-percent" value={selectedCity1} options={webAppPerCountries} onChange={onCityChange} optionLabel="name" placeholder="Select country" />
                    </div>

                </div>
            </div>



        </div>
    );
};

export default PagePerformanceComparison;

