'use strict';

import React, { Component, createClass, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'moment-timezone';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

let config = {
  headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
  }
}


let collectionFields = [];
const Start = () => {
  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
  const [ dynDashWidgetName, setDynDashWidgetName ] = useGlobal('dyn_dash_widget_name');
  
  
  


  


  const setWidgetName = (e) => {
    console.log(e);
    setDynDashWidgetName(e)
    // setDynDashWidgetCollectionSelected(e.value);
    // getFieldsFromCollection(e.value.collection_name);
    // setDynDashWidgetCollectionSelectedFieldsOptionSelected([]);
  }

  
  return (
    <div className="step start">
        <div className="">
          <form id="Form" className="form-horizontal">
            <div className="form-group">
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                    <span className="p-float-label">
                      <InputText className="p-inputtext-lg block" id="widget_name" value={dynDashWidgetName}  onChange={(e) => setWidgetName(e.target.value)} />
                      <label htmlFor="widget_name">Widget Name</label>
                    </span>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                  
                  </div>
                </div>
              </div>
              
            </div>
          </form>
        </div>
      </div>
  );
}

export default Start;