/* eslint-disable no-unused-vars */
import React, { Component, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';


const itemRows = [];
const itemLoadTimeAvg = [];
const itemLoadTimeMin = [];
const itemLoadTimeMax = [];
const webAppPerfIndex = [];
const standard = [];

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "withCredentials": false,
  }
}

const WebAppPerformance = () => {

  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ globalCanRunApi, setGlobalCanRunApi ] = useGlobal('canRunApi');

  const OnlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  } 

  

  let webApplicationPerformance = {
    labels: itemRows.filter(OnlyUnique),
    datasets: [
      {
        label: 'Average',
        backgroundColor: '#5CA39A',
        data: [2932, 1023, 1023, 3730, 3730, 1829, 1829]
      },
      {
        label: 'Min',
        backgroundColor: 'rgb(145,145,145)',
        data: [2849, 1609, 3421, 4824, 1868, 4217, 1318]
      },
      {
        label: 'Max',
        backgroundColor: '#474747',
        data: [7692, 2793, 3421, 6438, 1868, 10473, 1318]
      }
    ]
  };

  let webApplicationPerformanceOptions = {
    maintainAspectRatio: false,
    aspectRatio: .6,
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  useEffect(() => {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    
    if (globalCanRunApi === true) {
      axios.get(globalDbENv + '/web_application_performance', config)
        .then((res) => {
          const webAppPerf = res.data

          for (let item of webAppPerf) {
            itemRows.push(item.APPLICATION_NAME);
            itemLoadTimeAvg.push(item["Load Time Average"]);
            itemLoadTimeMin.push(item["Load Time Min"]);
            itemLoadTimeMax.push(item["Load Time Max"]);
            webAppPerfIndex.push(item["Avg Stability Index"]);
            standard.push(7.4);

          }
          // console.log(itemRows.filter(onlyUnique))
          webApplicationPerformance.labels = itemRows.filter(onlyUnique);
        }).catch((err) => {
          console.log(err);
        })
    }
  }, []);
  return (
    <div>
      <div className="card">
        <Chart type="bar" width="100%" height="200px" data={webApplicationPerformance} options={webApplicationPerformanceOptions} />
      </div>
    </div>
  );
};

export default WebAppPerformance;
