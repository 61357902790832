import styled from 'styled-components';

const PageCenterStyle = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default PageCenterStyle;
