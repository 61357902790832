/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';
import WelcomeName from 'components/header/welcome-name';
import TextField from '@mui/material/TextField';



function getFromLS(key) {
    let ls = {};
    global.localStorage.removeItem("rgl-8");
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {

    if (global.localStorage) {
        global.localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const ShowAllDevices = () => {
    saveToLS("tan", "value")
    // const [panel, setPanel] = useState('AllDevices');
    // if ( count === 'SetOne' ) {
    //     active = <SetOne />
    // }
    // if ( count === 'SetTwo' ) {
    //     active = <SetTwo />
    // }
    // if ( count === 'SetThree' ) {
    //     active = <SetThree />
    // }
    console.log('All Reporting')
}

class EditProfile extends Component {


    constructor(props) {
        super(props);

        this.state = {
            // layouts: JSON.parse(JSON.stringify(originalLayouts))
        };
    }

    static get defaultProps() {

        return {
            // className: "layout",
            // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            // rowHeight: 30
        };
    }

    componentDidMount() {
        console.log('getting');
    }

    render() {
        return (
            <div className='container-fluid p-0'>
                <div className='container-fluid p-0'>
                    <div className='row panel-content loose title-container m-0'>
                        <div className='col-12 col-sm-12 col-md-7 col-lg-7'>
                            <div className="panel-title p-pt-2 p-pl-2">
                                <p stylename="margin-top: 0;" className="yello-text">Edit your profile
                                    {/* {welcomeDate()} */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row panel-content loose  mr-0 ml-0 bg-white'>
                    <div className='col-12 col-sm-12 col-md-5 col-md-5'>
                        <p className="biguns-header fw300 p-mt-0 p-mb-2 mt-4">All about you.</p>
                        <p className="biguns-header fw200 up mt-3">Edit your profile.</p>
                    </div>
                    <div className='col-12 col-sm-12 col-md-7 col-md-7'>
                        <TextField
                            id="standard-helperText"
                            name="user_name"
                            helperText="Your name"
                            variant="standard"
                        />
                        <WelcomeName />
                    </div>
                </div>
                </div>
                
                {/* <div className="p-grid p-50">
                    <div className="p-col-4">
                        <p className="biguns-header fw300 p-mt-0 p-mb-2">All about you.</p>
                        <p className="biguns-header fw200 up p-mt-0">Edit your profile.</p>
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-7 p-pr-5">
                        <TextField
                            id="standard-helperText"
                            name="user_name"
                            helperText="Your name"
                            variant="standard"
                        />
                        <WelcomeName />
                    </div>
                </div> */}
            </div>

        )
    }
}

export default EditProfile;