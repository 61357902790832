/* eslint-disable no-unused-vars */
import React, { Component, useState, useEffect } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Chart } from 'primereact/chart';
import Incidents from './charts/incidents';

/* New Widgets */
import StackedChart from '../widgets/stacked_chart';
import PieChart from '../widgets/pie_chart';
import DoughnutChart from '../widgets/doughnut_chart';
import LineChart from '../widgets/line_chart';
import ComboChart from '../widgets/combo_chart';

import PagePerformanceComparison from '../widgets/page_performance_comparison';
import DeviceStabilityDoughnutChart from '../widgets/device_stability_doughnut_chart';
import DeviceStabilityChart from '../widgets/device_stability_chart';
import IncidentsLineChart from '../widgets/incidents_line_chart';
import WebappPerformanceStackedChart from '../widgets/webapp_performance_stacked_chart';
import ApplicationUxiSpeedometer from '../widgets/application_uxi_speedometer';
import BusinessWorkflowTrafficLight from '../widgets/business_workflow_traffic_lights';

//// import Button from '@mui/material/Button';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { setToLS } from 'features/app/utils/storage';


import MapChartSVG from './map/mapChartSVG';
import DeviceStability from './charts/device-stability';
import WebAppPerformance from './charts/web-app-performance';
import WebPerformance from './charts/web-performance';
import EnterpriseApps from './charts/enterprise_apps';
import BusinessActivitiesSummary from './charts/business-activities-summary';
import { useHistory } from 'react-router-dom';
import useDashboards from 'features/dashboards/use';
import TipsOfTheDay from '../../../components/ticker/tips';

import { TenantLookupService } from '../components/service/tenantLookup';



import moment from 'moment';
import axios from 'axios';

import { getFromLS } from 'features/app/utils/storage';

import { useGlobal } from 'reactn';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { ApiGet } from 'apicalls/api_get';














const Welcome2 = (moody) => {


  const [subServices, setSubServices] = useState({});
  const [avatar, setAvatar] = useGlobal('avatar');
  const [visibleLeft, setVisibleLeft] = useGlobal('visibleLeft');
  const [globalCurrentTenant, setGlobalCurrentTenant] = useGlobal('currentTenant');
  const [globalSubscribedServices, setGlobalSubscribedServices] = useGlobal('subscribedServices');
  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const [globalWebAppPerformanceDataAvailable, setGlobalWebAppPerformanceDataAvailable] = useGlobal('webAppPerfDataAvailable');
  const [globalWebAppPerformanceChartData, setGlobalWebAppPerformanceChartData] = useGlobal('webAppPerformanceChartData');
  const [globalWebAppPerformanceChartDataUsers, setGlobalWebAppPerformanceChartDataUsers] = useGlobal('webAppPerformanceChartDataUsers');
  const [globalWebPerformanceAppList, setGlobalWebPerformanceAppList] = useGlobal('WebPerformanceAppList');
  const [ globalCurrTenant, setGlobalCurrTenant ] = useGlobal('currentTenant');

  const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
  const [globalCanRunApi, setGlobalCanRunApi] = useGlobal('canRunApi');
  const tenantService = new TenantLookupService();

  const theCurrTenant = tenantService.translate();

  setToLS('basket', []);

  let config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
    }
  }

  const theIncidents = [];
  const webAppPerfObject = [
    { "labels": [] },
    { "datasets": [] },
  ];
  const webAppPerfObjectUsers = [
    { "labels": [] },
    { "datasets": [] },
  ];
  const webAppPerfLabels = { "labels": [] };
  const webAppPerfDataset = { "datasets": [] };

  const webAppPerfLabelsUsers = { "labels": [] };
  const webAppPerfDatasetUsers = { "datasets": [] };

  const webAppPerfUserCount = [];
  const webAppPerfLoadTimeAvg = [];

  const webAppPerfNetBackend = [];
  const webAppPerfNetClient = [];

  const webAppPerfLoadTimeMax = [];
  const webAppPerCountries = [
    { "name": "All", "code": "all" }
  ];
  const webAppViewType = [
    { "name": "Loadtime", "code": "loadtime" },
    { "name": "Network", "code": "network" },
  ];
  let incidents = [];
  let [cities] = useState([
    { name: 'All devices', code: 'all' },
    { name: 'Desktop', code: 'desktop' },
    { name: 'Mobile', code: 'mobile' },
    { name: 'Vdi', code: 'vdi' }
  ]);
  let incicentsApps = [
    "All Applications"
  ];
  const chartData = {
    labels: ["App 1", "App 2", "App 3", "App 4"],
    datasets: [
      {
        type: "line",
        label: "Loadtime Avg",
        borderColor: "#4500ff",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        data: [20, 65, 52, 48, 56, 76, 42]
      },
      {
        type: "line",
        label: "Loadtime Max",
        borderColor: "#ff0000",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        data: [50, 25, 12, 48, 56, 76, 42]
      }
    ]
  };
  let isItEmpty = false;
  const createApplicationList = (data) => {
    var tmpArr = []
    const unique = [...new Set(data.map(item => item["Application_Name"]))];
    tmpArr = [...unique, ...incicentsApps]
    setGlobalWebPerformanceAppList(tmpArr)

  }
  const [basicData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Users',
        backgroundColor: '#42A5F5',
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  });
  const getGetWebPerfData = () => {

    setGlobalWebAppPerformanceChartData([])
    setGlobalWebAppPerformanceChartDataUsers([])
    if (getFromLS('canRunApi') === true) {
      
      ApiGet(globalDbENv + ':7447/' + globalDbName + '/webApplicationPerformance', config)
        .then((res) => {
          var tmpLabels = []
          incidents = res.data
          // console.log(res.data)
          createApplicationList(res.data)
          for (let item of incidents) {
            if (item["account_id"] === parseInt(theCurrTenant)) {
              // theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
              theIncidents.push(item);
              tmpLabels.push(item.Timeframe)
              webAppPerfUserCount.push(item["User Count:"])
              webAppPerfLoadTimeAvg.push(parseFloat(item["Load Time Average (s)"]).toFixed(2))
              webAppPerfLoadTimeMax.push(parseFloat(item["Page load Network Time Avg (s)"]).toFixed(2))
              // if(webAppPerCountries.some(person => person.name === item["Application_Name"])){
              //     console.log("Object found inside the array.");
              // } else{
              //     console.log("Object not found.");
              //     webAppPerCountries.push({ "name": item["Application_Name"], "code": item["Application_Name"] })
              // }
            }



          }


          webAppPerfLabels.labels = tmpLabels;
          webAppPerfDataset.datasets = chartData.datasets;

          webAppPerfObject.labels = tmpLabels;
          webAppPerfObject.datasets = chartData.datasets;


          webAppPerfLabelsUsers.labels = tmpLabels;
          webAppPerfDatasetUsers.datasets = basicData.datasets;

          webAppPerfObjectUsers.labels = tmpLabels;
          webAppPerfObjectUsers.datasets = basicData.datasets;

          // webAppPerfObject.datasets[2].data = webAppPerfUserCount;
          webAppPerfObject.datasets[1].data = webAppPerfLoadTimeAvg;
          webAppPerfObject.datasets[0].data = webAppPerfLoadTimeMax;

          webAppPerfObjectUsers.datasets[0].data = webAppPerfUserCount;
          setGlobalWebAppPerformanceChartData(webAppPerfObject)
          // console.log(webAppPerfObject)
          setGlobalWebAppPerformanceChartDataUsers(webAppPerfObjectUsers)
          [cities] = webAppPerCountries;
          //console.log(globalWebAppPerformanceChartData?.labels)

          if (webAppPerfObject?.labels.length > 0) {
            isItEmpty = 'true';
            setGlobalWebAppPerformanceDataAvailable(isItEmpty)
          } else {
            isItEmpty = 'false';
            setGlobalWebAppPerformanceDataAvailable(isItEmpty)
          }

          // console.log(globalWebAppPerformanceChartData.labels)
          // console.log(globalWebAppPerformanceDataAvailable)
        }).catch((err) => {
          console.log(err);
        })
    }
  }

  useEffect(() => {
    getGetWebPerfData();
  }, [globalCurrTenant])

  useEffect(() => {
    setGlobalCanRunApi(true)
  }, [globalCanRunApi])

  // useEffect(() => {
  //   axios(globalDbENv + ':7443/' + globalDbName + "/subscribed_services", config)
  //     .then(data => setGlobalSubscribedServices(data.data))
  //     .then(console.log(globalSubscribedServices))
  // }, [])




  const { loading, success, data, handleDash } = useDashboards();
  const history = useHistory();

  // const resetLayout = () => {
  //   this.setState({ layouts: {} });
  // }

  const HandleDash = (dash) => {
    console.log(dash)
    // removeFromLS('aternity')
    //setToLS('viewingDashboard', dash.item)
    history.push('/')
    handleDash({ id: dash.id, type: dash.type })
  }

  const welcomeDate = () => {
    return moment().format("MMMM Do YYYY");
  }



  return (
    <div className='welcome-header'>
      <div className='container-fluid p-0'>
        <div className='row panel-content loose title-container m-0'>
          <div className='col-12 col-sm-12 col-md-9 col-lg-9'>
            <div className="panel-title p-pt-2 p-pl-2">
              <p stylename="margin-top: 0;" className="yello-text">Overview.
                {/* {welcomeDate()} */}
              </p>
              <p className='small-text'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-3 col-lg-3 hide-mobile p-0 pt-3'>
            <TipsOfTheDay />
          </div>

        </div>
      </div>
      <div className='container-fluid bubble-enable main-dash-welcome-page'>
        <div className='row mt-4 welcome-page-widget-row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
            <BusinessWorkflowTrafficLight />
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3' id='speed-gauge'>
            <ApplicationUxiSpeedometer />
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
            <DeviceStabilityChart />
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
            <IncidentsLineChart />
          </div>

        </div>
        <div className='row mt-4'>
          {globalWebAppPerformanceDataAvailable === 'true' &&
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <WebappPerformanceStackedChart />
            </div>
          }

          {globalWebAppPerformanceDataAvailable === 'true' &&
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <PagePerformanceComparison />
            </div>
          }

          {globalWebAppPerformanceDataAvailable === 'false' &&
            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
              <PagePerformanceComparison />
            </div>
          }
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
            {/* <LineChart /> */}
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12 col-sm-12 col-md-2 col-lg-6'>
            {/* <BusinessWorkflowTrafficLight /> */}
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'>
            {/* <LineChart /> */}
          </div>
        </div>
        {/* <div className='row mt-4'>
          <div className='col-12 col-md-12 col-sm-12 col-lg-3'>
            <div className='bubble'>
              <div className="panel-title">
                Incidents <Badge value="2" className='maindash-badge'></Badge>
                <p className='panel-description'>Viewing incidents </p>
              </div>
              <Incidents />
            </div>
            <div className='bubble mt-4'>
              <div className="panel-title">
                Web Performance
                <p className='panel-description'>Viewing average load time (Seconds)</p>
              </div>
              <WebPerformance />
            </div>
            
          </div>
          <div className='col-12 col-md-12 col-sm-12 col-lg-9'>
            <div className='bubble'>
              <div className="panel-title p-mb-0">
                Device Stability
                <p className='panel-description'>Viewing global device stability against industry benchmark </p>
              </div>
              <DeviceStability />
            </div>
            <div className='bubble mt-4'>
              <div className="panel-title">
                Web App Performance
                <p className='panel-description'>Application load time (milliseconds)</p>
              </div>
              <WebAppPerformance />
            </div>
            
            <div className='row mt-4'>
              <div className='col-12 col-md-12 col-sm-12 col-lg-4'>
                <div className='bubble'>
                  <p className="panel-title">UXI (Load time)</p>
                  <WebAppPerformance />
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-md-12 col-sm-12 col-lg-12'>
                    <div className='bubble'>
                      <div className="panel-title">
                        Enterprise Apps
                        <p className='panel-description'>User experience rating</p>
                      </div>
                      <div className="card">
                        <EnterpriseApps />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12 col-sm-12 col-lg-5'>
                <div className='bubble'>
                  <div className="panel-title">
                    Business Activities Summary
                    <p className='panel-description'>Viewing business activity across applications </p>
                  </div>
                  <BusinessActivitiesSummary />
                </div>
                <div className='row mt-4'>
                  <div className='col-12 col-md-12 col-sm-12 col-lg-12'>
                    <div className='bubble'>
                      <div className="panel-title p-mb-0">
                        Global UXI
                        <p className='panel-description p-mb-0'>Viewing global user experience</p>
                      </div>
                      <MapChartSVG />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12 col-sm-12 col-lg-3'>
                <div className='bubble'>
                  <p className="panel-title">Zero Trust - <b>Threats</b></p>
                  <small>Last 24 hours</small>
                  <table width="100%">
                    <tbody>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Identified threats resolved" />Tip</td>
                      <td>635</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Firewall triggered by custom policies" />PM</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="Risk level categories identifed" />CAT</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td><Button type="button" className="badge-button" label="" icon="pi pi-info-circle" tooltip="DNS threats" />BIND</td>
                      <td>0</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
  
        </div> */}
      </div>
    </div>
  )

}

export default Welcome2;