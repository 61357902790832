import React, { useState, useEffect } from 'react';
import WelcomeName from 'components/header/welcome-name';
import { Button } from 'primereact/button';
import $ from 'jquery';
import useAuth from 'features/auth/use';
import { useTheme } from '../../features/app/theme/useTheme';
import { setToLS, getFromLS, removeFromLS } from '../../features/app/utils/storage';
import DropdownTenant from '../../features/tenant/TenantSelector';
import { useSelector } from 'react-redux';
import * as authActionSelectors from '../../features/auth/slice';
import { useHistory } from 'react-router-dom';
import useDashboards from '../../features/dashboards/use';
import { Card } from 'primereact/card';
import { Sidebar } from 'primereact/sidebar';
import { MegaMenu } from 'primereact/megamenu';
import { Badge } from 'primereact/badge';
import Basket from '../../pages/maindash/components/basket/basket';
import axios from 'axios';
import { Tree } from 'primereact/tree';
import { Dropdown } from 'primereact/dropdown';
import { useGlobal } from 'reactn';
import { PanelMenu } from 'primereact/panelmenu';
import { getAlertUtilityClass } from '@mui/material';
import { node } from 'prop-types';



let config = {
   headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
   }
}

// export function getRedirectPath(selectedThemeGo) {
//    return selectedThemeGo
// }
let newArr = [];



const AppBar = ({ childToParent }) => {

   const [apiData, setApiData] = useState(null);
   const [apiSubData, setSubApiData] = useState(null);
   




   const history = useHistory();

   const doHandleLogin = () => {
      setGlobalVisibleLeft(false);
      handleLogin();
   }
   useDashboards()
   const { data, handleDash } = useDashboards();
   const authSuccess = useSelector(authActionSelectors.selectSuccess);

   const [globalVisibleLeft, setGlobalVisibleLeft] = useGlobal('visibleLeft');
   const [globalVisibleBasket, setGlobalVisibleBasket] = useGlobal('visibleBasket');
   const [globalBasketCount, setGlobalBasketCount] = useGlobal('basketCount');
   const [globalDashboardCount, setGlobalDashboardCount] = useGlobal('dashboardCount');
   const [globalCurrentTenant, setGlobalCurrentTenant] = useGlobal('currentTenant');
   const [globalWynDashboards, setGlobalWynDashboards] = useGlobal('wynDashboards');
   const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
   const [customDashboards, setCustomDashboards] = useGlobal('customDashboards');
   const [customDashboardsSidebar, setCustomDashboardsSidebar] = useGlobal('customDashboardsSidebar');
   const [ globalSubscribedServices, setGlobalSubscribedServices ] = useGlobal('subscribedServices');
   const [ globalAllTenants, setGlobalAllTenants ] = useGlobal('allTenants');
   const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
   const { success} = useAuth();
   

   const [selectedDashboard, setSelectedDashboard] = useState(null);
   const [basketCount, setBasketCount] = useState(0);

   const { handleLogin, handleLogout } = useAuth();
   const { theme, themeLoaded, getFonts } = useTheme();

   console.log(globalAllTenants)

   const onAccountDropDownChange = (e) => {
      // console.log(e.value);
      childToParent(e.value)
      setGlobalVisibleLeft(false);

   }
   const onCityChange = (e) => {
      // console.log(e.value)
      removeFromLS('aternity')
      setToLS('viewingDashboard', e.value)
      setSelectedDashboard(e.value.label);
      history.push('/')
      setGlobalVisibleLeft(false)
      handleDash({ id: e.value.id, type: e.value.type })
      //HandleDash(e.value)
      //setSelectedDashboard(e.value);
      //console.log(selectedDashboard)
      // setToLS('currentTenant', e.value)
      //params.handleTenant(e.value.label)
   }

   useEffect(() => {
      axios(globalDbENv + ':7443/'+ globalDbName + "/subscribed_services", config)
         .then(data => setApiData(data.data))
      //   .then(console.log(apiData))
   }, [])

   const accountTemplate = (option, props) => {
      if (option) {
         return (

            <div>{option.label}</div>

         );
      }
      //let label = <b onClick={(event) => { childToParent( {type: 'direct', short: node.item.short } ); setGlobalVisibleLeft(false) }}>{node.item.label}</b>;



      return (
         <span>
            {props.placeholder}
         </span>

      )
   }

   const selectedCountryTemplate = (option, props) => {
      if (option) {
         return (

            <div>{option.title}</div>

         );
      }

      return (
         <span>
            {props.placeholder}
         </span>
      );
   }

   const countryOptionTemplate = (option) => {
      return (
         <div className="country-item">
            <div>{option.title}</div>
         </div>
      );
   }



   const nodes = [
      {
         key: "0",
         // label: <WelcomeName />,
         label: 'My Profile',
         short: 'editprofile',
         items: [
            { key: "0-0", label: 'Edit Profile (Comming soon)', icon: 'pi pi-fw pi-user', short: 'editprofile', type: 'direct' }
         ]
      },
      {
         key: "1",
         label: 'My Dashboards...',
         short: 'Welcome',
         items: [
            {
               key: "01",
               label: 'Tools',
               short: 'newdashboard',
               items: [
                  {
                     key: "01-0",
                     label: 'Create Dashboard',
                     short: 'newdashboard',
                     icon: 'pi pi-fw pi-plus',
                     command: (event) => { childToParent(event); }
                  },
                  // {
                  //    key: "01-1",
                  //    label: 'Data Library',
                  //    short: 'datalibrary',
                  //    icon: 'pi pi-fw pi-book',
                  //    command: (event) => { childToParent(event); }
                  // },
                  {
                     key: "01-2",
                     label: 'Widget Library',
                     short: 'widgetlibrary',
                     icon: 'pi pi-fw pi-book',
                     command: (event) => { childToParent(event); }
                  }
                  // {
                  //    key: "01-3",
                  //    label: 'Create Scenario',
                  //    short: 'createscenario',
                  //    icon: 'pi pi-fw pi-compass',
                  //    command: (event) => { childToParent(event); }
                  // },
                  // {
                  //    key: "01-4",
                  //    label: 'Set current dashboard as home',
                  //    short: 'createscenario',
                  //    icon: 'pi pi-fw pi-save',
                  //    command: (event) => { childToParent(event); }
                  // }
               ]
            },
            {
               key: "00",
               label: 'Main Dashboards',
               items: [
                  // { key: "00-0", label: 'Main', icon: 'pi pi-fw pi-th-large', short: 'Welcome', command: (event) => { childToParent(event); } },
                  { key: "00-1", label: 'Main Dash', icon: 'pi pi-fw pi-th-large', short: 'Welcome2', command: (event) => { childToParent(event); } },
                  { key: "00-1", label: 'Welcome Static', icon: 'pi pi-fw pi-th-large', short: 'WelcomeStatic', command: (event) => { childToParent(event); } }
               ]
            },
            
         ]
      },
      {
         key: "2",
         label: 'My Services',
         items: [
            {
               key: "2",
               label: 'Subscribed',
               items: []
            },
            {
               key: "3",
               label: 'UnSubscribed',
               items: []
            }
         ]
      },
      {
         key: "3",
         label: 'News',
         items: [
            { key: "3-0", label: 'Reports', icon: 'pi pi-fw pi-book', short: 'reports', command: (event) => { childToParent(event); } },
            { key: "3-1", label: 'Storm News', icon: 'pi pi-fw pi-book', short: 'news', command: (event) => { childToParent(event); } },
            { key: "3-2", label: 'Service Updates', icon: 'pi pi-fw pi-book', short: 'service-update', command: (event) => { childToParent(event); } }
         ]
      }
   ];

   //   console.log(nodes)


   const nodeTemplate = (node, options) => {
      let label;
      if (node.disabled === true) {
         label = <b>{node.label}</b>;
      } else {
         label = <b onClick={(event) => { childToParent({ type: 'direct', short: node.short }); setGlobalVisibleLeft(false) }}>{node.label}</b>;
      }




      return (
         <span className={options.className}>
            {label}
         </span>
      )
   }

   const subServiceTemplate = (node, options) => {
      let label;
      if (node.disabled === true) {
         label = <b>{node.label}</b>;
      } else {
         label = <b onClick={(event) => { childToParent({ type: 'direct', short: node.short }); setGlobalVisibleLeft(false) }}>{node.label}</b>;
      }

      // if ( node.intType === 'subRedirect') {
      //    alert('sub')
      //    label = <b onClick={(event) => { HandleDash( {type: node.type, id: node.id } ); setVisibleRight(false) }}>{node.label}</b>;   
      // } else {
      //    label = <b onClick={(event) => { childToParent( {type: 'direct', short: node.short } ); setVisibleRight(false) }}>{node.label}</b>;
      // } 
      // let label = <b onClick={(event) => { console.log(event);  }}>{node.label}</b>;
      // let label = <b onClick={(event) => { clickMe( event );  }}>{node.label}frfrf</b>;
      // let label = <b onClick={(event) => { childToParent( event ); setVisibleRight(false) }}>{node.label}</b>;
      // let label = <b onClick={clickMe.bind(null, node)}>{node.label}frfrf</b>;


      return (
         <span className={options.className}>
            {label}
         </span>
      )
   }

  

   let megaItems = [
      {
         label: <WelcomeName />,
         icon: 'pi pi-fw pi-user',
         items: [
            [
               {
                  label: 'Profile',
                  icon: 'pi pi-fw pi-user',
                  items: [
                     {
                        label: 'Edit Profile (Comming soon)',
                        short: 'editprofile',
                        type: 'direct',
                        icon: 'pi pi-fw pi-pencil'
                     }
                  ]
               }
            ]
         ]
      },
      // {
      //    label: 'Options',
      //    icon: 'pi pi-fw pi-cog',
      //    items: [
      //       [
      //          {
      //             label: 'Preferences',
      //             icon: 'pi pi-fw pi-cog',
      //             items: [
      //                {
      //                   label: 'Light',
      //                   icon: 'pi pi-fw pi-bookmark',
      //                   command: (event) => { DoSWitchtheme(event); }
      //                },
      //                {
      //                   label: 'Dark',
      //                   icon: 'pi pi-fw pi-video',
      //                   command: (event) => { DoSWitchtheme(event); }
      //                }
      //             ]
      //          },
      //       ]
      //    ]
      // },
      {
         label: 'My Dashboards',
         icon: 'pi pi-fw pi-cog',
         items: [
            [
               {
                  label: 'Tools',
                  icon: 'pi pi-fw pi-cog',
                  items: [
                     {
                        label: 'Create Dashboard',
                        short: 'newdashboard',
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { childToParent(event); }
                     },
                     // {
                     //    label: 'Data Library',
                     //    short: 'datalibrary',
                     //    icon: 'pi pi-fw pi-book',
                     //    command: (event) => { childToParent(event); }
                     // },
                     {
                        label: 'Widget Library',
                        short: 'widgetlibrary',
                        icon: 'pi pi-fw pi-book',
                        command: (event) => { childToParent(event); }
                     },
                     // {
                     //    label: 'Create Scenario',
                     //    short: 'createscenario',
                     //    icon: 'pi pi-fw pi-compass',
                     //    command: (event) => { childToParent(event); }
                     // },
                     // {
                     //    label: 'Set current dashboard as home',
                     //    short: 'createscenario',
                     //    icon: 'pi pi-fw pi-save',
                     //    command: (event) => { childToParent(event); }
                     // }
                  ]
               },
            ],
            [
               {
                  label: 'Dashboards',
                  icon: 'pi pi-fw pi-star-fill',
                  items: [
                     // {
                     //    label: 'Welcome',
                     //    short: 'Welcome',
                     //    icon: 'pi pi-fw pi-home',
                     //    command: (event) => { childToParent(event); }
                     // },
                     {
                        label: 'Main Dash',
                        short: 'Welcome2',
                        icon: 'pi pi-fw pi-home',
                        command: (event) => { childToParent(event); }
                     },
                     {
                        label: 'Welcome Static',
                        short: 'WelcomeStatic',
                        icon: 'pi pi-fw pi-home',
                        command: (event) => { childToParent(event); }
                     }
                     // {
                     //    label: 'Dynamic',
                     //    short: 'dynamic',
                     //    icon: 'pi pi-fw pi-home',
                     //    command: (event) => { childToParent(event); }
                     // },
                     // {
                     //    label: 'Overview',
                     //    short: 'Overview',
                     //    icon: 'pi pi-fw pi-eye',
                     //    command: (event) => { childToParent(event); },
                     //    items: [
                     //       {
                     //          label: 'Edit Dashboard',
                     //          icon: 'pi pi-fw pi-pencil'
                     //       },
                     //       {
                     //          label: 'Add to Toolbar',
                     //          icon: 'pi pi-fw pi-star-fill',
                     //          command: (event) => { childToParent(event); }
                     //       }
                     //    ]
                     // },
                     // {
                     //    label: 'Operations',
                     //    short: 'Operations',
                     //    icon: 'pi pi-fw pi-chart-bar',
                     //    command: (event) => { childToParent(event); },
                     //    items: [
                     //       {
                     //          label: 'Edit Dashboard',
                     //          icon: 'pi pi-fw pi-pencil'
                     //       },
                     //       {
                     //          label: 'Add to Toolbar',
                     //          icon: 'pi pi-fw pi-star-fill orange-color',
                     //          command: (event) => { childToParent(event); }
                     //       }
                     //    ]
                     // },
                     // {
                     //    label: 'Devices',
                     //    short: 'DeviceStatus',
                     //    icon: 'pi pi-fw pi-server',
                     //    command: (event) => { childToParent(event); },
                     //    items: [
                     //       {
                     //          label: 'Edit dashboard',
                     //          icon: 'pi pi-fw pi-pencil'
                     //       },
                     //       {
                     //          label: 'Add to Toolbar',
                     //          icon: 'pi pi-fw pi-star-fill',
                     //          command: (event) => { childToParent(event); }
                     //       }
                     //    ]
                     // }
                  ]
               }
            ],
            [
               {
                  label: 'Custom Dashboards',
                  icon: 'pi pi-fw pi-star-fill',
                  items: [
                  ]
               }
            ]
         ]
      },
      {
         label: 'My Services',
         icon: 'pi pi-fw pi-cog',
         items: [
            [
               {
                  label: 'Subscribed',
                  icon: 'pi pi-fw pi-cog',
                  items: [
                     // {
                     //    label: 'WFA',
                     //    short: 'wfa',
                     //    icon: 'pi pi-fw pi-bookmark',
                     //    command: (event) => { childToParent(event); }
                     // },
                     // {
                     //    label: 'SASE',
                     //    short: 'sase',
                     //    icon: 'pi pi-fw pi-bookmark',
                     //    command: (event) => { childToParent(event); }
                     // }
                  ]
               },
            ],
            [
               {
                  label: 'Unsubscribed',
                  icon: 'pi pi-fw pi-cog',
                  items: [
                     // {
                     //    label: 'Option 3',
                     //    short: 'widgetlibrary',
                     //    icon: 'pi pi-fw pi-book',
                     //    command: (event) => { childToParent(event); }
                     // },
                     // {
                     //    label: 'Option 4',
                     //    short: 'createscenario',
                     //    icon: 'pi pi-fw pi-compass',
                     //    command: (event) => { childToParent(event); }
                     // },
                     // {
                     //    label: 'Option 5',
                     //    short: 'createscenario',
                     //    icon: 'pi pi-fw pi-save',
                     //    command: (event) => { childToParent(event); }
                     // }
                  ]
               },
            ]
         ]
      }
   ];


   // const getDynDashboards = () => {
   //    axios.get(getFromLS('dbenv')+'/dashboards')
   //    .then((res)=>{
   //       const dynDashboards = res.data
   //       for (let item of dynDashboards) {
   //         if ( item.dashboardName !== undefined){
   //             megaItems[1].items[0][0].items.push({label: item.dashboardName, short: 'dynamic', icon: "pi pi-fw pi-home", command: (event) => { childToParent(event); }})
   //             // setCustomDashboards(megaItems[1].items[0][0].items)
   //         }
   //       }
         

   //       console.log(megaItems);
   //    }).catch((err)=>{
   //       console.log(err);
   //    })
   // }


   

   function getMegaItems() {
      return megaItems
   }
   


   const HandleAternity = (event) => {
      setToLS('aternity', 'true')
      setGlobalVisibleLeft(false)
      handleDash({ event })
   }

   const HandleDash = (dash) => {
      console.log(dash)
      removeFromLS('aternity')
      
      history.push('/')
      setGlobalVisibleLeft(false)
      if ( dash.source === 'sidebar' ) {
         setToLS('viewingDashboard', dash)
         handleDash({ id: dash.id, type: dash.type })
      } else {
         setToLS('viewingDashboard', dash.item)
         handleDash({ id: dash.item.id, type: dash.item.type })
      }
      
   }
   // const HandleDash = (dash) => {   
   //    console.log(dash)
   //    //removeFromLS('aternity')
   //    //setToLS('viewingDashboard', dash.item)
   //    // history.push('/')
   //    setVisibleLeft(false)
   //    setVisibleRight(false)
   //    handleDash({ id: dash.id, type: dash.type })
   // }

   // useEffect(() => {
   //    WebFont.load({
   //       google: {
   //          families: getFonts()
   //       }
   //    });
   // });

   // useEffect(() => {
   //    setSelectedTheme(theme);
   // }, [theme, themeLoaded]);

   useEffect(() => {
      // console.log(megaItems[2].items[0][0].items)
      // console.log(globalSubscribedServices)
      globalSubscribedServices
      .forEach((v) => {
         // console.log(v)
         nodes[2].items[0].items.push({ label: v.dash_name, id: v.dash_id, type: v.dash_type, icon: 'pi pi-fw pi-th-large', command: (event) => { HandleDash({ label: v.dash_name, id: v.dash_id, type: v.dash_type, source: 'sidebar' }); } })
         megaItems[2].items[0][0].items.push({ label: v.dash_name, id: v.dash_id, type: v.dash_type, command: (event) => { HandleDash({ label: v.dash_name, id: v.dash_id, type: v.dash_type, source: 'sidebar' }); } })
      })
      // console.log(nodes)
   }, [globalSubscribedServices])
   useEffect(() => {
      function getDynDashboards() {
         //if ( customDashboardsSidebar.length === 0 ){
            var customDashBoardsmenuItem = {
               "label": "Custom Dashboards",
               "icon": "pi pi-fw pi-star-fill",
               "items": []
           }
           
            axios.get(globalDbENv + ':7443/'+ globalDbName + '/dashboards')
            .then((res)=>{


               // console.log(res.data.sort((a, b) => (a.dashboardName > b.dashboardName) ? 1 : -1))
               
                  nodes[1].items.push(customDashBoardsmenuItem);
               
                  // console.log(megaItems[1].items[2][0].items)
                  const dynDashboards = res.data.sort((a, b) => (a.dashboardName > b.dashboardName) ? 1 : -1)
                  for (let item of dynDashboards) {
                     if ( item.dashboardName !== undefined){
                        megaItems[1].items[2][0].items.push({label: item.dashboardName, dynDash: true, short: 'dynamic', icon: "pi pi-fw pi-home", command: (event) => { childToParent(event); }})
                        nodes[1].items[2].items.push({label: item.dashboardName, dynDash: true, short: 'dynamic', icon: "pi pi-fw pi-home", command: (event) => { childToParent(event); }})
                     }
                     
                  }
                  setCustomDashboards(megaItems)
                  setCustomDashboardsSidebar(nodes)
                  
                  // console.log(customDashboards);
               
               
            }).catch((err)=>{
               console.log(err);
            })
         //}
         // console.log(customDashboardsSidebar)
         setTimeout(function () { $(".p-sidebar-header").prepend($(".sidebar-title")); $(".p-sidebar-header").addClass("p-sidebar-header-right"); }, 0);
         setGlobalVisibleLeft(false);
      }
      getDynDashboards();
      // console.log(customDashboards)
   }, [])




   const DoMobileSidebar = () => {

      

      // console.log(apiData)
      // const subscribedServicesArray = [
      //    {
      //       key: "13",
      //       label: 'My Services',
      //       children: []
      //    }
      // ];


      //    apiData
      //    .forEach((v,k) => {
      //       console.log(v)
      //        subscribedServicesArray[0].children.push({intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, command: (event) => { HandleDash({label: v.dash_name, id: v.dash_id, type: v.dash_type}); }})
      //       //  subscribedServicesArray[0].children.push({intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, onClick: (event) => HandleDash(event)});
      //    })
      //    console.log(subscribedServicesArray)
      //    setSubApiData(subscribedServicesArray)


      setTimeout(function () { $(".p-sidebar-header").prepend($(".sidebar-title")); $(".p-sidebar-header").addClass("p-sidebar-header-right"); }, 0);
      setGlobalVisibleBasket(true)
   }
   const runLogout = (event) => {
      setGlobalVisibleLeft(false);
      handleLogout(event)
   }
   
   const Dosidebar = () => {
      // if ( success ) {
      //    alert('success')

      //    let links = [];
      //    links = success && globalWynDashboards.map((v, k) => <Button plain hoverIndicator key={k} onClick={() => handleDash({ id: v.id, type: v.type })}> <Card><p truncate>{v.title}</p></Card> </Button>);

      //    setToLS('all-dashboards', globalWynDashboards);
      //    setToLS('all-dashboards-safe', globalWynDashboards);
      // }

      setGlobalVisibleLeft(true)
      setTimeout(function () { $(".p-sidebar-header").prepend($(".sidebar-title")); }, 0);
      const getAllDashboards = getFromLS('all-dashboards');
      const getAllDashboardsCount = getAllDashboards.length;

      console.log(getAllDashboardsCount)

      setGlobalDashboardCount(getAllDashboardsCount)
      
      // console.log(getAllDashboards)
      console.log(globalWynDashboards)

      const subscribedServicesArray = [
         {
            key: "13",
            label: 'My Services',
            disabled: true,
            items: []
         }
      ];


      apiData
         .forEach((v, k) => {
            // console.log(v)
            subscribedServicesArray[0].items.push({ intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, command: (event) => { HandleDash({ label: v.dash_name, id: v.dash_id, type: v.dash_type }); } })
            //  subscribedServicesArray[0].children.push({intType: 'subRedirect', label: v.dash_name, id: v.dash_id, type: v.dash_type, onClick: (event) => HandleDash(event)});
         })
      // console.log(subscribedServicesArray)
      setSubApiData(subscribedServicesArray)
      // axios(getFFromLS('dbenv') + "/subscribed_services", config)
      //   .then(data => setApiData(data.data))
      //   .then(console.log(apiData))

      //   .then(
      //   apiData
      //   .forEach((v,k) => {
      //    console.log(v)
      //       // listItems.push({label: v.displayName, onClick: () => params.handleTenant(v.displayName)});
      //       // tenantsArrayItems.push({label: v.displayName})
      //       subscribedServicesArray[0].children.push({label: v.dash_name, key: k, command: (event) => childToParent(event)})
      //       // listItemsTree[0].push({label: v.displayName, onClick: () => params.handleTenant(v.displayName)});
      //   })
      //   )
      //   .then(console.log(subscribedServicesArray))

      setTimeout(function () { $(".p-sidebar-header").prepend($(".sidebar-title")); $(".p-sidebar-header").addClass("p-sidebar-header-right"); }, 0);
      // setVisibleRight(true)




      // Empty array on fresh call
      newArr = [];
      var loopData = [];

      if (globalWynDashboards) {
         var i;
         for (i = 0; i < globalWynDashboards.length; i++) {
            loopData = { id: globalWynDashboards[i].id, title: globalWynDashboards[i].title, type: globalWynDashboards[i].type, command: (event) => { HandleDash(event); } }
            newArr.push(loopData)
         }
         var aternityDash = {
            "id": "aternity",
            "title": "Aternity",
            "type": "dbd",
            command: (event) => { HandleAternity(event); }
         }
         loopData = aternityDash;
         //newArr.push(loopData)
         console.log(newArr)
         // setGlobalWynDashboards([])
         setGlobalWynDashboards(newArr)
         console.log(newArr)
         console.log(globalWynDashboards)
         setGlobalDashboardCount(newArr.length);
      }

      // Sort the list alphabetially
      //newArr.sort((a, b) => (a.title > b.title) ? 1 : -1)
   }
   // console.log(subscribedServicesArray)
   return (

      <div className="top-bar-bg">
         <div className='container-fluid'>
            <div className='row mobile-header'>
               <div className='col-12 p-0'>
                  <div className="sidebar-trigger left" onClick={() => Dosidebar()}><Button icon="pi pi-bars" className="p-mr-2" /></div>
                  <div className='logo-container'>
                     <img alt="logo" src="storm-grey-storm-logo-noswirl.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} height="40" className="storm-logo"></img>
                     <img alt="Storm" src="./swirl.png" className="logo-swirl rotating" />
                  </div>
                  {globalBasketCount > 0 &&
                     <div className="sidebar-trigger right pr-2" onClick={() => DoMobileSidebar()}><Button icon="pi pi-shopping-cart" className=""><Badge value={globalBasketCount} className='basket-badge'></Badge></Button></div>
                  }
               </div>
            </div>
            <div className='row hide-mobile'>
               <div className='col-12 col-sm-12 col-md-8 col-lg-8 p-0'>
                  <div className="sidebar-trigger inline-block" onClick={() => Dosidebar()}><Button icon="pi pi-bars" className="p-mr-2" /><span className="hide-mobile">Open menu</span></div>
                  {authSuccess && <MegaMenu model={customDashboards} className="top-bar-bg the-mega-items" />}
                  {/* <div className='float-right mt-4'>
                     <span className="baseline-middle">Current Tenant: &nbsp;</span> <DropdownTenant />
                  </div> */}
               </div>
               {/* <div className='col-12 col-sm-12 col-md-8 col-lg-8 hide-mobile'>
                     {authSuccess && <MegaMenu model={getMegaItems()} className="top-bar-bg the-mega-items" />}
               </div> */}
               <div className='col-12 col-sm-12 col-md-4 col-lg-4 hide-mobile text-right top-bar-logo'>
                  <div className='small-text inline-block header-current-tenant-label'><span className="tenant-text text-uppercaseÛ">Tenant:</span> <span className='tenant-value bold-weight text-uppercase'>{globalCurrentTenant.label}</span></div>
                  <div className='inline'>
                     <img alt="logo" src="storm-grey-storm-logo.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} height="40" className="p-mr-2 storm-logo pr-2"></img>
                  </div>

               </div>
            </div>
         </div>

         <Sidebar modal="false" dismissable visible={globalVisibleLeft} onMouseOut={() => setGlobalVisibleLeft(false)} onHide={() => setGlobalVisibleLeft(false)} >
            <h3 className="sidebar-title">The Menu</h3>
            <div className="">


               {/* <SplitButton label="please select" icon="pi pi-th-large" model={newArr} className="width-100-percent p-button-warning splitbutton b-radius-white"></SplitButton> */}
               <div className='tenant-dropdown-select'>
                  <Dropdown value={selectedDashboard} options={globalWynDashboards} onChange={onCityChange} optionLabel="title" filter filterBy="title" placeholder={<div>Select Dashboard<Badge value={globalDashboardCount} className="p-badge-info no-of-dashboards float-right"></Badge></div>}
                     valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
               </div>

               <div className='mt-1'>
                  <DropdownTenant />
               </div>
               <div className='sidebar-menu-tree'>
               <PanelMenu model={customDashboardsSidebar} nodeTemplate={nodeTemplate} style={{ width: '100%' }}/>
                  {/* <Tree value={nodes} nodeTemplate={nodeTemplate} className="transparent-bg user-menu" />
                  <Tree value={apiSubData} nodeTemplate={subServiceTemplate} className="transparent-bg user-menu" /> */}
               </div>
            </div>

            <div>
               <Button onClick={(event) => runLogout(event)} className="set-to-bottom p-mr-2 logout-sidebar-button"><i className="pi pi-sign-out mr-3" />Logout</Button>
               {/* <SplitButton label="My Account" icon="pi pi-user" model={getItems()} className="set-to-bottom p-button-warning p-mr-2"></SplitButton> */}
            </div>


         </Sidebar>

         <Sidebar dismissable visible={globalVisibleBasket} position="right" onMouseOut={() => setGlobalVisibleBasket(false)} onHide={() => setGlobalVisibleBasket(false)}>
            <h3 className="sidebar-title">Basket Items</h3>
            <div className="">
               {/* <div className=''>
                  <DropdownTenant />
                  </div> */}
               <div className="p-d-flex p-jc-between pl-4 pr-4 pt-4 pb-0">
                  <Basket childToParent={childToParent} />

               </div>




            </div>
            {/* <div className='container-fluid p-0'>
               <div className='row'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                     <div className='service-status p-4'>
                        
                        
                     {apiData ? apiData.map(item =>
                        <Button type="button" className={"p-mr-2 p-button-sm " + item.button_colour_class} onClick={(event) => doServiceSubsRedirect(event)}><i className="pi pi-circle-fill ok-stat" />{item.dash_name}</Button>
                     ) : null}
                     </div>
                  </div>
               </div>
               
            </div> */}
         </Sidebar>


      </div>
   )
};

export default AppBar;