/* eslint-disable no-unused-vars */
import React, { Component, createClass, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

import { TenantLookupService } from '../components/service/tenantLookup';

import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

import ReactSpeedometer from "react-d3-speedometer";
import { ajaxPrefilter } from 'jquery';


const theIncidents = [];

let config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
    }
}





const ApplicationUxiSpeedometer = () => {

    const [products, setProducts] = useState([]);
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const [globalTenantSwitched, setGlobalTenantSwitched] = useGlobal('tenantSwitched');
    const [selectedOdometer, setSelectedOdometer] = useGlobal('selectedOdometer');;
    const [value, setValue] = useState(2.75);
    const [ globalApplicationUxi, setGlobalApplicationUxi ] = useGlobal('applicationUxi');

    const tenantService = new TenantLookupService();


    // const tenantLookupValue = TenantLookupService.tenantLookupValue;
    // alert(tenantLookupValue)

    const cities = [
        { name: 'All devices', code: 'all' },
        { name: 'Desktop', code: 'desktop' },
        { name: 'Mobile', code: 'mobile' },
        { name: 'Vdi', code: 'vdi' }
    ];



    const onCityChange = (e) => {
        setSelectedOdometer(e.value);
        console.log(e.value);
       getApplicationUXiData(e.value.code)
    }

    let uxiValue = 0;
    let theCurrTenant;
    

    

    const getApplicationUXiData = (code) => {
        theCurrTenant = tenantService.translate();
        console.log(tenantService.translate());
        console.log(code);
        setGlobalTenantSwitched(false);
        
        let typeToAnalyse = 'averageuxi';
        switch(code) {
            case 'all':
              typeToAnalyse = 'averageUxi'
              break;
            case 'mobile':
              typeToAnalyse = 'averageUxiMobile'
              break;
            case 'desktop':
                typeToAnalyse = 'averageUxiDesktop'
              break;
            case 'vdi':
                typeToAnalyse = 'averageUxiWeb'
              break;
            default:
              // code block
          }
        axios.get(globalDbENv + ':7447/'+ globalDbName + '/'+typeToAnalyse, config)
            .then((res)=>{
                console.log(res.data)
                console.log(theCurrTenant.toString())
                for (let item of res.data) {
                    console.log(item._id)
                    console.log(typeof item._id)
                    if ( item._id === Number(theCurrTenant) ) {
                        uxiValue = item.avguxi;
                    }
                    //theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
                setGlobalApplicationUxi(uxiValue)
            }).catch((err)=>{
                console.log(err);
            })
    }

    useEffect(() => {
        //theCurrTenant = tenantService.translate();
        console.log(tenantService.translate())
        console.log(cities[0])
        // getApplicationUXiData(globalApplicationUxi)
        getApplicationUXiData('all')
    }, [globalTenantSwitched]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if ( getFromLS('canRunApi') === true  ) {
            setSelectedOdometer({ name: 'All devices', code: 'all'})
            axios.get(globalDbENv + ':7443/'+ globalDbName + '/averageUxi', config)
            .then((res)=>{
                console.log(res.data)
                for (let item of res.data) {
                    console.log(item)
                    if ( item._id === Number(theCurrTenant) ) {
                        uxiValue = item.avguxi;
                    }
                    //theIncidents.push({'activity_name': item["Activity Name"], 'application_name': item['Application Name'], 'open_time': moment(item['Incident Open Timestamp']).format('dMy, h:mm:ss a'), 'close_time': moment(item['Incident Close Timestamp']).format('dMy, h:mm:ss a')});
                }
                setGlobalApplicationUxi(uxiValue)
            }).catch((err)=>{
                console.log(err);
            })
        }
        
    }, [globalTenantSwitched]);
    
    
  return (
    <div className="card welcome-widget speedo chartheight">
            

            <div>
                <h5>Application Uxi</h5>
                <div className='text-center'>
                <ReactSpeedometer
                    maxValue={10}
                    
                    value={parseFloat(globalApplicationUxi).toFixed(2)}
                    needleColor="red"
                    startColor="green"
                    endColor="red"
                />
                </div>
                <Dropdown className="width-100-percent mt-3" value={selectedOdometer} options={cities} onChange={onCityChange} optionLabel="name" placeholder="Select a device type" />
                
            </div>
        </div>
  );
};

export default ApplicationUxiSpeedometer;

