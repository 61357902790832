/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';

import { getFromLS as getFFromLS } from 'features/app/utils/storage'; 
import { useGlobal } from 'reactn';

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}

const WebPerformance = () => {

    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const [ globalCanRunApi, setGlobalCanRunApi ] = useGlobal('canRunApi');
    const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
    const theWebPerformanceData = [];
    const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length

    const webPerformanceYesterdayDataSet = [];
    const webPerformanceTodayDataSet = [];
    let tAvg
    let yAvg
    let webPerformanceData = {
        labels: ['Average'],
        datasets: [
        {
            label: 'Today',
            backgroundColor: '#474747',
            data: [2]
        },
        {
            label: 'Yesterday',
            backgroundColor: '#b5b5b5',
            data: [4]
        }
        ]
    };
    
    
    const webApplicationPerformance = {
        labels: ['Forecast', 'Namely', 'Sharepoint', 'Zendesk', 'Google Docs', 'Netsuite', 'Zoom'],
        datasets: [
          {
            label: 'Average',
            backgroundColor: '#5CA39A',
            data: [2932, 1023, 1023, 3730, 3730, 1829, 1829]
          },
          {
            label: 'Min',
            backgroundColor: 'rgb(145,145,145)',
            data: [2849, 1609, 3421, 4824, 1868, 4217, 1318]
          },
          {
            label: 'Max',
            backgroundColor: '#474747',
            data: [7692, 2793, 3421, 6438, 1868, 10473, 1318]
          }
        ]
      };
    
      let webApplicationPerformanceOptions = {
        maintainAspectRatio: true,
        aspectRatio: .8,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
    
      let basicOptions = {
        maintainAspectRatio: true,
        aspectRatio: .8,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#000000'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#000000'
            },
            grid: {
              color: '#000000'
            }
          }
        }
      };

      useEffect(() => {
        if ( globalCanRunApi === true ) {
          axios.get(globalDbENv  + ':7443/'+ globalDbName + '/web_performance')
          .then((res) => {
  
              const webPerformance = res.data
              for (let item of webPerformance) {
                webPerformanceTodayDataSet.push(item[" Today"]);
                webPerformanceYesterdayDataSet.push(item.Yesterday);
              }
  
              tAvg = arrAvg(webPerformanceTodayDataSet)
              yAvg = arrAvg(webPerformanceYesterdayDataSet)
  
              webPerformanceData = {
              labels: ['Average'],
              datasets: [
                  {
                  label: 'Today',
                  backgroundColor: '#474747',
                  data: [tAvg]
                  },
                  {
                  label: 'Yesterday',
                  backgroundColor: '#b5b5b5',
                  data: [yAvg]
                  }
              ]
              };
          }).catch((err) => {
              console.log(err);
          })
        }
      }, []);
    
  return (
    <div>
        <div className="card">
            {webPerformanceYesterdayDataSet && webPerformanceTodayDataSet}
            <Chart type="bar" data={webPerformanceData} options={basicOptions} />
        </div>
    </div>
  );
};

export default WebPerformance;
