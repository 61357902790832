/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './service/down.json';
import deviceStatus from './service/device_status.json';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

const DataTableStripedDemo = () => {
    const [products, setProducts] = useState(data);

    useEffect(() => {
        fetch('service/down.json')
          .then((res) => res.json())
          .then((data) => setProducts({...data}))
          .then((data) => alert({...data}));
      }, []);

    return (
        <div>
            <div className="card">
                <DataTable value={deviceStatuses} stripedRows responsiveLayout="scroll">
                    <Column field="AGENT_CURRENT_STATUS" header="Current Status"></Column>
                    <Column field="DEVICE_PERFORMANCE_SCORE" header="Performance Score"></Column>
                    <Column field="HEALTH_STATUS" header="Status"></Column>
                </DataTable>
            </div>
        </div>
    );
}

/**
 * This layout demonstrates how to sync multiple responsive layouts to localstorage.
 */

const deviceStatuses = deviceStatus.value;

const devicesStatus = deviceStatuses.reduce((catsSoFar, { AGENT_CURRENT_STATUS, DEVICE_HEALTH_SCORE }) => {
if (!catsSoFar[AGENT_CURRENT_STATUS]) catsSoFar[AGENT_CURRENT_STATUS] = [];
catsSoFar[AGENT_CURRENT_STATUS].push(DEVICE_HEALTH_SCORE);
return catsSoFar;
}, {});

const AverageDisconnectedHealth = () => {
    for(var i = 0; i < devicesStatus.Disconnected.length; i++) {
        // total += grades[i];
    }
}
AverageDisconnectedHealth()

 const uxiChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [300, 50, 100],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  const stabilityChartData = {
    labels: ['Fair 3-4', 'Good 4+', 'Poor < 3'],
    datasets: [
        {
            data: [100, 50, 300],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#ce0000"
            ],
            hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
            ]
        }
    ]
  };
  
  const lightOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
  };
  
  const stackedData = {
    labels: ['Kim Hunt', 'Aaron De Pury', 'Aanyah Cook', 'Aaron Emberson', 'Abdul Haq', 'Aaron Porter', 'Aaron Reid'],
    datasets: [{
        type: 'bar',
        label: 'Web Browsing',
        backgroundColor: '#42A5F5',
        data: [
            50,
            25,
            12,
            48,
            90,
            76,
            42
        ]
    }, {
        type: 'bar',
        label: 'CDV Viewer',
        backgroundColor: '#66BB6A',
        data: [
            21,
            84,
            24,
            75,
            37,
            65,
            34
        ]
    }, {
        type: 'bar',
        label: 'Other',
        backgroundColor: '#FFA726',
        data: [
            41,
            52,
            24,
            74,
            23,
            21,
            32
        ]
    }]
  };
  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            stacked: true,
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
  };

  function getFromLS(key) {
    let ls = {};
    global.localStorage.removeItem("rgl-8");
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }
  
  function saveToLS(key, value) {
      
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

class CreateScenario extends Component {
    

    constructor(props) {
        super(props);
        
        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts))
        };
    }

    static get defaultProps() {
        
        return {
            className: "layout",
            cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 30
        };
    }

    componentDidMount() {
    }

    resetLayout() {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

  render() {
    const footer = (
      <span>
          <Button className="p-button-sm" label="Add to basket" icon="pi pi-check" />
      </span>
    );
    return (
        <div>
          <div className="panel-content loose title-container p-ml-3 p-mr-3 p-mt-3 p-mb-3" key="1" data-grid={{ w: 12, h: 2, x: 8, y: 0, minW: 2, minH: 3 }}>
          <div className="panel-title">
            <p stylename="margin-top: 0;">Create Scenario</p>
            <small>Scenario ></small>
          </div>
        </div>
        <div className="p-grid p-p-3">
          <div className="p-col-3">
            <Card title="Widget 1" className="p-p-5" footer={footer}>
              <p className="p-m-0" style={{ lineHeight: '1.5' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
            </Card>
          </div>
          <div className="p-col-3">
            <Card title="Widget 2" className="p-p-5" footer={footer}>
              <p className="p-m-0" style={{ lineHeight: '1.5' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
            </Card>
          </div>
          <div className="p-col-3">
            <Card title="Widget 3" className="p-p-5" footer={footer}>
              <p className="p-m-0" style={{ lineHeight: '1.5' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
            </Card>
          </div>
          <div className="p-col-3">
            <Card title="Widget 4" className="p-p-5" footer={footer}>
              <p className="p-m-0" style={{ lineHeight: '1.5' }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
            </Card>
          </div>
        </div>
      </div>
        
    )
  }
}

export default CreateScenario;