/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import 'moment-timezone';
import { getFromLS } from 'features/app/utils/storage';

import { getFromLS as getFFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}

const EnterpriseApps = () => {

  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length

  

  const basicData = {
    labels: ['Excel', 'Outlook', 'Powerpoint', 'teams', 'Word'],
    datasets: [
      {
        label: 'Today',
        backgroundColor: '#5CA39A',
        data: [96, 99, 100, 100, 92]
      },
      {
        label: 'Yesterday',
        backgroundColor: '#474747',
        data: [95, 99, 100, 100, 94]
      }
    ]
  };









  let basicOptions = {
    maintainAspectRatio: true,
    aspectRatio: .8,
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#000000'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        ticks: {
          color: '#000000'
        },
        grid: {
          color: '#000000'
        }
      }
    }
  };



  useEffect(() => {
    if (getFromLS('canRunApi') === true) {
      // axios.get(globalDbENv + '/web_performance')
      //   .then((res) => {

      //     const webPerformance = res.data
      //     for (let item of webPerformance) {
      //       webPerformanceTodayDataSet.push(item[" Today"]);
      //       webPerformanceYesterdayDataSet.push(item.Yesterday);
      //     }

      //     tAvg = arrAvg(webPerformanceTodayDataSet)
      //     yAvg = arrAvg(webPerformanceYesterdayDataSet)

      //     webPerformanceData = {
      //       labels: ['Average'],
      //       datasets: [
      //         {
      //           label: 'Today',
      //           backgroundColor: '#474747',
      //           data: [tAvg]
      //         },
      //         {
      //           label: 'Yesterday',
      //           backgroundColor: '#b5b5b5',
      //           data: [yAvg]
      //         }
      //       ]
      //     };
      //   }).catch((err) => {
      //     console.log(err);
      //   })
    }
  }, []);

  return (
    <div>
      <div className="card">
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
};

export default EnterpriseApps;
