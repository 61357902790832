/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, Component } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import { Button } from 'primereact/button';
import deviceStatus from './service/device_status.json';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Card } from 'primereact/card';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';

import Example from './stepper/examples/Example';



import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import WidgetLibrary from './widget_library';
import DataLibrary from './data_library';
import SidebarBasket from 'pages/maindash/components/basket/sidebar';
import { setToLS, getFromLS } from '../../../features/app/utils/storage';
import { Sidebar } from 'primereact/sidebar';
import Basket from './basket/basket';
import { Badge } from 'primereact/badge';
import { TenantLookupService } from '../components/service/tenantLookup';
import { useGlobal } from 'reactn';
import { NULL } from 'sass';


const steps = ['Select Dashboard Details', 'Select Data', 'Select Visualisation'];
setToLS('basket', []);


function getCurrTheme() {
  const currTheme = getFromLS("theme") || {};
  return currTheme.name
}



function getFromLSS(key) {
  let ls = {};
  global.localStorage.removeItem("rgl-8");
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /**Ignore**/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {

  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

export function NewDashboardForm() {

  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');

  const isDashNameSet = () => {
    if (dashboardName !== '') {
      return <p className='p-mt-5 p-mb-1'>Dashboard Name: <strong>{dashboardName}</strong></p>
    }
  }
  const handleDashNameChange = (event) => {
    setDashName(dashboardName = event.target.value)
    setToLS('createDashName', event.target.value);

  }

  const isDashCategorySet = () => {
    if (dashboardCategory !== '') {
      return <p className='p-mb-1 p-mt-0'>Dashboard Category: <strong>{dashboardCategory}</strong></p>
    }
  }

  const handleDashCatChange = (event) => {

    setDashCategory(dashboardCategory = event)
    setToLS('createDashcat', event);

  }





  const theCategories = [];


  let config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "withCredentials": false,
    }
  }

  // axios.get(globalDbENv  + ':7443/'+ globalDbName +  '/dashboard_create_categories', config)
  //   .then((res) => {
  //     const acats = res.data

  //     for (let item of acats) {

  //       theCategories.push({ 'cat_name': item["cat_name"], 'cat_value': item['cat_value'] });
  //     }

  //   }).catch((err) => {
  //     console.log(err);
  //   })


  let [dashboardName, setDashName] = React.useState('');
  let [dashboardCategory, setDashCategory] = React.useState('');

  return (

    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
            <form>



              <TextField
                id="standard-helperText"
                name="first_name"
                label="Enter Dashboard Label"
                helperText="Create dashboard label"
                variant="standard"
                onChange={handleDashNameChange}
              />

              <FormControl fullWidth className='p-mt-3'>
                <Dropdown optionLabel="cat_name" optionValue="cat_value" value={theCategories.cat_value} options={theCategories} onChange={(e) => handleDashCatChange(e.value)} placeholder="Select category" />
              </FormControl>

              {/* <p className="basic-text p-m-0" isDashNameSet>{ isDashNameSet() } </p>
              <p className="basic-text p-mt-0" isDashcategorySet>{ isDashCategorySet() } </p> */}





              {/* <div>
                <h3 style={{ color: "#389926" }}>Values</h3>
                <pre>{JSON.stringify(value, 0, 2)}</pre>
              </div> */}


            </form>
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
            <p className='basic-text'><strong>Begin</strong> by creating a label for your new dashboard. </p>
            <p className='basic-text'><strong>Then</strong> choose a category for which the dashboard will belong to.</p>
            <p className='basic-text'><strong>Then</strong> once you have completed those values, click the next button.</p>
          </div>
        </div>
      </div>


    </div>
  )

}



const NewDashboard = () => {



  const [activeIndex, setActiveIndex] = useState(0);
  const [globalBasketCount, setGlobalBasketCount] = useGlobal('basketCount');
  const [basketItems, setBasketItems] = useGlobal('basketItems');
  const [globalVisibleBasket, setGlobalVisibleBasket] = useGlobal('visibleBasket');
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [ dynDashWidgetName, setDynDashWidgetName ] = useGlobal('dyn_dash_widget_name');
  const [ dynDashName, setDynDashName ] = useGlobal('dyn_dash_name');
  const [ dynDashWidgetCollectionSelected, setDynDashWidgetCollectionSelected ] = useGlobal('dyn_dash_widget_collection_selected');
  const [ dynDashWidgetCollectionSelectedFieldsOptionSelected, setDynDashWidgetCollectionSelectedFieldsOptionSelected ] = useGlobal('dyn_dash_widget_collection_selected_fields_option_selected');
  const [dynDashWidgetVisualisation, setDynDashWidgetVisualisation] = useGlobal('dyn_dash_widget_visualisation');
  


  const dialogFuncMap = {
    'displayMaximizable': setDisplayMaximizable,
    'displayResponsive': setDisplayResponsive
  }

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);


  }

  const DbItems = [
    {
      label: 'Demo-BTT-PageLoads',
      items: [
        {
          label: 'Fields',
          items: [
            {
              label: 'Date',
            },
            {
              label: 'Onload (s)',
            },
            {
              label: 'Account ID',
            }
          ]
        }
      ]
    },
    {
      label: 'Demo-BTT-PageViews',
      items: [
        {
          label: 'Fields',
          items: [
            {
              label: 'Date',
            },
            {
              label: 'Page Views',
            },
            {
              label: 'Account ID',
            }
          ]
        }
      ]
    },
    {
      label: 'Demo-BTT-error-state-report-table',
      items: [
        {
          label: 'Fields',
          items: [
            {
              label: 'Time of status change',
            },
            {
              label: 'Monitor name',
            },
            {
              label: 'Page name',
            },
            {
              label: 'HTTP Status code',
            },
            {
              label: 'Duration',
            },
            {
              label: 'Description',
            },
            {
              label: 'Mask',
            },
            {
              label: 'Mask comment',
            },
            {
              label: 'Mask detail',
            }
          ]
        }
      ]
    }
  ];

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
        <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
      </div>
    );
  }

  let currBasket = basketItems;


  const toast = useRef(null);

  const items = [
    {
      label: 'Select Dashboard Details',
      command: (event) => {
        toast.current.show({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Select Data',
      command: (event) => {
        toast.current.show({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },
    {
      label: 'Select Visualisation',
      command: (event) => {
        toast.current.show({ severity: 'info', summary: 'Pay with CC', detail: event.item.label });
      }
    }
  ];


  const [visibleLeft, setVisibleLeft] = useState(false);


  console.log(dynDashWidgetCollectionSelectedFieldsOptionSelected)
  const childToParent = (childdata) => {

    setVisibleLeft(false)
  }
  const upTheme = getCurrTheme();

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div className='container-fluid steps-demo'>
        <div className='row panel-content loose title-container pl-2 pr-2'>
          <div className='col-12 col-sm-12 col-md-7 col-lg-7'>
            <div className="panel-title p-pt-2 p-pl-2">
              <p stylename="margin-top: 0;">Create a new visual data landscape</p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-5 col-lg-5 text-right'>

          </div>
        </div>
        <div className='row height-100vh'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-5 p-0'>
            <div className='left-header p-3'>
              <p className="biguns-header fw300 p-mt-0 p-mb-2">Create.</p>
              <p className="biguns-header fw200 up p-mt-0">Define your multi-service data landscape dashboard</p>
              {globalBasketCount > 0 &&
                <p className='mt-4'>View {globalBasketCount} Basket Items</p>
              }
              {dynDashName !== undefined &&
                <p className='mt-3'><strong>Dashboard Name</strong>: {dynDashName}</p>
              }
              {dynDashWidgetName !== undefined &&
                <p className='mt-3'><strong>Widget Name</strong>: {dynDashWidgetName}</p>
              }
              
              {dynDashWidgetCollectionSelected !== null &&
                <p><strong>Collection: </strong>{dynDashWidgetCollectionSelected.collection_name}</p> 
              }
              {dynDashWidgetCollectionSelectedFieldsOptionSelected !== null &&
                <p><strong>Fields: </strong>{dynDashWidgetCollectionSelectedFieldsOptionSelected}</p> 
              }
              {dynDashWidgetVisualisation !== null &&
                <p><strong>Visualisation: </strong>{dynDashWidgetVisualisation}</p> 
              }
              


              
              {/* <Dialog header="Header" visible={displayMaximizable} maximizable modal style={{ width: '50vw' }} footer={renderFooter('displayMaximizable')} onHide={() => onHide('displayMaximizable')}>
                <p className="m-3">Select Data Source.</p>
                <PanelMenu model={DbItems} style={{ width: '22rem' }} />
              </Dialog> */}

            </div>

          </div>
          <div className='col-12 col-sm-12 col-md-12 col-lg-7 bg-white-go p-0'>
            <Example />
            {/* <HorizontalLinearStepper /> */}
            {upTheme === 'Light' &&
              <img alt="logo" src="cogwheel-light.png" className='cogs hide-mobile' />
            }
            {upTheme === 'Dark' &&
              <img alt="logo" src="cogwheel-dark.png" className='cogs hide-mobile' />
            }
          </div>
        </div>
      </div>
      <Sidebar position="left" visible={visibleLeft} onHide={() => setVisibleLeft(false)} className='p-sidebar-md'>
        <h3 className='sidebar-header p-mb-3'>Create Dashboard Basket Items</h3>
        <Basket childToParent={childToParent} />
      </Sidebar>
      {/* <SidebarBasket visible={visibleLeft} onHide={() => setVisibleLeft(false )}>
              <h3 className='sidebar-header'>Create Dashboard</h3>
              <p className="panel-title">Basket</p>
      </SidebarBasket> */}


    </div>

  )
}


export default NewDashboard;