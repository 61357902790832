import React, { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LoadingPage from 'components/ui/loading-page';
import TenantDialog from 'features/tenant';
import useDashboards from  'features/dashboards/use';
import useWyn from  'features/wyn/use';
import PageCenterStyle from "components/ui/page-centre.style";
import { Card } from 'primereact/card';
import { getFromLS } from 'features/app/utils/storage';
import { useGlobal } from 'reactn';


const HomeNoDisplay = () => {
    const { handleDash } = useDashboards();
    const { loading: dashLoading, success: dashSuccess } = useDashboards();
    const { loading: wynLoading, success: wynSuccess } = useWyn();
    const [ globalStartupKey, setGlobalStartupKey ] = useGlobal('startupKey');
    // const dashLink = dashSuccess && ( selected ? selected.id : data[0].id);
    // const embedType = dashSuccess && (selected ? (selected.type === 'dbd' ? 'dashboards' : 'webpages') : (data[0].type === 'dbd' ? 'dashboards' : 'webpages'));
    // const iframeUrlAternity = `https://eu2.aternity.com/#/view/AdvancedMultiDSB11643/EUCMenu?BACCT=1841&BCustomDashboardId=11643&BTimeframeParam=TF_09-08-21%2011:00:00_10-08-21%2010:55:00_Last24Hours&BTitleParam=WFA%20-%20Menu%20v1.0&UTimeframeParam=TF_20-09-21%2008:00:00_21-09-21%2007:40:00_Last24Hours&BMode=preview`;
    // const iframeUrl = wynSuccess && `https://iav.stormportal.net/${embedType}/view/${dashLink}?token=${wynData.access_token}&size=fittoscreen&actions=clearselection&openfulldashboard`;

    const getCurrentDashRaw = () => {
        console.log(getFromLS('viewingDashboard'))
        if ( getFromLS('viewingDashboard') ) {
            var dashLabel = getFromLS('viewingDashboard')
            if ( dashLabel ) {
              handleDash({id: dashLabel.id, type: dashLabel.type})
            }            
        }
    }

    
    useEffect(()=>{
        getCurrentDashRaw()
        setGlobalStartupKey(true);
    },[]) 

    

    return (
        <div data-testid='HomeComponent'>
            {(wynLoading || dashLoading) && <div><LoadingPage data-testid='LoadingComponent'/></div>}
            <Card>
                <TenantDialog/>
                <Card direction='row' flex overflow={{ horizontal: 'hidden' }}>
                    {/* <SideBar/> */}
                    <Card flex justify="center" data-testid='ContentComponent'>
                        {/* <AppBar data-testid='NavBarHead'/> */}
                        {!wynSuccess && !dashSuccess && <PageCenterStyle/>}
                        <Card>
                            <AuthenticatedTemplate>
                                {getFromLS('aternity') && <>
                                {/* <StyledFrame>
                                    <iframe
                                    title="Selected Dashboard"
                                    className="dashboard-preview" 
                                    src={iframeUrlAternity}
                                    frameBorder="0"
                                    />
                                </StyledFrame> */}
                                </>}
                                {dashSuccess && <>
                                {/* <StyledFrame>
                                    <iframe
                                    title="Selected Dashboard"
                                    className="dashboard-preview" 
                                    src={iframeUrl}
                                    frameBorder="0"
                                    />
                                </StyledFrame> */}
                                </>}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <h1 level='6'>
                                    <center>Please sign-in to gain access to your dashboards.</center>
                                </h1>
                            </UnauthenticatedTemplate>
                        </Card>
                    </Card>
                </Card>
            </Card>
        </div>
    )
}

export default HomeNoDisplay;
