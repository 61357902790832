import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actionSelectors from './slice';
import axios from 'axios';

export function getWynToken(state) {
    return state.wyn.data?.access_token;
}

export const flatten = (a) =>  a.reduce((flat, next) => flat.concat(next.documents), []);

export function* getDashboardList() {
    const extendedQuery = `query {
        documenttypes(key: "dbd,wbp") {
            documents(orderby: "-modified") {
               id,
               description,
               type,
               title,
               tags {id, name},
               created_by { name },
               created,
               modified_by { name },
               modified
            }
        }
     }`;
    try {
        const wynToken = yield select(getWynToken);
        if (wynToken) {
            const wynGraphQlUrl = `https://iav.dev.stormportal.net:7445/api/GraphQL?token=${wynToken}`;
            const graphQuery = {
                query: extendedQuery
            };
            const response = yield axios.post(wynGraphQlUrl, graphQuery);
            const { data: { documenttypes } } = response.data;
            const docs = flatten(documenttypes);
            yield put(actionSelectors.success(docs));
        }
        
    } catch(e) {
        yield put(actionSelectors.error(e));
    }
}

export default function* dashboardsSaga() {
    yield all([
        takeLatest(actionSelectors.load.type , getDashboardList),
    ]);
}
