/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import AppBar from 'components/header/app-bar';
import Welcome from './components/welcome';
import Welcome2 from './components/welcome2';
import WelcomeStatic from './components/welcomeStatic';
import Overview from './components/overview';
import EditProfile from './components/edit_profile';
import Operations from './components/operations';
import DeviceStatus from './components/device_status';
import DataLibrary from './components/data_library';
import WidgetLibrary from './components/widget_library';
import Wfa from './components/wfa';
import RpStormNews from './components/rp_storm_news';

import Sase from './components/sase';
import CreateScenario from './components/create_scenario';
import NewDashboard from './components/new_dashboard';
import GetDynamicDash from './components/getDynamicDash';
import useDashboards from  'features/dashboards/use';
import HelpSupport from './components/help_support';
import HomeNoDisplay from '../../pages/homeNoDisplay/index'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { setToLS, getFromLS } from 'features/app/utils/storage';
import { OverlayPanel } from 'primereact/overlaypanel';
import BusinessWorkflowTrafficLight from './widgets/business_workflow_traffic_lights';
import $ from 'jquery';
import { Button } from 'primereact/button';
import ServiceUpdates from './components/app_bar/service-updates/service_updates';
import NewsUpdates from './components/app_bar/news/news';
import ReportsUpdates from './components/app_bar/reports/reports';
import axios from 'axios';
import { useGlobal } from 'reactn';
const Maindash = (props) => {  
  const history = useHistory();
  const toast = useRef(null);
  let op = useRef(null);
  let serviceUpdates = useRef(null);
  let reports = useRef(null);
  const isMounted = useRef(false);
  const location = useLocation();
  const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
  const [ appWidgets, setAppWidgets ] = useGlobal('appWidgets');
  const [ customDashboards, setCustomDashboards ] = useGlobal('customDashboards');
  const { loading, success, handleDash } = useDashboards();
  const [ globalStartupKey, setGlobalStartupKey ] = useGlobal('startupKey');
  const [ getAppDataFlag, setGetAppDataFlag ] = useGlobal('getAppDataFlag');
  const [globalVisibleLeft, setGlobalVisibleLeft] = useGlobal('visibleLeft');
  const [globalSubscribedServices, setGlobalSubscribedServices] = useGlobal('subscribedServices');
  const [ globalNewsType, setGlobalNewsType ] = useGlobal('newsType');
  const [ globalDbName, setGlobalDbName ] = useGlobal('dbenvname');
  const [selectedProduct, setSelectedProduct] = useState(null);
  let [datai, setData] = useState('Welcome2');
  const theWidgets = [];
  // setToLS('startup', null)
  //setGlobalStartupKey(null)

  const getAppData = () => {
    console.log(getAppDataFlag)
    if ( getAppDataFlag === false ) {
      const config = {
        headers: {
        "Access-Control-Allow-Origin": "*",
        "withCredentials": false,
        }
      }

      //subscribed services
      axios(globalDbENv  + ':7443/'+ globalDbName + "/subscribed_services", config)
        .then(data => setGlobalSubscribedServices(data.data))
        .then(console.log(globalSubscribedServices))

      // Widgets
      axios.get(globalDbENv + ':7443/'+ globalDbName + '/widgets',config)
      .then((res)=>{
          const widgets = res.data
          console.log(widgets)
          
          widgets
          .forEach((item,k) => {
            theWidgets.push({'key': item.key, 'dataName': item.dataName, 'Summary': item.Summary, 'fields': item.fields, 'visualisation': item.visualisation,'chart_options': item.chart_options});
            console.log(theWidgets)
          });
          // for (let item of widgets) {
          //   console.log(item)
          //     theWidgets.push({'key': item.key, 'dataName': item.dataName, 'Summary': item.Summary, 'fields': item.fields, 'visualisation': item.visualisation,'chart_options': item.chart_options});
          //     console.log(theWidgets)
          // }



          setAppWidgets(theWidgets);
          
          
          // console.log(deviceStabilityIndex)
      }).catch((err)=>{
          console.log(err);
      })


      axios.get(globalDbENv + '/dashboards')
      .then((res) => {
        const dynDashboards = res.data
        
        console.log(dynDashboards)
        // for (let item of dynDashboards) {
        //     megaItems[2].items[0][0].items.push({ label: item.dashboardName, short: 'dynamic', icon: "pi pi-fw pi-home", command: (event) => { childToParent(event); } })
        // }
      }).catch((err) => {
        console.log(err);
      })
      setGetAppDataFlag(true);
    }
  }

  const childToParent = (childdata) => {
    console.log(childdata.item)

    if ( childdata.item.dynDash === true ) {
      setToLS("dynLoad", childdata.item.label)
    }
    
    
    if ( childdata.type === 'direct' ) {
      setData(datai = childdata.short)
    } else {
      setToLS('dynPageSelected',childdata.item.label);
      setData(datai = childdata.item.short)
      setGlobalVisibleLeft(false);
    }
    // history.push('/dynamic')
  }
  const doServiceSubsRedirect = (childdata) => {
    history.push('/')
  }

  useEffect(() => {
    // console.log(location)
    setGlobalStartupKey(null)
    if ( location.type === 'wynToMain' ) {
      // console.log(location)
        setData(datai = location.shortLink) 
    }
    window.scrollTo(0, 0);
  }, [location])

  useEffect(() => {
    getAppData();
    window.scrollTo(0, 0);
  }, [])

  const setNewsType = (type, location) => {
    console.log(type)
    setGlobalNewsType(type);
    setData(datai = location);
  }
  
    
  

  
  return (
      <div data-testid='MaindashComponent' className='main-dash-component'>
        <AppBar data-testid='NavBarHead' childToParent={childToParent} doServiceSubsRedirect={doServiceSubsRedirect}/>
        <div className="dashboard-toolbar hide-mobile">
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 p-0'>
              
                {/* <span className="dashboard-toolbar">Dashboard Favourites</span>
                <button onClick={() => setData(datai = 'Overview')} className="p-mt-0 p-button-outlined">
                  Overview
                </button>
                <button onClick={() => setData(datai = 'Operations')} className="p-mt-0 p-button-outlined">
                  Operations
                </button> */}
              
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 text-right p-0'>

              <div className="card display-contents">
              <Button type="button" icon="pi pi-search" label={selectedProduct ? selectedProduct.name : 'Business Workflow'} onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" className="select-product-button overlay-button popover-button" />

                      <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{width: '450px'}} className="overlaypanel-demo">
                        <BusinessWorkflowTrafficLight />
                      </OverlayPanel>
                      <Button className="overlay-button popover-button" type="button" icon="pi pi-book" label='Reports' onClick={() => setNewsType('reports', "news")} aria-haspopup aria-controls="overlay_panel"></Button>
                      <Button className="overlay-button popover-button"type="button" icon="pi pi-book" label='Storm News' onClick={() => setNewsType('news', "news")} aria-haspopup aria-controls="overlay_panel" />
                      <Button className="overlay-button popover-button" type="button" icon="pi pi-th-large" label='Service Updates' onClick={() => setNewsType('service-update', "news")} aria-haspopup aria-controls="overlay_panel" />
                  </div>
              </div>
            </div>
          </div>
         
          
        </div>
        {datai === 'Welcome' && <Welcome />} 
        {datai === 'Welcome2' && <Welcome2 />} 
        {datai === 'WelcomeStatic' && <WelcomeStatic />} 
        {datai === 'Overview' && <Overview />} 
        {datai === 'Operations' && <Operations />} 
        {datai === 'dynamic' && <GetDynamicDash />} 
        {datai === 'widgetlibrary' && <WidgetLibrary />} 
        {datai === 'datalibrary' && <DataLibrary />} 
        {datai === 'wfa' && <Wfa />} 
        {datai === 'news' && <RpStormNews />} 
        {datai === 'sase' && <Sase />} 
        {datai === 'createscenario' && <CreateScenario />} 
        {datai === 'DeviceStatus' && <DeviceStatus />} 
        {datai === 'editprofile' && <EditProfile />} 
        {datai === 'newdashboard' && <NewDashboard />} 
        {datai === 'helpsupport' && <HelpSupport />} 
        {/* <HomeNoDisplay /> */}
      </div>
  )
}
export default Maindash;