import React, { Component, createClass, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'moment-timezone';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "withCredentials": false,
  }
}

const Step2 = (mood) => {
  const [globalDbENv, setGlobalDbEnv] = useGlobal('dbenv');
  const [globalDbName, setGlobalDbName] = useGlobal('dbenvname');
  const [dynDashWidgetAvailableChartTypes, setDynDashWidgetAvailableChartTypes] = useGlobal('dyn_dash_widget_available_chart_types');
  const [dynDashWidgetCollectionSelected, setDynDashWidgetCollectionSelected] = useGlobal('dyn_dash_widget_collection_selected');
  const [dynDashWidgetCollectionSelectedFields, setDynDashWidgetCollectionSelectedFields] = useGlobal('dyn_dash_widget_collection_selected_fields');
  const [dynDashWidgetVisualisation, setDynDashWidgetVisualisation] = useGlobal('dyn_dash_widget_visualisation');
  
  const [ dynDashWidgetCollectionSelectedFieldsOptionSelected, setDynDashWidgetCollectionSelectedFieldsOptionSelected ] = useGlobal('dyn_dash_widget_collection_selected_fields_option_selected');
  


  
    // console.log(dynDashWidgetCollectionSelectedFieldsOptionSelected)
    // // if ( getFromLS('canRunApi') === true ) {
    // axios.get(globalDbENv + ':7443/' + globalDbName + '/dyn_dash_available_charttypes', config)
    //   .then((res) => {
    //     console.log(res.data)
    //     console.log("karrie")
    //     alert(res.data)
    //     console.log(dynDashWidgetCollectionSelectedFieldsOptionSelected.length)
    //     setDynDashWidgetAvailableChartTypes(res.data);
    //     console.log(dynDashWidgetAvailableChartTypes)
        
    //     // setDynDashWidgetCollectionOptions(res.data)
    //     // setDynDashWidgetCollectionSelectedFields([])
    //     // QuoteData = res.data;
    //     // setGlobalLoginQuoteItems(QuoteData)
    //   }).catch((err) => {
    //     console.log(err);
    //   })

  

  useEffect(() => {
    console.log(dynDashWidgetCollectionSelectedFieldsOptionSelected)
    // if ( getFromLS('canRunApi') === true ) {
    axios.get(globalDbENv + ':7443/' + globalDbName + '/dyn_dash_available_charttypes', config)
      .then((res) => {
        setDynDashWidgetAvailableChartTypes(res.data);
      }).catch((err) => {
        console.log(err);
      })

  }, [mood]);

  const onVisSelect = (e) => {
    console.log(e);
    setDynDashWidgetVisualisation(e);
    // setDynDashWidgetCollectionSelected(e.value);
    // getFieldsFromCollection(e.value.collection_name);
    // setDynDashWidgetCollectionSelectedFieldsOptionSelected([]);
  }

  return (
    <div className="step step2">
      <div className="">
        <form id="Form" className="form-horizontal">
          <div className="form-group">
            <label className="col-md-12 control-label">
              <h5>Choose Visualisation Option</h5>
            </label>
        
              
              

                <div className='row'>
                  {dynDashWidgetAvailableChartTypes.map((data) => ( // <= only 2 items (
                    <div className='col-6 col-sm-6 col-md-6 col-lg-3 text-center '>
                      {/* <img style={{ height: '200px' }} src={'chart-icons/' + data.chart_id + '.png'} alt={data.chart_label} className="visualisation-image" /> */}
                      <div className='text-center visualisation-image' onClick={(e) => onVisSelect(data.chart_id)}>
                        <div className='text-center chart-vis-image' style={{ background: 'url(chart-icons/'+data.chart_id+'.png)' }}>
                        </div>
                        <span className='inline-blockl'>{data.chart_label}</span>
                      </div>
                    </div>
                  ))}
                </div>



              

            
          </div>
        </form>
      </div>
    </div>
  )
}

export default Step2;
