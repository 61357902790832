import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { msalInstance } from 'features/app';
import { loginRequest } from 'config/authConfig';
import * as actionSelectors from './slice';

export function* getAccount() {
    const account = yield msalInstance.getActiveAccount();
    return account;
}

export function* signIn() {
    const response = yield msalInstance.loginPopup();
    return response;
}

export function* signOut() {
    yield msalInstance.logoutPopup();
}

export function* acquireToken(config){
    const data = yield msalInstance.acquireTokenSilent(config);
    return data;
}

export function* loginSaga() {
    try {
        const loginResponse = yield call(signIn);
        yield put(actionSelectors.success({
            accessToken: loginResponse.accessToken,
            idToken: loginResponse.idToken,
            account: loginResponse.account
        }));
    } catch (error) {
        // handle error
        yield put(actionSelectors.error(error));
    }
}

export function* logoutSaga() {
    yield put(actionSelectors.init());
    yield call(signOut)
}

export function* loadTokenSilent() {
    yield put(actionSelectors.load());
    const account = yield call(getAccount);
    if (account) {
        try {
            const authData = yield call(acquireToken, loginRequest);
            yield put(actionSelectors.success({
                accessToken: authData.accessToken,
                idToken: authData.idToken,
                account: account
                
            }));
        } catch (e) {
            if (e.errorCode === 'login_required') {
                console.log('login required');
                yield call(logoutSaga);
            } else {
                yield put(actionSelectors.error(e));
            }
        }
    } else {
        yield put(actionSelectors.init());
    }
}

export default function* authSaga() {
    yield all([
        fork(loadTokenSilent),
        takeLatest(actionSelectors.login.type , loginSaga),
        takeLatest(actionSelectors.logout.type , logoutSaga)
    ]);
}
