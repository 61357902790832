import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LoadingPage from 'components/ui/loading-page';
import AppBar from 'components/header/app-bar';
import TenantDialog from 'features/tenant';
import { StyledFrame } from './index.style';
import useDashboards from  'features/dashboards/use';
import useWyn from  'features/wyn/use';
import PageCenterStyle from "components/ui/page-centre.style";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { setToLS, getFromLS } from 'features/app/utils/storage';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useGlobal } from 'reactn';
import $ from 'jquery';
import axios from 'axios';

const Home = () => {
    const [ globalDbENv, setGlobalDbEnv ] = useGlobal('dbenv');
    const location = useLocation();
    const history = useHistory();
    const { handleDash } = useDashboards();
    const { loading: dashLoading, success: dashSuccess, selected, data } = useDashboards();
    const [ globalStartupKey, setGlobalStartupKey ] = useGlobal('startupKey');
    const [ globalCurrentTenant, setGlobalCurrentTenant ] = useGlobal('currentTenant');
    const [ globalWynDashboards, setGlobalWynDashboards ] = useGlobal('wynDashboards');
    setToLS('all-dashboards', data);
    setToLS('all-dashboards-safe', data);
    
    const { loading: wynLoading, success: wynSuccess, data: wynData } = useWyn();
    const dashLink = dashSuccess && ( selected ? selected.id : data[0].id);
    const embedType = dashSuccess && (selected ? (selected.type === 'dbd' ? 'dashboards' : 'webpages') : (data[0].type === 'dbd' ? 'dashboards' : 'webpages'));
    const iframeUrlAternity = `https://eu2.aternity.com/#/view/AdvancedMultiDSB11643/EUCMenu?BACCT=1841&BCustomDashboardId=11643&BTimeframeParam=TF_09-08-21%2011:00:00_10-08-21%2010:55:00_Last24Hours&BTitleParam=WFA%20-%20Menu%20v1.0&UTimeframeParam=TF_20-09-21%2008:00:00_21-09-21%2007:40:00_Last24Hours&BMode=preview`;
    const iframeUrl = wynSuccess && `https://iav.dev.stormportal.net:7445/${embedType}/view/${dashLink}?token=${wynData.access_token}&size=fittoscreen&actions=clearselection&openfulldashboard`;

  
    
    const childToParent = (childdata) => {
        // alert("CD called")
        $(".p-sidebar-header").prepend($(".sidebar-title")); $(".p-sidebar-header").addClass("p-sidebar-header-right");
        if (location.pathname === '/' && !childdata.item) {
            history.push({
                pathname: '/maindash',
                type: 'wynToMain',
                state: { shortLink: childdata.short },
                shortLink: childdata.short
            });
        } else {
            history.push({
                pathname: '/maindash',
                type: 'wynToMain',
                state: { shortLink: childdata.item.short },
                shortLink: childdata.item.short
            });
        }
      }

    const getCurrentDashRaw = () => {
        console.log(getFromLS('viewingDashboard'))
        if ( getFromLS('viewingDashboard') ) {
            var dashLabel = getFromLS('viewingDashboard')
            if ( dashLabel ) {
              handleDash({id: dashLabel.id, type: dashLabel.type})
            }            
        }
    }

    const HandleDash = (dash) => {
        console.log(dash)
        // removeFromLS('aternity')
        //setToLS('viewingDashboard', dash.item)
        history.push('/')
        handleDash({ id: dash.id, type: dash.type })
      }

    // console.log(getFromLS('startup'))
    // if (getFromLS('startup') === true && data !== null) {
    //     history.push('/maindash')
    // }

    if (globalStartupKey === true && data !== null) {
        history.push('/maindash')
    }

    
    
    useEffect(()=>{
        // getAppData();
        // getCurrentDashRaw()
        // setGlobalWynDashboards(data);
        console.log(globalWynDashboards)
    },[data, globalWynDashboards, setGlobalWynDashboards]) 

    

    return (
        <div data-testid='HomeComponent'>
            
            {(wynLoading || dashLoading) && <div><LoadingPage data-testid='LoadingComponent'/></div>}
            <Card>
                <TenantDialog/>
                <Card direction='row' flex overflow={{ horizontal: 'hidden' }}>
                    {/* <SideBar/> */}
                    <Card flex justify="center" data-testid='ContentComponent'>
                        
                        {/* <div className='container-fluid p-0 m-0'>
                            <div className='row panel-content loose title-container m-0 p-0'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 p-0 m-3'>
                                    <div className="panel-title-wyn-dash">
                                        <p className='small-text-2 m-0 p-0'><span className="text-uppercaseÛ">Tenant:</span> <span className='text-white bold-weight text-uppercase'>{globalCurrentTenant.label}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {!wynSuccess && !dashSuccess && <PageCenterStyle/>}
                        {globalCurrentTenant && <AppBar data-testid='NavBarHead' childToParent={childToParent}/>}
                        
                        <Card>
                            <AuthenticatedTemplate>
                                {getFromLS('aternity') && <>
                                <StyledFrame>
                                    <iframe
                                    title="Selected Dashboard"
                                    className="dashboard-preview" 
                                    src={iframeUrlAternity}
                                    frameBorder="0"
                                    />
                                </StyledFrame>
                                </>}
                                {dashSuccess && <>
                                <StyledFrame>
                                    <iframe
                                    title="Selected Dashboard"
                                    className="dashboard-preview" 
                                    src={iframeUrl}
                                    frameBorder="0"
                                    />
                                </StyledFrame>
                                </>}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <h1 level='6'>
                                    <center>Please sign-in to gain access to your dashboards.</center>
                                </h1>
                            </UnauthenticatedTemplate>
                        </Card>
                    </Card>
                </Card>
            </Card>
        </div>
    )
}

export default Home;
