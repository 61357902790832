import React, { Component, createClass, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';

import { Chart } from 'primereact/chart';

let chartData = [

]
const DynBarChart = ({thedatafromparent}) => {
    
    // const [state, setState] = useState( {key: Date.now() });
    const [globaldynData, setGlobaldynData] = useGlobal('dynamicData');
    const [basicData] = useState({
        labels: ['January', 'February'],
        datasets: [
        {
            label: 'My First dataset',
            backgroundColor: '#42A5F5',
            data: [65, 59, 80, 81, 56, 55, 40]
        }
        ]
    });
    
    const [basicOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
        legend: {
            labels: {
            color: '#495057'
            }
        }
        },
        scales: {
        x: {
            ticks: {
            color: '#495057'
            },
            grid: {
            color: '#ebedef'
            }
        },
        y: {
            ticks: {
            color: '#495057'
            },
            grid: {
            color: '#ebedef'
            }
        }
        }
    
    
    
    
    
    })
    const getData = () => {
        console.log(thedatafromparent)
        // alert('new stuff')

        


        console.log(thedatafromparent)
        basicData.labels = thedatafromparent.fields;

        thedatafromparent.fields
        .forEach((item, k) => {
            console.log(item)
            console.log(basicData.datasets.length)
            basicData.datasets.label = item;
            console.log(thedatafromparent.chartData[0].Duration)
            basicData.datasets[0].data = thedatafromparent.chartData[0].Duration;
        });

        chartData.labels = basicData.labels;
        chartData.datasets = basicData.datasets;
        chartData.options = basicOptions;
        console.log(basicData)
        console.log(chartData)
        setGlobaldynData(chartData)
    }
    
    useEffect(() => {
        getData();
      }, [thedatafromparent]);
    return (
        <div>
            <div className="card welcome-widget">
                { thedatafromparent &&
                    <Chart type="bar"  data={globaldynData} options={basicOptions} />
                }
            </div>
        </div>
    )
}

export default DynBarChart;
